import React, { useState, useEffect } from 'react';
import {
    Box, Modal, TextField, Select, Switch, IconButton, FormControl,
    Autocomplete, MenuItem, Typography, Checkbox, Divider, ListSubheader
} from '@mui/material';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { useForm, useFieldArray } from 'react-hook-form';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import CommonButton from '../settings/commonButton';
import SecondaryHeading from '../settings/commonSecondaryHeading';
import PercentageIcon from "../../assets/percentage.png";
import PriceIcon from "../../assets/pricekr.png";
import CustomSelect from '../settings/commonCustomSelect';
import DeleteIcon from "../../assets/Trash.png";
import PrimaryHeading from '../settings/commonPrimaryHeading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

const dateOptions = [
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
];

const timeOptions = [
    {
        label: 'Now',
        value: 'now',
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
];

const endDateOptions = [
    {
        label: '24 hrs',
        value: '24hrs',
    },
    {
        label: '3 days',
        value: '3days',
    },
    {
        label: '1 week',
        value: '1week',
    },
    {
        label: '2 weeks',
        value: '2weeks',
    },
    {
        label: '1 month',
        value: '1month',
    },
    {
        label: 'End of month',
        value: 'endOfMonth',
    },
    {
        label: 'Customs',
        value: 'custom',
    },
];

function AddCampaignOfferModal(props) {
    const { open, handleClose, selectedCampaignOffer, type } = props;

    // console.log('>>>>>>>>>>>> ', JSON.stringify(selectedCampaignOffer))

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const [selectedValues, setSelectedValues] = useState({
        startDate: { value: '', time: '' },
        endDate: { value: '', time: '' },
    });

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [customStartTime, setCustomStartTime] = useState(null);
    const [customEndTime, setCustomEndTime] = useState(null);

    const [campaignTitle, setCampaignTitle] = useState('');
    const [serviceList, setServiceList] = useState([]);
    const [searchText, setSearchText] = useState('');

    const { control, register, setValue, getValues } = useForm({
        defaultValues: {
            services: [],
        },
    });

    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: 'services',
    });

    useEffect(() => {
        if (type == 'edit') {
            const startDate = new Date(selectedCampaignOffer?.start_datetime);
            const endDate = new Date(selectedCampaignOffer?.end_datetime);

            let startDateOption = 'custom';
            if (startDate.toDateString() === new Date().toDateString()) {
                startDateOption = 'today';
            } else if (startDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
                startDateOption = 'tomorrow';
            }

            let startTimeOption = 'custom';
            if (startDate.getHours() === new Date().getHours() && startDate.getMinutes() === new Date().getMinutes()) {
                startTimeOption = 'now';
            }

            let endDateOption = 'custom';
            if (endDate.toDateString() === new Date().toDateString()) {
                endDateOption = 'today';
            } else if (endDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
                endDateOption = 'tomorrow';
            }

            let endTimeOption = 'custom';
            if (endDate.getHours() === new Date().getHours() && endDate.getMinutes() === new Date().getMinutes()) {
                endTimeOption = 'now';
            }

            setSelectedEndDate('custom');

            setSelectedValues({
                startDate: { value: startDateOption, time: startTimeOption },
                endDate: { value: endDateOption, time: endTimeOption },
            });

            setCustomStartDate(startDate);
            setCustomStartTime(startDate);

            setCustomEndDate(endDate);
            setCustomEndTime(endDate);

            setCampaignTitle(selectedCampaignOffer?.title);

            selectedCampaignOffer?.data.forEach(item => {
                const service = item.service;
                const selectedEmployeeIds = item.employees.map(emp => emp.id);
                append({
                    serviceId: service.id,
                    newPrice: service.special_price,
                    percentage: service.special_percentage,
                    oldPrice: service.price,
                    dropdownValue: '',
                    employeeId: selectedEmployeeIds,
                    serviceName: service.name,
                    employees: service.employees || [],
                });
            });

        }
    }, [selectedCampaignOffer]);

    useEffect(() => {
        fetchServiceApi();
    }, []);

    const transformServiceList = (data) => {

        const filteredData = data.map(group => ({
            ...group,
            services: group.services.filter(service => {
                return !fields.some(field => field.serviceId === service.id);
            })
        }));

        const groupedServices = filteredData
            .filter(group => group.id !== 0)
            .map(group => ({
                title: group.group,
                groupId: group.id,
                data: group.services.length > 0 ? group.services : [{ name: 'No services', groupId: group.id }],
            }));

        const ungroupedServices = filteredData
            .filter(group => group.id === 0)
            .flatMap(group => group.services)
            .map(service => ({
                ...service,
                groupId: 0,
            }));

        return [
            {
                title: 'Services without a group',
                groupId: 0,
                data: ungroupedServices.length > 0 ? ungroupedServices : [{ name: 'No services', groupId: 0 }],
            },
            ...groupedServices,
        ];
    };

    async function fetchServiceApi() {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service_group`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setServiceList(transformServiceList(response.data.data));
                setLoading(false);
            } else {
                setServiceList([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
            setServiceList([]);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchServiceApi();
    }, [fields.length]);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) {
            return;
        }
        swap(source.index, destination.index);
    };

    const handleServiceSelect = (service) => {
        const updatedServices = serviceList.map(group => ({
            ...group,
            data: group.data.filter(serviceItem => serviceItem.id !== service.id),
        }));

        setServiceList(updatedServices);

        append({
            serviceId: service.id,
            newPrice: '',
            oldPrice: service.price,
            dropdownValue: '',
            employeeId: [],
            serviceName: service.name,
            employees: service.employees || [],
        });
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleDateChange = (field, selectedValue) => {
        setSelectedValues((prev) => ({
            ...prev,
            [field]: { ...prev[field], value: selectedValue },
        }));
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const resetFields = (command) => {
        handleClose(command);
    };

    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const handleTimeChange = (field, selectedTime) => {
        setSelectedValues((prev) => ({
            ...prev,
            [field]: { ...prev[field], time: selectedTime },
        }));
    };

    const handlePriceChange = async (e, index, field, type) => {
        const value = e.target.value;

        const validNumber = /^[0-9]*\.?[0-9]*$/;

        if (value == '.') {
            setValue(`services.${index}.newPrice`, '');
            setValue(`services.${index}.percentage`, '');
            e.preventDefault();
            return;
        };

        if (!validNumber.test(value)) {
            setValue(`services.${index}.newPrice`, '');
            setValue(`services.${index}.percentage`, '');
            e.preventDefault();
            return;
        };

        if (type === 'newPrice') {
            if (value === '') {
                setValue(`services.${index}.percentage`, '');
            } else {
                const percentage = ((parseFloat(value) / field.oldPrice) * 100).toFixed(2);
                setValue(`services.${index}.percentage`, percentage);
            }
        } else if (type === 'percentage') {
            if (value === '') {
                setValue(`services.${index}.newPrice`, '');
            } else {
                const newPrice = (parseFloat(value) / 100) * field.oldPrice;
                setValue(`services.${index}.newPrice`, newPrice.toFixed(2));
            }
        }
    };

    async function createOrUpdateCampaignOffer() {

        const updatedFields = getValues('services');

        let startDate = null;
        let startTime = null;
        let endDate = null;
        let endTime = null;

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formatTime = (date) => {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

        if (!checked) {
            if (selectedValues.startDate.value === 'custom' || selectedValues.startDate.time === 'custom') {
                if (!customStartDate || !customStartTime) {
                    toast.error('Custom start date and time must be provided.');
                    return;
                }
                startDate = formatDate(new Date(customStartDate));
                startTime = formatTime(new Date(customStartTime));
            } else if (selectedValues.startDate.value === 'today') {
                const today = new Date();
                startDate = formatDate(today);
                startTime = formatTime(today);
            } else if (selectedValues.startDate.value === 'tomorrow') {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                startDate = formatDate(tomorrow);
                startTime = formatTime(tomorrow);
            }

            if (!selectedEndDate) {
                toast.error('End date must be selected.');
                return;
            }

            if (selectedEndDate === 'custom') {

                if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
                    toast.error('Custom end date and time must be provided.');
                    return;
                }

                if (selectedValues.endDate.value === 'custom') {
                    if (!customEndDate) {
                        toast.error('Custom end date must be provided.');
                        return;
                    }
                    endDate = formatDate(new Date(customEndDate));
                } else if (selectedValues.endDate.value === 'today') {
                    endDate = formatDate(new Date());
                } else if (selectedValues.endDate.value === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endDate = formatDate(tomorrow);
                } else {
                    toast.error('Invalid end date value.');
                    return;
                }

                if (selectedValues.endDate.time === 'custom') {
                    if (!customEndTime) {
                        toast.error('Custom end time must be provided.');
                        return;
                    }
                    endTime = formatTime(new Date(customEndTime));
                } else if (selectedValues.endDate.time === 'now') {
                    endTime = formatTime(new Date());
                } else if (selectedValues.endDate.time === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endTime = formatTime(tomorrow);
                } else {
                    toast.error('Invalid end time value.');
                    return;
                }

            } else if (selectedEndDate === '24hrs') {
                const endOf24hrs = new Date();
                endOf24hrs.setHours(endOf24hrs.getHours() + 24);
                endDate = formatDate(endOf24hrs);
                endTime = formatTime(endOf24hrs);
            } else if (selectedEndDate === '3days') {
                const endOf3Days = new Date();
                endOf3Days.setDate(endOf3Days.getDate() + 3);
                endDate = formatDate(endOf3Days);
                endTime = formatTime(endOf3Days);
            } else if (selectedEndDate === '1week') {
                const endOf1Week = new Date();
                endOf1Week.setDate(endOf1Week.getDate() + 7);
                endDate = formatDate(endOf1Week);
                endTime = formatTime(endOf1Week);
            } else if (selectedEndDate === '2weeks') {
                const endOf2Weeks = new Date();
                endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
                endDate = formatDate(endOf2Weeks);
                endTime = formatTime(endOf2Weeks);
            } else if (selectedEndDate === '1month') {
                const endOf1Month = new Date();
                endOf1Month.setMonth(endOf1Month.getMonth() + 1);
                endDate = formatDate(endOf1Month);
                endTime = formatTime(endOf1Month);
            } else if (selectedEndDate === 'endOfMonth') {
                const endOfMonth = new Date();
                endOfMonth.setMonth(endOfMonth.getMonth() + 1);
                endOfMonth.setDate(0);
                endDate = formatDate(endOfMonth);
                endTime = formatTime(endOfMonth);
            }
        } else {
            const currentDate = new Date();
            startDate = formatDate(currentDate);
            startTime = formatTime(currentDate);

            if (!selectedEndDate) {
                toast.error('End date must be selected.');
                return;
            }

            if (selectedEndDate === '24hrs') {
                const endOf24hrs = new Date();
                endOf24hrs.setHours(endOf24hrs.getHours() + 24);
                endDate = formatDate(endOf24hrs);
                endTime = formatTime(endOf24hrs);
            } else if (selectedEndDate === '3days') {
                const endOf3Days = new Date();
                endOf3Days.setDate(endOf3Days.getDate() + 3);
                endDate = formatDate(endOf3Days);
                endTime = formatTime(endOf3Days);
            } else if (selectedEndDate === '1week') {
                const endOf1Week = new Date();
                endOf1Week.setDate(endOf1Week.getDate() + 7);
                endDate = formatDate(endOf1Week);
                endTime = formatTime(endOf1Week);
            } else if (selectedEndDate === '2weeks') {
                const endOf2Weeks = new Date();
                endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
                endDate = formatDate(endOf2Weeks);
                endTime = formatTime(endOf2Weeks);
            } else if (selectedEndDate === '1month') {
                const endOf1Month = new Date();
                endOf1Month.setMonth(endOf1Month.getMonth() + 1);
                endDate = formatDate(endOf1Month);
                endTime = formatTime(endOf1Month);
            } else if (selectedEndDate === 'endOfMonth') {
                const endOfMonth = new Date();
                endOfMonth.setMonth(endOfMonth.getMonth() + 1);
                endOfMonth.setDate(0);
                endDate = formatDate(endOfMonth);
                endTime = formatTime(endOfMonth);
            } else if (selectedEndDate === 'custom') {
                if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
                    toast.error('Custom end date and time must be provided.');
                    return;
                }

                if (selectedValues.endDate.value === 'custom') {
                    if (!customEndDate) {
                        toast.error('Custom end date must be provided.');
                        return;
                    }
                    endDate = formatDate(new Date(customEndDate));
                } else if (selectedValues.endDate.value === 'today') {
                    endDate = formatDate(new Date());
                } else if (selectedValues.endDate.value === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endDate = formatDate(tomorrow);
                } else {
                    toast.error('Invalid end date value.');
                    return;
                }

                if (selectedValues.endDate.time === 'custom') {
                    if (!customEndTime) {
                        toast.error('Custom end time must be provided.');
                        return;
                    }
                    endTime = formatTime(new Date(customEndTime));
                } else if (selectedValues.endDate.time === 'now') {
                    endTime = formatTime(new Date());
                } else if (selectedValues.endDate.time === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endTime = formatTime(tomorrow);
                } else {
                    toast.error('Invalid end time value.');
                    return;
                }
            }
        };

        if (!startDate || !startTime ||
            (selectedValues.endDate.value === 'custom' && (!endDate || !endTime))) {
            toast.error('Invalid date or time selection');
            return;
        };

        if (campaignTitle == '') {
            toast.error('Please enter campaign title');
            return;
        }

        if (updatedFields.length == 0) {
            toast.error('Please select atleast one service');
            return;
        }

        const specialOffers = updatedFields.map(field => {
            const serviceId = field.serviceId;
            const specialPrice = Number(field.newPrice);
            const formattedPercentage = Number(field.percentage) % 1 === 0
                ? Number(field.percentage)
                : Number(field.percentage).toFixed(2).replace('.00', '');
            const employeeIds = field.employeeId;

            if (!specialPrice || !formattedPercentage || employeeIds.length === 0) {
                toast.error('New price, Percentage, and Who offer this price? cannot be empty.');
                return null;
            }

            return {
                service_id: serviceId,
                special_price: specialPrice,
                special_percentage: formattedPercentage,
                employee_ids: employeeIds
            };
        }).filter(offer => offer !== null);

        if (specialOffers.length === 0) {
            return;
        }

        try {
            const data = {
                campaign_name: campaignTitle,
                start_datetime: `${startDate} ${startTime}`,
                end_datetime: `${endDate} ${endTime}`,
                special_offers: specialOffers
            };

            let response;

            if (type === 'create') {
                response = await axios.post(`${process.env.REACT_APP_URL}/api/v1/store/campaign`, data, {
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`
                    }
                });

                if (response.status === 200) {
                    toast.success("Campaign offer created successfully!");
                } else {
                    toast.error("Failed to create campaign offer");
                }

                setTimeout(() => {
                    resetFields('callApi');
                }, 2000);

            } else if (type === 'edit') {
                response = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/store/campaign/${selectedCampaignOffer?.groupId}`, data, {
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`
                    }
                });

                if (response.status === 200) {
                    toast.success("Campaign offer updated successfully!");
                } else {
                    toast.error("Failed to update campaign offer");
                }

                setTimeout(() => {
                    resetFields('callApi');
                }, 2000);

            } else {
                toast.error(`Failed to ${type} campaign offer`);
            }

        } catch (error) {
            console.log(`Error while ${type} capaign offer:`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (empId, index) => {
        const updatedFields = fields.map((field, i) => {
            if (i === index) {
                const currentEmployeeIds = field.employeeId || [];
                let updatedEmployeeIds;

                if (currentEmployeeIds.includes(empId)) {
                    updatedEmployeeIds = currentEmployeeIds.filter(id => id !== empId);
                } else {
                    updatedEmployeeIds = [...currentEmployeeIds, empId];
                }

                return {
                    ...field,
                    employeeId: updatedEmployeeIds,
                };
            }
            return field;
        });

        setValue(`services`, updatedFields);
    };

    const allServices = serviceList.flatMap((groupData) =>
        groupData.data.map((service) => ({
            ...service,
            group: groupData.title,
        }))
    );

    const handleSearchInputChange = (event, value) => {
        setSearchText(value);
    };

    const filteredOptions = allServices.filter((service) =>
        service.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[style, {
                zIndex: 12,
                justifyContent: 'center', alignItems: 'center',
                overflowY: { xs: 'auto', sm: '85vh' },
                overflowX: 'hidden',
                maxHeight: { xs: '90vh', sm: '85vh' },
                position: 'relative'
            }]}>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <PrimaryHeading text={type == 'edit' ? 'Update campaign' : 'Create a campaign'} fontSize='26px' />
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%', alignItems: 'flex-start' }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                            width: '50%',
                            maxWidth: '1440px',
                            '@media (max-width: 1440px)': {
                                width: '63%',
                            }
                        }}>
                            <Box sx={{ ml: 2.5 }}>
                                <PrimaryHeading text={'Start instantly'} />
                            </Box>

                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{
                                    '& .MuiSwitch-switchBase': {
                                        '&.Mui-checked': { color: '#fff' },
                                        '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                                    },
                                    '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                                }}
                            />
                        </Box>

                        {!checked &&
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.startDate.value === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set starting date'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.startDate.value}
                                            onChange={(e) => handleDateChange('startDate', e.target.value)}
                                            options={dateOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.startDate.value === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={customStartDate}
                                                    onChange={(newValue) => setCustomStartDate(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.startDate.time === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set starting time'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.startDate.time}
                                            onChange={(e) => handleTimeChange('startDate', e.target.value)}
                                            options={timeOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.startDate.time === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={customStartTime}
                                                    onChange={(newValue) => setCustomStartTime(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>
                            </>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', mt: 5 }}>
                            <PrimaryHeading text={'End of campaign'} />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: '100%',
                                maxWidth: '60%',
                                '@media (max-width: 1440px)': {
                                    maxWidth: '50%',
                                },
                                '@media (max-width: 900px)': {
                                    maxWidth: '40%',
                                }
                            }}>
                                <CustomSelect
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    options={endDateOptions}
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                        </Box>

                        {selectedEndDate === 'custom' &&
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.endDate.value === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set ending date'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.endDate.value}
                                            onChange={(e) => handleDateChange('endDate', e.target.value)}
                                            options={dateOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.endDate.value === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={customEndDate}
                                                    onChange={(newValue) => setCustomEndDate(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.endDate.time === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set ending time'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.endDate.time}
                                            onChange={(e) => handleTimeChange('endDate', e.target.value)}
                                            options={timeOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.endDate.time === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={customEndTime}
                                                    onChange={(newValue) => setCustomEndTime(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%', alignItems: 'center' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center', mt: 3, mb: 3 }}>
                            <PrimaryHeading text={'Campaign'} />
                        </Box>

                        <Box sx={{
                            width: '90%',
                            height: 55,
                            backgroundColor: '#D9D9D9',
                            borderRadius: '10px',
                            mb: 2,
                            display: 'flex',
                            alignItems: 'center',


                        }}>
                            <CustomTextField
                                value={campaignTitle}
                                onChange={(e) => setCampaignTitle(e.target.value)}
                                placeholder={`Title for campaign`}
                                mt={0}
                                ml={3}
                                borderRadius={'20px'}
                                width={'40%'}
                            />
                        </Box>

                        <Box sx={{ width: '90%' }}>

                            {fields.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 2,
                                        marginBottom: 1,
                                        padding: '0 15px',
                                        width: '100%',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Box sx={{ width: { xs: '20%', sm: '6%' } }} />

                                    <Box sx={{ width: { xs: '40%', sm: '25%' } }} />

                                    <Typography sx={{ width: { xs: '100%', sm: '14%' }, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>
                                        Percentage
                                    </Typography>

                                    <Typography sx={{ width: { xs: '100%', sm: '12%' }, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>
                                        Old Price
                                    </Typography>

                                    <Typography sx={{ width: { xs: '100%', sm: '18%' }, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>
                                        New Price
                                    </Typography>

                                    <Typography sx={{ width: { xs: '100%', sm: '21%' }, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>
                                        Who offer this price?
                                    </Typography>

                                    <Typography sx={{ width: { xs: '100%', sm: '6%' }, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }} />
                                </Box>
                            )}

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <Box ref={provided.innerRef} {...provided.droppableProps}>
                                            {fields.map((field, index) => (
                                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                mb: 2,
                                                                height: 45,
                                                                border: '1.5px solid #ddd',
                                                                borderRadius: '12px',
                                                                padding: '8px',
                                                                backgroundColor: '#fff',
                                                                opacity: snapshot.isDragging ? 1 : 1,
                                                                boxShadow: snapshot.isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                                                                ...provided.draggableProps.style,
                                                            }}
                                                        >
                                                            <DensityMediumIcon sx={{ width: 18, height: 18, marginLeft: 3, color: '#A0A0A0' }} />

                                                            <Box
                                                                sx={{
                                                                    width: '23%',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    '@media (max-width: 1440px)': {
                                                                        width: '30%',
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        width: '40%',
                                                                    },
                                                                }}
                                                            >
                                                                <SecondaryHeading text={field.serviceName} fontColor={'#A0A0A0'} />
                                                            </Box>

                                                            <TextField
                                                                {...register(`services.${index}.percentage`)}
                                                                size="small"
                                                                onChange={(e) => handlePriceChange(e, index, field, 'percentage')}
                                                                placeholder='0.0%'
                                                                sx={{
                                                                    border: '1.5px solid #D9D9D9',
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        border: 'none',
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#D9D9D9',
                                                                    },
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#D9D9D9',
                                                                    },
                                                                    '& input': {
                                                                        color: 'black',
                                                                        fontSize: '1rem',
                                                                        padding: '5px',
                                                                    },
                                                                    '& input::placeholder': {
                                                                        color: '#747474',
                                                                        fontSize: '1rem',
                                                                        opacity: 1,
                                                                    },
                                                                    width: '10%',
                                                                    height: 35,
                                                                    backgroundColor: 'white',
                                                                    borderRadius: '12px',
                                                                    '@media (max-width: 1440px)': {
                                                                        width: '12%',
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        width: '18%',
                                                                    },
                                                                }}
                                                            />

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    '@media (max-width: 1440px)': {
                                                                        ml: 0.5,
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        ml: 0.5,
                                                                    },
                                                                }}
                                                            >
                                                                <img
                                                                    src={PercentageIcon}
                                                                    alt="PercentageIcon"
                                                                    style={{
                                                                        width: '15px',
                                                                        height: '15px',
                                                                        '@media (max-width: 1440px)': {
                                                                            marginLeft: 2
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    width: 'auto',
                                                                    '@media (max-width: 1440px)': {
                                                                        width: '15%',
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        width: '25%',
                                                                        marginBottom: '8px',
                                                                    },
                                                                }}
                                                            >
                                                                <SecondaryHeading
                                                                    text={`${field.oldPrice} kr`}
                                                                    fontColor={'#A0A0A0'}
                                                                    sx={{
                                                                        fontSize: '1rem',
                                                                        '@media (max-width: 1440px)': {
                                                                            fontSize: '0.9rem',
                                                                        },
                                                                        '@media (max-width: 900px)': {
                                                                            fontSize: '0.85rem',
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>

                                                            <TextField
                                                                {...register(`services.${index}.newPrice`)}
                                                                size="medium"
                                                                onChange={(e) => handlePriceChange(e, index, field, 'newPrice')}
                                                                placeholder='0.00'
                                                                sx={{
                                                                    border: '1.5px solid #D9D9D9',
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        border: 'none',
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#D9D9D9',
                                                                    },
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#D9D9D9',
                                                                    },
                                                                    '& input': {
                                                                        color: 'black',
                                                                        fontSize: '1rem',
                                                                        padding: '5px',
                                                                    },
                                                                    '& input::placeholder': {
                                                                        color: '#747474',
                                                                        fontSize: '1rem',
                                                                        opacity: 1,
                                                                    },
                                                                    width: '13%',
                                                                    backgroundColor: 'white',
                                                                    borderRadius: '12px',
                                                                    '@media (max-width: 1440px)': {
                                                                        width: '15%',
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        width: '18%',
                                                                    },
                                                                }}
                                                            />

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    '@media (max-width: 1440px)': {
                                                                        ml: 0.5,
                                                                    },
                                                                    '@media (max-width: 900px)': {
                                                                        ml: 0.5,
                                                                    },
                                                                }}
                                                            >
                                                                <img
                                                                    src={PriceIcon}
                                                                    alt="PriceIcon"
                                                                    style={{ width: '15px', height: '15px' }}
                                                                />
                                                            </Box>

                                                            <FormControl sx={{ width: '20%' }}>
                                                                <Select
                                                                    {...register(`services.${index}.employeeId`)}
                                                                    multiple
                                                                    size="small"
                                                                    value={field.employeeId || []}
                                                                    renderValue={(selected) => {
                                                                        const employees = field.employees || [];
                                                                        if (selected.length === employees.length) {
                                                                            return 'All employees';
                                                                        }
                                                                        if (selected.length === 1) {
                                                                            const selectedEmployee = employees.find(
                                                                                (employee) => employee.id === selected[0]
                                                                            );
                                                                            return selectedEmployee ? selectedEmployee.name : '';
                                                                        }
                                                                        return `${selected.length} employees`;
                                                                    }}
                                                                    sx={{
                                                                        height: 35,
                                                                        fontSize: '0.85rem',
                                                                        borderRadius: '10px',
                                                                        border: `1.5px solid #D9D9D9`,
                                                                        '& .MuiSelect-select': {
                                                                            color: '#545454',
                                                                            fontSize: '15px',
                                                                            fontWeight: 400,
                                                                        },
                                                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                                        '& .MuiSelect-icon': { color: '#000' },
                                                                        '@media (max-width: 1440px)': {
                                                                            width: '110%', // Adjust width on medium screens
                                                                            ml: 0.5,
                                                                        },
                                                                        '@media (max-width: 900px)': {
                                                                            width: '120%',
                                                                            ml: 1 // Adjust width on smaller screens
                                                                        },
                                                                    }}
                                                                >
                                                                    {field.employees?.length === 0 ? (
                                                                        <MenuItem disabled sx={{ color: 'black' }}>No employees available</MenuItem>
                                                                    ) : (
                                                                        field.employees?.map((employee) => (
                                                                            <MenuItem key={employee.id} value={employee.id} sx={{ m: 0 }}>
                                                                                <Checkbox
                                                                                    checked={field.employeeId?.includes(employee.id)}
                                                                                    onChange={() => handleCheckboxChange(employee.id, index)}
                                                                                    sx={{
                                                                                        color: field.employeeId?.includes(employee.id) ? '#44B904' : '#BDBDBD',
                                                                                        '&.Mui-checked': {
                                                                                            color: '#44B904',
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                {employee.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    )}
                                                                </Select>
                                                            </FormControl>

                                                            <IconButton onClick={() => { remove(index) }}>
                                                                <img
                                                                    src={DeleteIcon}
                                                                    alt="Delete"
                                                                    style={{ width: '17px', height: '19px', marginLeft: '@media (max-width: 1440px)' ? 10 : 0 }}
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            {/* Service Selection Dropdown */}
                            <Autocomplete
                                value={null}
                                options={filteredOptions}
                                getOptionLabel={(option) => option.name}
                                inputValue={searchText}
                                onInputChange={handleSearchInputChange}
                                groupBy={(option) => option.group}
                                fullWidth
                                disabled={type === 'edit'}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        placeholder={`+ Add service`}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            mt: 1,
                                            height: 45,
                                            fontSize: '0.85rem',
                                            borderRadius: '10px',
                                            border: `2px solid #D9D9D9`,
                                            '& .MuiInputBase-root': {
                                                padding: '5px',
                                            },
                                            '& .MuiInputBase-input': {
                                                color: '#545454',
                                                fontSize: '15px',
                                                fontWeight: 400,
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D9D9D9',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D9D9D9',
                                            },
                                            '& .MuiAutocomplete-popupIndicator': {
                                                color: '#000',
                                            },
                                            width: '100%',
                                            '& .MuiInputBase-input::placeholder': {
                                                color: '#A0A0A0',
                                                fontSize: '18px',
                                                fontWeight: 400
                                            },
                                        }}
                                    />
                                }
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                renderOption={(props, option) => (
                                    <>
                                        <li
                                            {...props}
                                            key={option.id}
                                            value={option.id}
                                            onClick={(e) => {
                                                if (option.name !== 'No services') {
                                                    handleServiceSelect(option);
                                                }
                                                e.preventDefault();
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#545454',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        </li>
                                        <Divider sx={{ margin: '8px 0' }} />
                                    </>
                                )}
                                renderNoOptions={(props) => (
                                    <Box {...props} sx={{ padding: 1 }}>
                                        <Typography variant="body2" color="error" align="center">
                                            No results found
                                        </Typography>
                                    </Box>
                                )}
                                renderGroup={(params) => (
                                    <li>
                                        <ListSubheader
                                            component="div"
                                            sx={{
                                                fontWeight: '700',
                                                fontSize: '20px',
                                                color: '#1F1F1F',
                                                '&.Mui-disabled': {
                                                    color: '#1F1F1F',
                                                },
                                                position: 'relative',
                                            }}
                                        >
                                            {params.group}
                                        </ListSubheader>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        {params.children}
                                    </li>
                                )}
                            />

                        </Box>

                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: 4
                }}>
                    <CommonButton
                        width="257px"
                        height="52px"
                        backgroundColor={'#44B904'}
                        onClick={() => createOrUpdateCampaignOffer()}
                        title={type == 'edit' ? 'Update campaign' : 'Add campaign'}
                    />
                </Box>

            </Box>

        </Modal >
    )
}

export default AddCampaignOfferModal;
