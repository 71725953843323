import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import PermissionSlice from "./permissionSlice";

// Configuration for redux-persist
const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, PermissionSlice);

const store = configureStore({
    reducer: {
        user: persistedReducer,
    },
});

export const persistor = persistStore(store);
export default store;
