import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import FormModel from './popup/FormModel';
import { dayChecker, dateIdFinder, getStartEndTime, eventFinder } from '../../utils/Functions';
import moment from 'moment';

export default function CalendarComponent({ controller, data,  setOpenFormModel }) {

    // const [openFormModel, setOpenFormModel] = useState(false);
    // const [formProps, setFormProps] = useState({});
    const refContainer = useRef();

    const handlePopup = ({ emp, weekday }) => {

        // console.log('emp', emp);
        // console.log('weekday', weekday);
        
        setOpenFormModel({openModel: true, formProps:{
            weekday,
            employee: emp,
            detailId: null,
            event: null
        } });
     
    }

    const handleEventPopup = ({ emp, weekday }) => {

        const event = getStartEndTime({ emp, date: weekday }).item;
        // console.log('detailId', detailId);

        // console.log('event', event);
        

        // console.log('emp', emp);
        // console.log('weekday', weekday);
        // console.log('detailId', detailId);
        
        setOpenFormModel({openModel: true, formProps:{
            weekday,
            employee: emp,
            detailId: event?.id ?? null,
            event:{
                ...event,
                end_break_time: event?.end_break_time ? (typeof event?.end_break_time == 'string' ? moment(event?.end_break_time, 'HH:mm:ss') :event?.end_break_time): null,
                end_date: event?.end_date ? (typeof event?.end_date == 'string' ? moment(event?.end_date, 'YYYY-MM-DD') :event?.end_date): null,
                end_time: event?.end_time ? (typeof event?.end_time == 'string' ? moment(event?.end_time, 'HH:mm:ss') :event?.end_time): null,
                repeat: event?.repeat === null ? "DO_NOT_REPEAT" : event?.repeat,
                start_break_time:  event?.start_break_time ? (typeof event?.start_break_time == 'string' ? moment(event?.start_break_time, 'HH:mm:ss') :event?.start_break_time): null,
                start_date: event?.start_date ? (typeof event?.start_date == 'string' ? moment(event?.start_date, 'YYYY-MM-DD') :event?.start_date): null,
                start_time: event?.start_time ? (typeof event?.start_time == 'string' ? moment(event?.start_time, 'HH:mm:ss') :event?.start_time): null,
               
                }
        } });
      
    }

    const calendarWidth = useMemo(()=>{

        if (refContainer.current) {
            return  refContainer.current.offsetWidth
            // setDimensions({
            //     width: refContainer.current.offsetWidth,
            //     height: refContainer.current.offsetHeight,
            // });
        }
        return 770
    },[controller])

    console.log('calendar outline width', calendarWidth);
    

    return (
        <Box
            className="scrollbar-hidden"
            id= "calendarOutline"
            ref={refContainer}
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, 1fr)',
                gridTemplateRows: '1fr',
                // minWidth: '100%',
                border: '1px solid #E0E0E0',
                borderRadius: 2,
                boxShadow: 1,
                maxHeight: '500px',
              
                overflow: 'scroll',
            }}>

            {(controller?.format === "evenweek" && controller?.weekNumber % 2 !== 0) ||
                (controller?.format === "oddweek" && controller?.weekNumber % 2 === 0) ? (

                    <Box display={'grid'} alignItems={'center'} justifyContent={'center'} height={'500px'} position={'absolute'}  sx={{  borderRadius: 2}} bgcolor={"#D9D9D9cc"} width={calendarWidth} zIndex={20} >
                    <Typography fontWeight={600} bgcolor={"white"} p={2} borderRadius={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} variant="body1">
                        This is an {controller?.format === "evenweek" ? "Even Week" : "Odd Week"}. Please proceed to next week
                    </Typography>
                </Box>

            ) : null}

         


            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 60,
                    borderBottom: '1px solid #E0E0E0',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: '#fff'
                }}>
                    <Typography variant="body1">Week {controller?.weekNumber}</Typography>
                </Box>
                {data?.employees_opening_hour && Object.entries(data.employees_opening_hour).map(([key, item]) => {

                    return (
                        <Box key={key} sx={{

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: '1px solid #E0E0E0',
                            p: 2,
                            height: 50,
                            // maxWidth:120
                            // minheight: 50,
                            // maxheight: 50,
                        }}>
                            <Typography variant="body2" sx={{
                                textAlign: 'center', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical", lineHeight: 1.3
                            }}>{`${item?.name}` || 'NOT AVAILABLE'}</Typography>
                        </Box>
                    )
                })}
            </Box>

            {controller?.weekDates?.map((weekday, index) => (
                <Box key={index} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderLeft: '1px solid #E0E0E0',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 60,
                        minWidth: 80,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: '#fff',
                        borderBottom: '1px solid #E0E0E0',
                    
                    }}>
                        <Typography variant="body2" sx={{fontWeight: 700}}>{moment(weekday).format('ddd. DD. MMM')}</Typography>
                    </Box>
                    {data?.employees_opening_hour && Object.entries(data.employees_opening_hour).map(([empKey, emp], index1) => {




                        return (
                            <Box key={empKey} sx={{
                                height: 50,
                                width: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 1,
                                pb:1,
                                borderTop: index1 ==0 ?null : '1px solid #E0E0E0',
                                "& .appear-item": {
                                display: "none"
                                },
                                "&:hover .appear-item": {
                                display: "block"
                                },
                                cursor:'pointer',
                            }}
                                onClick={
                                    
                                    dayChecker(emp, controller, index) ? () => handleEventPopup({ emp, weekday }) : () => handlePopup({ emp, weekday })
                                }>

                         
                             

                                {dayChecker(emp, controller, index) ?
                                    <Box
                                        sx={{
                                            backgroundColor: '#44B904',
                                            borderRadius: 2,
                                            p: 0.7,
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 'auto',
                                            height: '100%',
                                            cursor:'pointer'
                                        }}>
                                        <Typography variant="body2" >  {getStartEndTime({ emp, date: weekday })?.startTime} - {getStartEndTime({ emp, date: weekday })?.endTime} </Typography>
                                    </Box>
                                    :
                                    <span className = 'appear-item' >
                                    +
                                 </span>
                                }
                            </Box>

                        )
                    }
                    )}
                </Box>
            ))}

            {/* {openFormModel && <FormModel formProps={formProps} setData={setData} open={openFormModel} onClose={() => setOpenFormModel(false)} />} */}
        </Box>
    );
}
