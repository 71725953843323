import axios, { HttpStatusCode } from "axios";


const apiFetcher = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
    }
})

apiFetcher.interceptors.response.use(function (response) {
    if (response.status === HttpStatusCode.Ok) {
        return response;
    } else if (response.status === HttpStatusCode.Forbidden) {
        return Promise.reject("Forbidden");
    } else if (response.status === HttpStatusCode.BadRequest) {
        return Promise.reject("Bad Request");
    } else if (response.status === HttpStatusCode.NotFound) {
        return Promise.reject("Not Found");
    } else if (response.status === HttpStatusCode.InternalServerError) {
        return Promise.reject("Internal Server Error");
    } else if (response.status === HttpStatusCode.ServiceUnavailable) {
        return Promise.reject("Service Unavailable");
    } else if (response.status === HttpStatusCode.Unauthorized) {
        return Promise.reject("Unauthorized");
    }
}, function (error) {
    return Promise.reject(error.response.data.message);
});


export default apiFetcher;