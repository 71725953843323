import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
// import "./BookingDetailModal.css"; // Custom CSS for styling
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import "./BookingForm.css";
import backButton from "../../assets/less.png";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import vectorImg from "../../assets/Vector.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BookingForm from "./BookingForm";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from "@mui/material";

const BookingDetailModal = ({
  booking,
  show,
  onClose,
  refreshBookings,
  closeForm,
  initialData,
}) => {
  // useEffect(() => {
  //   // Set default values from initialData if provided
  //   if (initialData) {
  //     setCustomerName(initialData.customerName);
  //     setPhoneNumber(initialData.phone);
  //     setSelectedDate(dayjs(initialData.date));
  //     setSelectedService(initialData.serviceId);
  //     setSelectedEmployee({
  //       id: initialData.employeeId,
  //       name: initialData.employeeName,
  //     });
  //     setSelectedTimeSlots(initialData.timeSlot);
  //     setServicePrice(initialData.price);
  //   }
  // }, [initialData]);

  console.log("booking in reschedule", booking);


  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerExists, setCustomerExists] = useState(false);
  const [error, setError] = useState("");
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState("");
  // const [totalAmount, setTotalAmount] = useState(""); // New state to track total amount
  const [employeeId, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  ); // Example employee ID
  const [servicePrice, setServicePrice] = useState(0); // Store service price
  // const [startTime, setStartTime] = useState(null);
  // const [endrtTime, setEndTime] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState(null); // Track active accordion group
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [employees, setEmployees] = useState([]); // State to hold employees
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: "",
    name: "",
  });
  // console.log(">>>>", booking.created);
  const [selectedDate, setSelectedDate] = useState(
    booking ? dayjs(booking.date) : dayjs()
  );
  const [openingHours, setOpeningHours] = useState(null);
  const [closingHours, setClosingHours] = useState(null);
  const [scheduldes, setSchedules] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const confirmCancelBooking = async () => {
    const result = await updateBookingStatus("CANCELLED");
    if (result) {
      setShowCancelConfirmation(false); // Close modal after canceling
    }
  };

  const handleCancelBooking = () => {
    setShowCancelConfirmation(true); // Open cancel confirmation modal
  };

  const handleRescheduleBooking = () => {
    fetchAvailableTimeSlots(selectedService, selectedDate, selectedEmployee);  
    setShowTimeSlotModal(true);
    setSelectedEmployee(selectedEmployee); 
    fetchEmployee(booking.serviceId);
  };
  const navigate = useNavigate();

  
  useEffect(() => {
    if (selectedEmployee && selectedEmployee.id) {
      fetchAvailableTimeSlots(selectedService, selectedDate, selectedEmployee);  
    }
  }, [selectedEmployee, selectedService, selectedDate]); 

  useEffect(() => {
    if (booking && booking.date) {
      setSelectedDate(dayjs(booking.date)); 
      fetchAvailableTimeSlots(booking.date); 
    }
  }, [booking]);


useEffect(() => {
  if (booking && booking.employeeId) {
    setSelectedEmployee({
      id: booking.employeeId,
      name: booking.employeeName,
    });
  }
}, [booking]);

  // Map actual status to display names
  const getDisplayStatus = (status) => {
    switch (status) {
      case "BOOKED":
        return "Pending";
      case "COMPLETED":
        return "Completed";
      case "CANCELLED":
        return "Cancelled";
      case "OFFER_ACCEPTED":
        return "Cancellation offer accepted";
      case "NOSHOW":
        return "Absence from booking";
      case "OFFERED":
        return "Awaiting new customer";
      default:
        return status;
    }
  };

  // API URL and Authorization
  const apiUrl = process.env.REACT_APP_URL;
  const authTokenUser = localStorage.getItem("auth_token");
  const updateBookingStatus = async (status) => {
    if (!booking.id) {
      toast.error("Booking ID is missing");
      return false;
    }

    const payload = { id: booking.id };
    let endpoint = "";

    switch (status) {
      case "COMPLETED":
        endpoint = `${apiUrl}/api/v1/store/booking/complete`;
        break;
      case "CANCELLED":
        endpoint = `${apiUrl}/api/v1/store/booking/cancel`;
        break;
      case "BOOKED":
        endpoint = `${apiUrl}/api/v1/store/booking/pending`;
        break;
      case "NOSHOW":
        endpoint = `${apiUrl}/api/v1/store/booking/noshow`;
        break;
      case "DELETED":
        endpoint = `${apiUrl}/api/v1/store/booking/delete`;
        break;
      case "RESCHEDULED":
        endpoint = `${apiUrl}/api/v1/store/booking/reschedule`;
        payload.booking_date = formatDate(booking.date);
        payload.time_slot = `${formatTime(booking.startTime, booking.endTime)}`;
        payload.employee_id = booking.employeeId;
        payload.total_amount = booking.price;
        break;
      default:
        return false;
    }

    try {
      const response = await axios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${authTokenUser}`,
        },
      });

      if (response.data.success) {
        toast.success(
          response.data.msg || "Booking status updated successfully"
        );
        refreshBookings();
        onClose();
        navigate("/calendar");
        return true;
      } else {
        toast.error(response.data.msg || "Failed to update booking status");
        return false;
      }
    } catch (error) {
      // toast.error(error.response?.data?.msg || "An error occurred while updating booking status.");
      return false;
    }
  };


  // Function to fetch booking times
  const fetchBookingTimes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/store/schedule`,
        {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        }
      );
      // Assuming response contains an array of time slots with 'start' and 'end' properties
      const responseData = response.data.data;
      // console.log("????", responseData);
      setSchedules(responseData);
      const openTimes = response?.data?.data.map((item) => item.open_time);
      setOpeningHours(openTimes);
      const closeTimes = response?.data?.data.map((item) => item.close_time);
      setClosingHours(closeTimes)
    } catch (error) {
      console.error("Error fetching booking times:", error);
    }
  };

  useEffect(() => {
    fetchBookingTimes();
  }, []);

  const fetchEmployee = async (serviceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/store/service/${serviceId}`,
        {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        }
      );
      const employeeData = response.data?.data?.employees;
      console.log("response>>>" , response);
      console.log("employeeData>>>" , employeeData);
      
      setEmployees(employeeData); 
    } catch (error) {
      console.error("Error fetching service data:", error);
      toast.error(
        "Failed to fetch the service. Please try again."
      );
    }
  };
  
    const handleEmployeeChange = useCallback((e) => {
      const selectedId = e.target.value;
      const selectedEmployeeObj = employees.find(
        (emp) => emp.id.toString() === selectedId
      );
      console.log("employees"  , employees);
      
      const selectedName = selectedEmployeeObj ? selectedEmployeeObj.name : "";
      setSelectedEmployee({
        id: selectedId,
        name: selectedName,
      });
      if (selectedId !== "0") {
        fetchAvailableTimeSlots(selectedService, selectedDate, selectedId); 
      } else {
        fetchAvailableTimeSlots(selectedService, selectedDate); 
      }
  
    }, [employees, selectedService, selectedDate]);

  if (!booking) return null;

  // Formatting Date and Time for display

  const formatDate = (date) => dayjs(date).format("DD/MM/YYYY");

  const formatTime = (start, end) =>
    `${dayjs(start).format("HH:mm")} - ${dayjs(end).format("HH:mm")}`;
  // const formatDate = (date) =>
  //   date.format("DD/MM/YYYY", {
  //     day: "2-digit",
  //     month: "short",
  //     year: "numeric",
  //   });
  // const formatTime = (start, end) =>
  //   `${dayjs(start).format([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   })} - ${dayjs(end).format([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   })}`;




  // Button handlers
  const handleMarkAsPending = async () => {
    const result = await updateBookingStatus("BOOKED");
    if (result) {
      // Successful toast message already handled in updateBookingStatus
    }
  };

  const handleMarkAsCompleted = async () => {
    const result = await updateBookingStatus("COMPLETED");
    if (result) {
      // Successful toast message already handled in updateBookingStatus
    }
  };

  // const handleCancelBooking = async () => {
  //   const result = await updateBookingStatus("CANCELLED");
  //   if (result) {
  //     // Successful toast message already handled in updateBookingStatus
  //   }
  // };

  const handleAbsenceFromBooking = async () => {
    const result = await updateBookingStatus("NOSHOW");
    if (result) {
      // Successful toast message already handled in updateBookingStatus
    }
  };

  const handleDeleteBooking = () => {
    setShowDeleteConfirmation(true); // Open delete confirmation modal
  };
  const confirmDeleteBooking = async () => {
    const result = await updateBookingStatus("DELETED");
    if (result) {
      setShowDeleteConfirmation(false); // Close modal after deletion
    }
  };



  // const handleRescheduleBooking = () => updateBookingStatus("RESCHEDULED");
  // const handleRescheduleBooking = () => {
  // setShowTimeSlotModal(true); // Open the time slot modal for rescheduling
  //   fetchAvailableTimeSlots(selectedService); // Fetch time slots for the selected service
  // };
  // Render buttons based on status
  const renderActionButtons = () => {
    const currentTime = dayjs(); // Get the current time
    const bookingTime = dayjs(booking.startTime); // Replace with the actual booking time property

    const isTimePassed = currentTime > bookingTime; // Check if the current time has passed the booking time
    switch (booking.status) {
      case "BOOKED":
        return (
          <>
            {isTimePassed && (
              <>
                <button className="modal-button" onClick={handleMarkAsCompleted}>
                  Mark as completed
                </button>
                <button className="modal-button" onClick={handleAbsenceFromBooking}>
                  Absence from booking
                </button>
              </>
            )}
            <button className="modal-button" onClick={handleCancelBooking}>
              Cancel booking
            </button>
            <button className="modal-button" onClick={handleRescheduleBooking}>
              Reschedule booking
            </button>
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      case "CANCELLED":
        return (
          <>
            <button className="modal-button" onClick={handleMarkAsPending}>
              Mark as pending
            </button>
            <button className="modal-button" onClick={handleRescheduleBooking}>
              Reschedule booking
            </button>
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <button className="modal-button" onClick={handleMarkAsPending}>
              Mark as pending
            </button>
            <button className="modal-button" onClick={handleCancelBooking}>
              Cancel booking
            </button>
            <button className="modal-button" onClick={handleRescheduleBooking}>
              Reschedule booking
            </button>
            {isTimePassed && (
              <button className="modal-button" onClick={handleAbsenceFromBooking}>
                Absence from booking
              </button>
            )}
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      case "NOSHOW":
        return (
          <>
            <button className="modal-button" onClick={handleMarkAsPending}>
              Mark as pending
            </button>
            {isTimePassed && (
              <>
                <button className="modal-button" onClick={handleMarkAsCompleted}>
                  Mark as completed
                </button>
                <button className="modal-button" onClick={handleCancelBooking}>
                  Cancel booking
                </button>
              </>
            )}
            <button className="modal-button" onClick={handleRescheduleBooking}>
              Reschedule booking
            </button>
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      case "RESCHEDULED":
        return (
          <>
            <button className="modal-button" onClick={handleCancelBooking}>
              Cancel booking
            </button>
            <button className="modal-button" onClick={handleRescheduleBooking}>
              Reschedule booking
            </button>
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      case "OFFER_ACCEPTED":
        return (
          <>
            {isTimePassed && (
              <button className="modal-button" onClick={handleMarkAsCompleted}>
                Mark as completed
              </button>
            )}
            {isTimePassed && (
              <button className="modal-button" onClick={handleAbsenceFromBooking}>
                Absence from booking
              </button>
            )}
          </>
        );
      case "OFFERED":
        return (
          <>
            <button className="modal-button" onClick={handleCancelBooking}>
              Cancel booking
            </button>
            <button className="modal-button" onClick={handleDeleteBooking}>
              Delete booking
            </button>
          </>
        );
      default:
        return null;
    }
  };


  const fetchAvailableTimeSlots = async (service, date , selectedEmployee) => {
    try {
      // Use the passed date or fallback to the state date
      const selected = date || selectedDate;
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/store/booking/timings?service_id=${booking.serviceId
        }&whole_day=true&date=${selected.format("YYYY-MM-DD")}&employee_id=${selectedEmployee.id}`,
        {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        }
      );
      if (response.data.success) {
        const bookedSlots = response.data.data;

        // Filter time slots
        const filteredSlots = bookedSlots.map((element) => {
          if (element.startsWith("0")) {
            return element.slice(1);
          } else {
            return element;
          }
        });
        setAvailableTimeSlots(filteredSlots);
        setShowServiceModal(false);
      } else {
        setError("Failed to fetch available time slots.");
      }
    } catch (error) {
      console.error("Error fetching time slots:", error);
      setError("Failed to fetch available time slots.");
    }
  };

  const openConfirmationModal = () => {
    // setShowConfirmationModal(true);
    setShowTimeSlotModal(false);
    if (!selectedEmployee) {
      toast.error("Please select an employee before confirming.");
      // return;
      setShowServiceModal(false);
      setShowTimeSlotModal(true); // Open the time slot modal only if employees are available
      return;
    }
    setShowConfirmationModal(true);
  };

  // const handleToggleAccordion = (groupId) => {
  //   setActiveAccordion(activeAccordion === groupId ? null : groupId);
  // };


  const dayMap = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  // Function to generate time slots from 9:00 to 16:55 in 5-minute intervals
  const slots = [];

  const generateTimeSlots = () => {
    const currentDate = dayjs();
    const dayOfWeek = currentDate.day(); // 0 for Sunday, 1 for Monday, ..., 3 for Wednesday, etc.

    // Set different start and end times based on the day of the week
    let startHour = 0;
    let endHour = 23;

    // if (dayOfWeek === 3) {
    //   // 3 represents Wednesday
    //   startHour = 12; // Start at 12 PM on Wednesdays
    //   endHour = 16; // End at 4 PM (16:55)
    // }

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        if (!(hour === endHour && minute > 55)) {
          // Only allow until the end hour's last 5-minute slot
          const formattedTime = `${hour}:${minute.toString().padStart(2, "0")}`;
          slots.push(formattedTime);
        }
      }
    }

    return slots;
  };

  const updatedSlots = slots.map((time) => ({
    time,
    selectable: availableTimeSlots.includes(time), // Mark as selectable if time is in availableTimeSlots
  }));

  // console.log("updatedSlots",availableTimeSlots);
  // console.log("updatedSlots",updatedSlots);

  const timeSlots = generateTimeSlots();

  const formatTimeSlots = (timeSlots) => {
    return timeSlots.map((slot) => {
      // Parse the current slot time
      const [hours, minutes] = slot.split(":").map(Number);

      // Add 1 hour to calculate the end time
      const endHours = (hours + 1) % 24; // Ensure it wraps around midnight if needed

      // Format start and end times to ensure two-digit hours
      const startTime = `${String(hours).padStart(1)}:${String(minutes).padStart(2, "0")}`;
      const endTime = `${String(endHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;

      // Return the formatted range
      return `${startTime} - ${endTime}`;
    });
  };

  const formattedTimeSlots = formatTimeSlots(timeSlots);

  const selectedDayIndex = dayjs(selectedDate).day();
  const selectedDayName = dayMap[selectedDayIndex];
  const scheduleForSelectedDay = scheduldes.find(schedule => schedule.day === selectedDayName);
  const outOfWorkingHours = formattedTimeSlots.filter((slots => !availableTimeSlots.includes(slots)));

  const openinghours = scheduleForSelectedDay?.open_time;
  const closinghours = scheduleForSelectedDay?.close_time;
  // Function to open the service modal

  // Function to handle service selection


  const handleSelectedTimeSlot = (selectedSlot) => {
    const matchedSlot = availableTimeSlots.find((slot) => {
      return slot.startsWith(selectedSlot);
    });

    if (matchedSlot) {
      setSelectedTimeSlots((prevSelectedSlot) => {
        if (
          prevSelectedSlot &&
          prevSelectedSlot !== "" &&
          prevSelectedSlot === matchedSlot
        ) {
          // alert("if")
          return ""; // Deselect
        } else {
          // alert("else")
          // Otherwise, select the matched slot
          return matchedSlot;
        }
      });
    }
  };

  // const handleTimeSlotSelect = (selectedTime) => {
  //   // Find the matching time slot range from availableTimeSlots
  //   const matchedSlot = availableTimeSlots.find((slot) =>
  //     slot.startsWith(selectedTime)
  //   );

  //   if (matchedSlot) {
  //     // If the selected time is already in the array, remove it
  //     setSelectedTimeSlots((prevSelectedSlot) => {
  //       // alert(prevSelectedSlot.time)
  //       // Deselect if already selected
  //       if (
  //         prevSelectedSlot &&
  //         prevSelectedSlot !== "" &&
  //         prevSelectedSlot === matchedSlot
  //       ) {
  //         // alert("if")
  //         return ""; // Deselect
  //       } else {
  //         // alert("else")
  //         // Otherwise, select the matched slot
  //         return matchedSlot;
  //       }
  //     });
  //   }
  // };

  // Helper function to get the selected employee's name

  // const splitedate = () =>{
  //   return availableTimeSlots.some(range => {}

  //   )
  // }

  // console.log("slot", selectedTimeSlots);
  // console.log("slotdrerr",availableTimeSlots.findIndex(availableSlot => availableSlot.includes(slots[0])) !== -1);

  const confirmReschedule = async () => {
    // const localDate = dayjs(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
    const localDate = dayjs(selectedDate.toDate().getTime() - selectedDate.toDate().getTimezoneOffset() * 60000);
    // console.log(localDate.format());
    // Format as YYYY-MM-DD
    try {
      const payload = {
        id: booking.id,
        booking_date: localDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
        time_slot: selectedTimeSlots, // Selected time slot
        employee_id: selectedEmployee.id, // Employee ID
        total_amount: booking.price, // Total amount
      };

      // console.log("Confirm reschedule payload:", payload); // Debugging to check payload

      const endpoint = `${apiUrl}/api/v1/store/booking/reschedule`;

      const response = await axios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${authTokenUser}`,
        },
      });

      if (response.data.success) {
        toast.success("Booking rescheduled successfully");
        setShowTimeSlotModal(false); // Close modal
        onClose();
        refreshBookings(); // Refresh bookings
      } else {
        toast.error("Failed to reschedule booking");
      }
    } catch (error) {
      console.error("Error rescheduling booking:", error.response.data.detail);
      toast.error(error.response.data.detail);
    }
  };


  // console.log("slot", selectedTimeSlots);
  // console.log(
  //   "slotdrerr",
  //   availableTimeSlots.findIndex((availableSlot) =>
  //     availableSlot.includes(slots[0])
  //   ) !== -1
  // );
  const formattedDate = moment(booking.created).format("DD/MM-YY (HH:mm)");

  const groupTimeSlotsByHour = (timeSlots) => {
    return timeSlots.reduce((acc, time) => {
      const hour = time.split(":")[0];
      if (!acc[hour]) acc[hour] = [];
      acc[hour].push(time);
      return acc;
    }, {});
  };


  return (
    <>
      <ToastContainer />
      {/* Modal to display available time slots */}
      <Modal
        show={showTimeSlotModal}
        onHide={() => setShowTimeSlotModal(false)}
        className="totelpot sotuot"
      >
        <Modal.Header>
          <button
            className="prev-button"
            onClick={() => {
              setShowTimeSlotModal(false);
              setShowServiceModal(true); // Reopen the Service Modal
            }}
          >
            <img src={backButton} style={{ height: '25px' }} />
          </button>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Modal.Title
              className="mb-0"
              style={{ margin: "0 auto", fontWeight: "400", fontSize: "35px" }}
            >
              Manual Booking
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-4">
            <Col md={9}></Col>
            <Col md={3}>
              {" "}
              <div className="employee-dropdown ">
                {employees.length > 0 ? (
                  <Form.Group controlId="employeeSelect" className="mb-0">
                    <Form.Control
                      as="select"
                      value={selectedEmployee.id}
                      onChange={handleEmployeeChange}
                    >
                      <option value="0">Any/all</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <p className="mb-0">No employees available</p>
                )}
              </div>
            </Col>
          </Row>

          <Row className="doted">
            <Col md={6} className="Bookingcalendar-container">
              {/* <Calendar onChange={setSelectedDate} value={selectedDate} /> */}
              {/* <Calendar
                onChange={(date) => {
                  setSelectedDate(date); // Update the selected date
                  fetchAvailableTimeSlots(selectedService, date); // Pass the updated date to the function
                  setSelectedTimeSlots([]);
                }}
                value={selectedDate} // Set the initial date from `selectedDate`
              /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <Box className="bookingcalendar-Box" sx={{
                  '& .MuiDateCalendar-root': {
                    margin: 0,
                    padding: 0,
                    height: '100%',
                    boxSizing: 'border-box',
                  },
                  '& .MuiDateCalendar-viewTransitionContainer': {
                    marginBottom: 0,
                  },

                }}>
                  <DateCalendar
                    // defaultValue={dayjs()}
                    value={selectedDate}

                    sx={{
                      width: '100%',
                      maxHeight: '450px !important',
                      height: '100%',
                      overflow: 'hidden',
                      padding: 0,
                      margin: 0,

                      '& .MuiPickersDay-root': {
                        color: 'black',
                        fontSize: '18px',
                        padding: '25px',
                      },
                      '& .Mui-selected': {
                        backgroundColor: '#BBB0A4 !important',
                        color: 'white !important',
                        fontSize: '18px',
                      },
                      '& .MuiPickersCalendarHeader-label': {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#000',
                        marginLeft: '40px',
                      },
                      '& .MuiTypography-root': {
                        color: '#5b6980',
                        fontSize: '18px',
                        padding: '25px'
                      },
                      '& .MuiIconButton-root': {
                        color: 'black',
                        fontSize: '18px',
                      },
                    }}
                    onChange={(date) => {
                      setSelectedDate(date); // Update the selected date
                      fetchAvailableTimeSlots(selectedService, date, selectedEmployee); // Pass the updated date to the function
                      // setSelectedEmployee(selectedEmployee.id , selectedEmployee.name);
                      setSelectedTimeSlots("");
                    }}
                  />
                </Box>
              </LocalizationProvider>

            </Col>
            <Col md={6} className="time-slot-container">
              <h5>Available Time Slots</h5>
              {availableTimeSlots.length > 0 ? (
                <div className="time-slot-list">
                  {Object.entries(groupTimeSlotsByHour(timeSlots)).map(([hour, slots], index) => (
                    <div key={index} className="hour-group">
                      {/* Hour Label */}
                      <div className="hour-label">
                        {hour}
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}>
                        {slots.map((slot, slotIndex) => {
                          const startTime = slot.split(" - ")[0]; // Extract the start time
                          const isStartTimeAvailable = availableTimeSlots.some((availableSlot) =>
                            availableSlot.startsWith(startTime)
                          );

                          const isOutOfWorkingHours =
                            moment(startTime, 'HH:mm:ss').isBefore(moment(openinghours, 'HH:mm:ss')) ||
                            moment(startTime, 'HH:mm:ss').isSameOrAfter(moment(closinghours, 'HH:mm:ss'));

                          let bgColor;

                          if (!isOutOfWorkingHours) {
                            if (isStartTimeAvailable) {
                              bgColor = "white"; // White for available slots
                            } else {
                              bgColor = "rgba(199,65,65,50%)"; // Red for unavailable slots
                            }
                          }
                          else {
                            bgColor = "rgba(84,84,84,10%)"; // Gray for out-of-working-hours slots
                          }

                          if (selectedTimeSlots.split(" - ")[0] === startTime) {
                            // console.log('in ifff');

                            bgColor = "#BBB0A4"; // Brown background for selected slot 
                          }
                          return (
                            <div
                              key={slotIndex}
                              className={`time-slot-item ${isStartTimeAvailable ? "" : "booked"
                                }`}
                              // onClick={() =>
                              //   isStartTimeAvailable && handleTimeSlotSelect(slot)
                              // }
                              onClick={() => handleSelectedTimeSlot(slot)}
                              style={{
                                display: "inline-block",
                                width: "calc(16.66% - 10px)", // To fit 6 boxes per row
                                padding: "14px 15px",
                                margin: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "12px",
                                cursor: !isStartTimeAvailable && !isOutOfWorkingHours ? "not-allowed" : "pointer",
                                backgroundColor: bgColor,
                                color: selectedTimeSlots.split(" - ")[0] === startTime ? "white" : "black",
                              }}
                            >
                              {slot}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No available time slots for the selected service.</p>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <button className="add-employee" onClick={confirmReschedule}>
          Confirm Rescheduled Time Slot
        </button>
      </Modal>
      {/* Confirmation Modal */}
      <Modal
        show={show && !showTimeSlotModal}
        onHide={onClose}
        centered
        className="ruted foltpt"
      >
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              position: "absolute",
              left: "38px",
              cursor: "pointer",
              paddingTop: "26px",
              top: "8px",
            }}
            onClick={onClose}
          >
            <img src={vectorImg} alt="Vector" className="img-top" />;
          </span>
          <Modal.Title
            className="Booking-Details"
            style={{
              margin: "0 auto",
              fontWeight: "400",
              fontSize: "35px",
              // marginBottom: "2rem",
            }}
          >
            Booking Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-5">
          <div className="details">
            <p>
              <strong>Name</strong> {booking.name || "N/A"}
            </p>
            <p>
              <strong>Phone nr.</strong> {booking.phone || "N/A"}
            </p>
            <p>
              <strong>Employee</strong> {booking.employee || "N/A"}
            </p>
            <p>
              <strong>Service</strong> {booking.service || "N/A"}
            </p>
            <p>
              <strong>Date</strong> {formatDate(booking.date)}
            </p>
            <p>
              <strong>Time</strong>{" "}
              {formatTime(booking.startTime, booking.endTime)}
            </p>
            <p>
              <strong>Status</strong>{" "}
              <span className={`status.${booking.status.toLowerCase()}`}>
                {getDisplayStatus(booking.status)}
              </span>
            </p>
            <p>
              <strong>Created</strong> {formattedDate}
            </p>
            {/* <p style={{border:"none"}}>
              <strong>Price:</strong> ${booking.price}
            </p> */}
          </div>

          {/* Render the action buttons based on the booking status */}
          <div className="action-buttons">{renderActionButtons()}</div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
        className="confirm-form"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title className="modal-title-centered">
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <p className="modal-confirmation-text">
            Are you sure you want to delete this booking?
          </p>
          <Row>
            <Col md={6} className="text-center">
              <button
                className="modal-button modal-button-yes"
                onClick={confirmDeleteBooking}
              >
                Yes
              </button>
            </Col>
            <Col md={6} className="text-center">
              <button
                className="modal-button modal-button-no"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                No
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Confirm Cancel Modal */}
      <Modal
        show={showCancelConfirmation}
        onHide={() => setShowCancelConfirmation(false)}
        centered
        className="confirm-form"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title className="modal-title-centered">
            Confirm Cancel
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <p className="modal-confirmation-text">
            Are you sure you want to Cancel this booking?
          </p>
          <Row>
            <Col md={6} className="text-center">
              <button
                className="modal-button modal-button-yes"
                onClick={confirmCancelBooking}
              >
                Yes
              </button>
            </Col>
            <Col md={6} className="text-center">
              <button
                className="modal-button modal-button-no"
                onClick={() => setShowCancelConfirmation(false)}
              >
                No
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingDetailModal;
