import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  Switch,
  MenuItem,
  IconButton,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Skeleton,
  Container,
} from "@mui/material";

import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import PencnlIconImg from "../../../assets/edit-2.png";
import DeleteIconImg from "../../../assets/DeleteIcon.png";

import apiFetcher from "../../../utils/interCeptor";
import { useFormik } from "formik";
import CustomDeleteModal from "../../deleteAlertModal";
import CommonButton from "../commonButton";
import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import CustomSelect from "../commonCustomSelect";

import EmployeeModel from "../calendar/popup/EmployeeModel";
import FSwitch from "../../commonComponents/f-switch";
import { useSelector } from "react-redux";
import Notauthorized from "../../commonComponents/F_Notauthorized";

// const employees = [
//     { id: 1, name: 'employee no 1' },
//     { id: 2, name: 'employee no 2' },
//     { id: 3, name: 'employee no 3' },
//     { id: 4, name: 'employee no 4' },
//     { id: 5, name: 'employee no 5' }
// ];

const initialOptions = [
  { label: "Change permissions", id: "change_permissions" },
  { label: "Create employees", id: "create_employee" },
  { label: "Create/edit/delete special offers", id: "crud_special_offers" },
  { label: "Create/edit/delete services", id: "crud_services" },
  { label: "Create/edit/delete service groups", id: "crud_service_groups" },
  { label: "Change department", id: "change_department" },
  { label: "Create department", id: "create_department" },
  { label: "View all insights", id: "view_insights" },
  { label: "Waiting list", id: "waiting_list" },
  { label: "Upload pictures", id: "upload_pictures" },
  { label: "Edit in ‘about us’", id: "edit_about_us" },
];

const openignHoursOptions = [
  {
    label: "Change calendar interval for all",
    id: "change_all_calender_interval",
  },
  {
    label: "Change calendar interval for own",
    id: "change_own_calender_interval",
  },
  { label: "Change opening hours for all", id: "change_all_opening_hours" },
  { label: "Change opening hours for own", id: "change_own_opening_hours" },
];

const calendarOptions = [
  { label: "View all employees", id: "view_all_employees" },
  { label: "Delete all bookings", id: "delete_all_bookings" },
  { label: "Delete own bookings", id: "delete_own_bookings" },
  { label: "Reschedule all bookings", id: "reschedule_all_bookings" },
  { label: "Reschedule own bookings", id: "reschedule_own_bookings" },
  {
    label: "Create/edit/delete cancellation offer",
    id: "crud_cancellation_offer",
  },
];

const customersOptions = [
  { label: "Create customers", id: "create_customers" },
  { label: "Edit customers", id: "edit_customers" },
  { label: "Delete customers", id: "delete_customers" },
];

const journalOptions = [
  { label: "Create journals", id: "create_journals" },
  { label: "Edit all journals", id: "edit_all_journals" },
  { label: "Edit own journals", id: "edit_own_journals" },
  { label: "View all journals", id: "view_all_journals" },
  { label: "View only own journals", id: "view_own_journals" },
];

const EmployeeSettingsOption = () => {
  const user = useSelector((state) => state.user.data);
  const permissions = user.settings;

  const [options, setOptions] = useState(initialOptions);
  const [optionsOpeningHours, setOptionsOpeningHours] =
    useState(openignHoursOptions);
  const [optionsCalenderOptions, setOptionsCalenderOptions] =
    useState(calendarOptions);
  const [optionsCustomerOptions, setOptionsCustomerOptions] =
    useState(customersOptions);
  const [optionsJournalOptions, setOptionsJournalOptions] =
    useState(journalOptions);
  // const [selectedEmployee, setSelectedEmployee] = React.useState('');

  // Add/Edit employee states
  const [showModal, setShowModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [journalAccess, setJournalAccess] = useState(false);
  const [image, setImage] = useState(null);
  const [role, setRole] = useState("EMPLOYEE");
  const [employeeId, setEmployeeId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: "",
    phone: "",
    accessCode: "",
    journalAccess: false,
    image: "",
    role: "",
    employeeId: null,
    allPermission: false,
    permission: {
      change_password: false,
      create_employee: false,
      crud_special_offers: false,
      crud_services: false,
      crud_service_groups: false,
      change_department: false,
      create_department: false,
      view_insights: false,
      waiting_list: false,
      upload_pictures: false,
      edit_about_us: false,

      change_all_calender_interval: false,
      change_own_calender_interval: false,
      change_all_opening_hours: false,
      change_own_opening_hours: false,

      view_all_employees: false,
      delete_all_bookings: false,
      delete_own_bookings: false,
      reschedule_all_bookings: false,
      reschedule_own_bookings: false,
      crud_cancellation_offer: false,

      create_customers: false,
      edit_customers: false,
      delete_customers: false,

      create_journals: false,
      edit_all_journals: false,
      edit_own_jourtnals: false,
      view_all_journals: false,
      view_own_journals: false,
    },
    selectedEmployee: {
      id: null,
      name: "test",
      phone: "123123",
      accessCode: "23432",
      journalAccess: false,
      image: "",
      role: "",
    },
  });

  console.log("user", user.role);
  console.log("permission", permissions);

  const getEmployees = async () => {
    try {
      const response = await apiFetcher.get("/api/v1/store/employee/get");

      const { success, data } = response.data;
      if (success) {
        // console.log('data', data);

        const dataValue = data.map((dataObj) => {
          // console.log('dataObj', JSON.stringify(dataObj));
          let newData = JSON.stringify(dataObj);
          let newObj = { ...JSON.parse(newData) };
          const permission = { ...newObj.settings };
          // console.log('permission', permission);
          delete newObj.settings;

          return {
            ...newObj,
            label: newObj.name,
            value: newObj.id,
            permission,
            selectedEmployee: false,
          };
        });
        console.log("dataValue", dataValue);

        setEmployees(dataValue);
        if (data.length > 0) {
          let foundEmployee = data[0];
          let allPermission = true;
          Object.values(foundEmployee.settings).map((settingsValue) => {
            if (!settingsValue) {
              allPermission = false;
            }
          });

          let permission = {
            change_password: false,
            create_employee: false,
            crud_special_offers: false,
            crud_services: false,
            crud_service_groups: false,
            change_department: false,
            create_department: false,
            view_insights: false,
            waiting_list: false,
            upload_pictures: false,
            edit_about_us: false,

            change_all_calender_interval: false,
            change_own_calender_interval: false,
            change_all_opening_hours: false,
            change_own_opening_hours: false,

            view_all_employees: false,
            delete_all_bookings: false,
            delete_own_bookings: false,
            reschedule_all_bookings: false,
            reschedule_own_bookings: false,
            crud_cancellation_offer: false,

            create_customers: false,
            edit_customers: false,
            delete_customers: false,

            create_journals: false,
            edit_all_journals: false,
            edit_own_jourtnals: false,
            view_all_journals: false,
            view_own_journals: false,
          };
          permission = { ...foundEmployee.settings };
          delete foundEmployee.settings;

          console.log("permission", permission);

          console.log("obj", {
            ...foundEmployee,
            permission,
            selectedEmployee: null,
          });

          formik.setValues({
            ...foundEmployee,
            permission,
            selectedEmployee: {
              id: null,
              name: "",
              phone: "",
              accessCode: "",
              journalAccess: false,
              image: "",
              role: "",
            },
          });
          if (!initialValues?.id) {
            setInitialValues({
              ...foundEmployee,
              permission,
              selectedEmployee: {
                id: null,
                name: "",
                phone: "",
                accessCode: "",
                journalAccess: false,
                image: "",
                role: "",
              },
            });
          }
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log("err", err);
    }
  };

  const updatePermissionAPI = async (value) => {
    try {
      // console.log('values', value);

      const { id, permission } = value;
      const payload = { ...permission };
      const response = await apiFetcher.patch(
        `/api/v1/store/employee/setting/${id}`,
        payload
      );
      // console.log('response', JSON.stringify(value));
      const { success, data } = response.data;
      if (success) {
        toast.success(`Permissions updated`);
        getEmployees();
      }

      setEnableSave(false);

      formik.setSubmitting(false);
    } catch (err) {
      toast.error(`Failed to update permission`);
      console.log("err", err);
      formik.setSubmitting(false);
    }
  };

  const createUpdateEmployee = async (values) => {
    setEditEmployee(false);
    try {
      let payload = { ...values };
      delete payload.id;
      delete payload.image;
      // delete payload.remove_image

      // console.log('values', JSON.stringify(payload));
      const formdata = new FormData();

      formdata.append("req_body", JSON.stringify(payload));

      if (values?.image != null && typeof values?.image === "object") {
        formdata.append("image", values?.image);
      }

      // console.log('formData', formdata);

      let resp = null;
      if (values.id) {
        let url = `/api/v1/store/employee/${values?.id}`;
        resp = await apiFetcher.patch(url, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        let url = "/api/v1/store/employee/";
        resp = await apiFetcher.post(url, formdata);
      }

      formik.setSubmitting(false);
      getEmployees();
      setEditEmployee(null);
      toast.success(values?.id ? `Employee updated` : "Employee created");
    } catch (err) {
      toast.error(
        values?.id ? `Failed to update employee` : "Failed to create employee"
      );
      console.log("err", err);
      setEditEmployee(null);
      formik.setSubmitting(false);
    }
  };

  const deleteEmployee = async (values) => {
    setShowDeleteModal(false);
    try {
      let url = `/api/v1/store/employee/${values?.id}`;
      const resp = await apiFetcher.delete(url);
      console.log("resp", resp);
      getEmployees();
      toast.success(`Employee deleted`);
      setEditEmployee(null);
    } catch (err) {
      toast.error(`Failed to delete employee`);
      setEditEmployee(null);
      console.log("err", err);
    }
  };

  const handleChangeSelectedEmployee = (event) => {
    const foundEmployee = employees.find(
      (empObj) => empObj.id == event.target.value
    );

    if (foundEmployee) {
      let allPermission =
        Object.entries(foundEmployee.permission).length > 0 ? true : false;
      for (const [key, value] of Object.entries(foundEmployee.permission)) {
        console.log("settingsValue", key, value);
        if (!value) {
          allPermission = false;
        }
      }

      formik.setValues({
        ...foundEmployee,
        selectedEmployee: {
          id: null,
          name: "",
          phone: "",
          accessCode: "",
          journalAccess: false,
          image: "",
          role: "",
        },
        allPermission,
      });

      setInitialValues({
        ...foundEmployee,
        selectedEmployee: {
          id: null,
          name: "",
          phone: "",
          accessCode: "",
          journalAccess: false,
          image: "",
          role: "",
        },
        allPermission,
      });
    }
    // formik.setFieldValue()
    // setSelectedEmployee(event.target.value);
  };

  const handleClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
  };

  const formatPhoneNumber = (number) => {
    if (number) {
      return number.replace(/(\d{2})(?=\d)/g, "$1 ");
    }
  };

  const resetForm = () => {
    setEditEmployee(null);
    setEmployeeId(null);
    setName("");
    setPhone("");
    setAccessCode("");
    setJournalAccess(false);
    setRole("EMPLOYEE");
    setImage(null);
  };

  const handleSave = async () => {
    handleClose();
  };

  useEffect(() => {
    setLoading(true);
    getEmployees();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,

    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      updatePermissionAPI(values);
    },
  });
  const setAllPermission = (value) => {
    formik.setFieldValue("allPermission", value);
    const { permission } = formik.values;

    let newPermission = { ...permission };

    Object.keys(permission).map((key) => {
      newPermission[key] = value;
      // formik.setFieldValue(permission.create_journals, value)
    });

    formik.setFieldValue("permission", newPermission);
  };

  const onChangeValue = (option, checked) => {
    let newPermission = { ...formik.values.permission, [option.id]: checked };

    formik.setFieldValue("permission", newPermission);
    if (!checked) {
      formik.setFieldValue("allPermission", checked);
    }
  };

  useEffect(() => {
    console.log("formik values", formik.values.permission);

    setEnableSave(
      !_.isEqual(initialValues.permission, formik.values.permission)
    );
  }, [formik.values]);

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#6f6f6f",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  if (!user.settings.view_all_employees && user.role !== "ADMIN") {
    return (
       <Notauthorized/>
    );
}

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack sx={{ paddingRight: 4, paddingLeft: 4, marginBottom: 10 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          {enableSave && (
            <CommonButton
              type="submit"
              width="auto"
              height={40}
              title={"Save changes"}
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            />
          )}
        </Box>
        <ToastContainer />
        <Stack
          spacing={6}
          sx={{
            bgcolor: "#fff",
            borderRadius: "25px",
            height: "86vh",
            //    overflowY:'auto',
            scrollbarWidth: "none",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              bgcolor: "#FFFFFF",
              borderRadius: "25px",
              flexDirection: "column",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                minHeight: "200px",
                padding: 5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  pr: 3,
                }}
              >
                <PrimaryHeading text={"Employees"} />
                <SecondaryHeading
                  text={" Here you can add and edit employees."}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "67%" }}
              >
                {loading ? (
                  <Stack width="60%" spacing={1}>
                    {[...Array(7)].map((_, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: 4,
                        }}
                      >
                        <Skeleton variant="rounded" width="100%" height={48} />
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  employees.map((employee, index) => (
                    <Stack
                      key={index}
                      flex={1}
                      flexDirection={"row"}
                      pl={2}
                      pr={2}
                      pt={1}
                      pb={1}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "60%",
                        margin: "5px 0",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "15px",
                        border: "1px solid #D9D9D9",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Stack
                        flex={1}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ px: 0 }}
                      >
                        <Typography
                          style={{
                            width: "100%",
                            size: "20px",
                            color: "#A0A0A0",
                          }}
                        >
                          {employee?.name}
                        </Typography>

                        {(user.settings.edit_customers || user.role === "ADMIN") && (

                        <Stack
                          flex={1}
                          flexDirection={"row"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                        >
                          <IconButton
                            onClick={() => {
                              // formik.setFieldValue('selectedEmployee', employee)
                              setEditEmployee(employee);
                              setShowModal(true);
                            }}
                            sx={{
                              background: "#ffff",
                              border: "none",
                              height: 28,
                              width: 28,
                              borderRadius: "50%",
                              padding: 0,
                            }}
                          >
                            <img
                              src={PencnlIconImg}
                              alt="Edit"
                              height={22}
                              width={22}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setEditEmployee(employee);
                              setShowDeleteModal(true);
                            }}
                            sx={{
                              background: "#ffff",
                              border: "none",
                              marginLeft: 1,
                              borderRadius: "50%",
                              padding: 0,
                            }}
                          >
                            <img src={DeleteIconImg} alt="Delete" width={18} />
                          </IconButton>
                        </Stack>
                        )}
                      </Stack>
                    </Stack>
                  ))
                )}
                {(user.settings.create_employees || user.role === "ADMIN") && (
                <Stack
                  flex={1}
                  onClick={() => {
                    setEditEmployee(null);
                    setShowModal(true);
                  }}
                  flexDirection={"row"}
                  pl={2}
                  pr={2}
                  pt={1}
                  pb={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "60%",
                    margin: "5px 0",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    border: "1px solid #D9D9D9",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    maxHeight: 40,
                  }}
                >
                  <Stack
                    flex={1}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        width: 400,
                        size: "20px",
                        color: "#A0A0A0",
                        mt: 0.2,
                        mb: 0.2,
                      }}
                    >
                      + New employee
                    </Typography>
                  </Stack>
                </Stack>
                )}
              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            {user?.role === "ADMIN" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  minHeight: "200px",
                  padding: 5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                    pr: 3,
                  }}
                >
                  <PrimaryHeading text={"Permissions"} />
                  <SecondaryHeading
                    text={"Here you can choose permissions for your employees."}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "67%",
                  }}
                >
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    {/* <PrimaryHeading text={"Select employee"} /> */}
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700, color: "#1F1F1F" }}
                    >
                      {"Select employee"}
                    </Typography>
                  </Stack>

                  <CustomSelect
                    id={"demo-simple-select"}
                    name={"demo-simple-select"}
                    value={formik.values.id}
                    //  onChange={(event)=>onChangeValue('calendarInterval', event)}
                    onChange={handleChangeSelectedEmployee}
                    options={employees}
                    sx={{ width: "30%", mt: 1 }}
                  />

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: 20,
                    }}
                  >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      {/* <PrimaryHeading text={"All permissions"} /> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, color: "#1F1F1F" }}
                      >
                        {"All permissions"}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FSwitch
                          id={"allPermission"}
                          checked={formik.values.allPermission}
                          onChange={(event, value) => setAllPermission(value)}
                        />
                        <Typography sx={{ fontWeight: 400, color: "#1F1F1F" }}>
                          {"Grant all permissions"}
                        </Typography>
                      </Box>

                      <Stack style={{ marginTop: "10px" }}>
                        {/* <PrimaryHeading text={"Store"} /> */}
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 700, color: "#1F1F1F" }}
                        >
                          {"Store"}
                        </Typography>
                        {options.map((option, index) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <FSwitch
                              id={option.id}
                              checked={formik.values.permission[option.id]}
                              onChange={(event, checked) =>
                                onChangeValue(option, checked)
                              }
                              sx={{
                                "& .MuiSwitch-switchBase": {
                                  "&.Mui-checked": { color: "#fff" },
                                  "&.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#44B904",
                                  },
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor: "#D9D9D9",
                                },
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, color: "#1F1F1F" }}
                            >
                              {option.label}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>

                      <Stack style={{ marginTop: "10px" }}>
                        {/* <PrimaryHeading text={"Opening Hours"} /> */}
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 700, color: "#1F1F1F" }}
                        >
                          {"Opening Hours"}
                        </Typography>
                        {optionsOpeningHours.map((option, index) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <FSwitch
                              id={option.id}
                              checked={formik.values.permission[option.id]}
                              onChange={(event, checked) =>
                                onChangeValue(option, checked)
                              }
                              sx={{
                                "& .MuiSwitch-switchBase": {
                                  "&.Mui-checked": { color: "#fff" },
                                  "&.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#44B904",
                                  },
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor: "#D9D9D9",
                                },
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, color: "#1F1F1F" }}
                            >
                              {option.label}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      {/* <PrimaryHeading text={"Calendar"} /> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, color: "#1F1F1F" }}
                      >
                        {"Calendar"}
                      </Typography>
                      {optionsCalenderOptions.map((option, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <FSwitch
                            id={option.id}
                            checked={formik.values.permission[option.id]}
                            onChange={(event, checked) =>
                              onChangeValue(option, checked)
                            }
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#D9D9D9",
                              },
                            }}
                          />
                          <Typography
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      ))}

                      {/* <PrimaryHeading text={"Customers"} /> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, color: "#1F1F1F" }}
                      >
                        {"Customers"}
                      </Typography>
                      {optionsCustomerOptions.map((option, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <FSwitch
                            id={option.id}
                            checked={formik.values.permission[option.id]}
                            onChange={(event, checked) =>
                              onChangeValue(option, checked)
                            }
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#D9D9D9",
                              },
                            }}
                          />
                          <Typography
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      ))}

                      {/* <PrimaryHeading text={"Journal"} /> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, color: "#1F1F1F" }}
                      >
                        {"Journal"}
                      </Typography>
                      {optionsJournalOptions.map((option, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <FSwitch
                            id={option.id}
                            checked={formik.values.permission[option.id]}
                            onChange={(event, checked) =>
                              onChangeValue(option, checked)
                            }
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#D9D9D9",
                              },
                            }}
                          />
                          <Typography
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      ))}

                      {/* <PrimaryHeading text={"Checkout / POS"} /> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, color: "#1F1F1F" }}
                      >
                        {"Checkout / POS"}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FSwitch
                          checked={null}
                          onChange={null}
                          sx={{
                            "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": { color: "#fff" },
                              "&.Mui-checked + .MuiSwitch-track": {
                                backgroundColor: "#44B904",
                              },
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor: "#D9D9D9",
                            },
                          }}
                        />
                        <Typography sx={{ fontWeight: 400, color: "#1F1F1F" }}>
                          {"????"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {showModal && (
            <EmployeeModel
              open={showModal}
              data={editEmployee}
              handleRemove={() => {
                setShowModal(false);
                setShowDeleteModal(true);
              }}
              setData={(data) => createUpdateEmployee(data)}
              onClose={() => setShowModal(!showModal)}
            />
          )}

          {showDeleteModal && (
            <CustomDeleteModal
              open={showDeleteModal}
              handleClose={handleCloseDeleteModal}
              description={
                <>
                  Are you sure you want to delete
                  <span
                    style={{
                      marginLeft: 5,
                      color: "#1F1F1F",
                      fontWeight: "bold",
                      marginRight: 5,
                    }}
                  >
                    {editEmployee?.name}
                  </span>
                  {/* {itemsToDelete?.type == 'container' ? 'service group': 'service'} */}
                </>
              }
              onClickDismiss={handleCloseDeleteModal}
              onClickConfirm={() => deleteEmployee(editEmployee)}
            />
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default EmployeeSettingsOption;
