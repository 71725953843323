import { Close, Error } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryHeading from "../../../../commonPrimaryHeading";
import CommonButton from "../../../../commonButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../../commonTextinput";
import CloseIcon from '@mui/icons-material/Close';

import { t } from "i18next";
import CustomDatePicker from "../../../../commonDatePicker";
import SecondaryHeading from "../../../../commonSecondaryHeading";

export default function NewAllDayClosureModel({
  open,
  holidays,
  setHolidays,
  onClose,
  newallDayClosureProps,
}) {

  const validationSchema = Yup.object({
    start_date: Yup.date()
      .required("Please select a start date")
      .typeError("Please select a valid start date"),

    end_date: Yup.date()
      .required("Please select an end date")
      .typeError("Please select a valid end date"),

    description: Yup.string()
      .required("Please enter a description")
      .min(1, "Description cannot be empty"),
  });

  const formik = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {
      finalSubmit(values);
    },
  });

  useEffect(() => {
    if (newallDayClosureProps?.id === "add") {
      console.log("Adding new closure");
    } else {
     let objOFHoliday = null
      if(newallDayClosureProps?.id){

         objOFHoliday = holidays.find((item) => item.id === newallDayClosureProps?.id);
      }else{
         objOFHoliday = holidays[newallDayClosureProps?.index]
      }

      console.log('objOfHoliday', objOFHoliday);
      
      if (objOFHoliday) {
        formik.setValues({
          start_date: moment(objOFHoliday.start_date),
          end_date: moment(objOFHoliday.end_date),
          description: objOFHoliday.description,
        });
      }
    }
  }, [newallDayClosureProps, holidays]);

  const finalSubmit = (values) => {

    // console.log('moment().valueOf()',moment().valueOf());
    
    let formatedData = {
      year: parseInt(values.start_date.format("YYYY")),
      start_date: values.start_date.format("YYYY-MM-DD"),
      end_date: values.end_date.format("YYYY-MM-DD"),
      description: values.description,
      isHoliday: true,
      type: 'custom',
      id: `cu-${moment().valueOf()}`
    };

    let filterdHolidays = holidays.filter((item) => item.id !== newallDayClosureProps?.id);
    let updatedHolidays = [...filterdHolidays, formatedData];
    setHolidays(updatedHolidays);
    onClose();
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#6f6f6f',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


  return (
    // <Modal
    //   open={open}
    //   onClose={onClose}
    //   aria-labelledby="new-all-day-closure-modal-title"
    //   aria-describedby="new-all-day-closure-modal-description"
    //   sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
    // >
    //   <Box sx={{ position: "relative", display: "flex", flexDirection: "column", gap: 4, width: "50%", height: "auto", maxHeight: "80vh", bgcolor: "#fff", borderRadius: "25px", padding: 10 }}>
    //     <IconButton onClick={onClose} sx={{ position: "absolute", top: 4, right: 4 }}>
    //       <Close sx={{ color: "#a2a2a2" }} />
    //     </IconButton>
    //     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //       <PrimaryHeading text={"New all-day closure"} />
    //     </Box>

    //     <Typography sx={{ fontSize: 14, fontWeight: 400, color: "#A0A0A0" }} >
    //       <Error sx={{ color: "#F7C098" }} />
    //       When scheduling an all-day closure, it applies to all clinic employees.
    //     </Typography>
    //     <Formik
    //       initialValues={formik.initialValues}
    //       validationSchema={validationSchema}
    //       onSubmit={formik.handleSubmit}
    //     >
    //       <Form>
    //         <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
    //           {/* Start date */}
    //           <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} >
    //             <SecondaryHeading text={"Start date"} />
    //             <LocalizationProvider dateAdapter={AdapterMoment}>
    //               <DatePicker
    //                 sx={{
    //                   "&.Mui-focused": { outline: "none", boxShadow: "none" },
    //                   "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    //                   "& .MuiPaper-root": { backgroundColor: "#FFFFFF" },
    //                   border: "1px solid #d9d9d9",
    //                   borderRadius: 3,
    //                 }}
    //                 value={formik.values.start_date}
    //                 format="DD/MM-YYYY"
    //                 onChange={(newValue) => formik.setFieldValue("start_date", newValue)}
    //                 renderInput={(params) => <TextField {...params} />}
    //               />
    //             </LocalizationProvider>
    //             {formik.touched.start_date && formik.errors.start_date && (
    //               <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    //                 <Error sx={{ color: "red" }} />
    //                 <Typography color="red">{formik.errors.start_date}</Typography>
    //               </Box>
    //             )}
    //           </Stack>

    //           {/* End date */}
    //           <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"}>
    //             <SecondaryHeading text={"End date"} />
    //             <LocalizationProvider dateAdapter={AdapterMoment}>
    //               <DatePicker
    //                 sx={{
    //                   "&.Mui-focused": { outline: "none", boxShadow: "none" },
    //                   "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    //                   "& .MuiPaper-root": { backgroundColor: "#FFFFFF" },
    //                   border: "1px solid #d9d9d9",
    //                   borderRadius: 3,
    //                 }}
    //                 value={formik.values.end_date}
    //                 format="DD/MM-YYYY"
    //                 onChange={(newValue) => formik.setFieldValue("end_date", newValue)}
    //                 renderInput={(params) => <TextField {...params} />}
    //               />
    //             </LocalizationProvider>
    //             {formik.touched.end_date && formik.errors.end_date && (
    //               <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    //                 <Error sx={{ color: "red" }} />
    //                 <Typography color="red">{formik.errors.end_date}</Typography>
    //               </Box>
    //             )}
    //           </Stack>

    //           {/* Description */}
    //           <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"}>
    //             <SecondaryHeading text={"Description"} />
    //             <CustomTextField
    //               id="outlined-multiline-static"
    //               value={formik.values.description}
    //               onChange={(e) => formik.setFieldValue("description", e.target.value)}
    //             />
    //             {formik.touched.description && formik.errors.description && (
    //               <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    //                 <Error sx={{ color: "red" }} />
    //                 <Typography color="red">{formik.errors.description}</Typography>
    //               </Box>
    //             )}
    //           </Stack>

    //           {/* Buttons */}
    //           <Box sx={{ display: "flex", justifyContent: "center", gap: 4 }}>
    //             <CommonButton
    //               variant="outlined"
    //               title={"Cancel"}
    //               backgroundColor={"#D9D9D9"}
    //               onClick={onClose}
    //             />
    //             <CommonButton variant="contained" title={"Save"} onClick={formik.handleSubmit} />
    //           </Box>
    //         </Box>
    //       </Form>
    //     </Formik>
    //   </Box>
    // </Modal>

      <Dialog

          PaperProps={{ sx: { borderRadius: "25px" } }}

          keepMounted
          fullWidth
          // aria-describedby="alert-dialog-slide-description"
          // sx={{
          //     display: !open ? 'none' : '',
          //     '& .MuiDialog-paper': {
          //         width: '900px'
          //     },
          // }}
          // className=".mui-fixed"

          maxWidth="sm"
          open={open}
          onClose={() => console.log('here')
          }
          // aria-labelledby="responsive-dialog-title"
      >

          <BootstrapDialogTitle id="customized-dialog-title"  sx={{  color: '#1F1F1F', fontWeight: 700,pl:6,pt:2 , mb:0, textAlign: 'center',mt:2  }}>
              {'New all-day closure'}
          </BootstrapDialogTitle>

          <DialogContent sx={{pl:6, pr:6, pt:0, pb:0, mt:0, mb:0}}>
              <Box
                  noValidate
                  component="form"
                  sx={{
                      p: 0,
                      mt:0,
                      // pl: 2,
                      // pr: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      m: 'auto',
                      width: '100%',
                  }}
              >
                    
                    <Typography variant="body1"  sx={{  fontWeight: 400, color: "#A0A0A0", mb:4 }} >
          <Error sx={{ color: "#F7C098", fontSize:18, mr:1, mb:0.2 }} />
          When scheduling an all-day closure, it applies to all clinic employees.
        </Typography>
        <Formik
          initialValues={formik.initialValues}
          validationSchema={validationSchema}
          onSubmit={formik.handleSubmit}
        >
          <Form>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {/* Start date */}
              <Stack display={"flex"} flex={1} flexDirection={"row"} alignItems={"center"} >
              <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight:700, flex:0.2 }}> Start Date</Typography>
              <Stack sx={{flex:0.8}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{
                      "&.Mui-focused": { outline: "none", boxShadow: "none" },
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      "& .MuiPaper-root": { backgroundColor: "#FFFFFF" },
                      "& .MuiOutlinedInput-input": {padding:1},
                      border: "1px solid #d9d9d9",
                      borderRadius: 3,
                      width:150
                    }}
                    value={formik.values.start_date}
                    format="DD/MM-YYYY"
                    onChange={(newValue) => formik.setFieldValue("start_date", newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.start_date && formik.errors.start_date && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* <Error sx={{ color: "red" }} /> */}
                    <Typography variant="caption" color="red">{formik.errors.start_date}</Typography>
                  </Box>
                )}
                 </Stack>
              </Stack>

              {/* End date */}
              <Stack display={"flex"} flex={1} flexDirection={"row"} alignItems={"center"}>
                {/* <SecondaryHeading text={"End date"} /> */}
                <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight:700, flex:0.2 }}> End Date</Typography>

              <Stack flex={0.8}>
                
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{
                      "&.Mui-focused": { outline: "none", boxShadow: "none" },
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      "& .MuiPaper-root": { backgroundColor: "#FFFFFF" },
                      "& .MuiOutlinedInput-input": {padding:1},
                      border: "1px solid #d9d9d9",
                      borderRadius: 3,
                      width:150
                    }}
                    value={formik.values.end_date}
                    format="DD/MM-YYYY"
                    onChange={(newValue) => formik.setFieldValue("end_date", newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.end_date && formik.errors.end_date && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* <Error sx={{ color: "red" }} /> */}
                    <Typography variant="caption" color="red">{formik.errors.end_date}</Typography>
                  </Box>
                )}
                </Stack>
              </Stack>

              {/* Description */}
              <Stack flex={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                {/* <SecondaryHeading text={"Description"} /> */}
                  <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight:700, flex:0.2 }}> Description</Typography>
                  <Stack sx={{flex:0.8}}>
                <CustomTextField
                  id="outlined-multiline-static"
                  value={formik.values.description}
                  mt={0}
                  onChange={(e) => formik.setFieldValue("description", e.target.value)}
                  placeholder={'Why is the clinic closed on this day? e.g. holiday'}
                />
                {formik.touched.description && formik.errors.description && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* <Error sx={{ color: "red" }} /> */}
                    <Typography variant="caption" color="red"  >{formik.errors.description}</Typography>
                    
                  </Box>
                )}
                   </Stack>
              </Stack>

              {/* Buttons */}
              {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 4 }}>
                <CommonButton
                  variant="outlined"
                  title={"Cancel"}
                  backgroundColor={"#D9D9D9"}
                  onClick={onClose}
                />
                <CommonButton variant="contained" title={"Save"} onClick={formik.handleSubmit} />
              </Box> */}
            </Box>
          </Form>
        </Formik>
              </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2, gap:2, mt:4 }} >
              {
                
                      <>

                          <CommonButton
                              type="submit"
                              // width={120}
                              height={40}
                              title={'Cancel'}
                              backgroundColor={'#D9D9D9'}
                              style={{
                                  minWidth:150 
                              }}
                              onClick={onClose}
                              // loading={formik.isSubmitting}
                              // disabled={formik.isSubmitting}
                          />
                          <CommonButton
                              type="submit"
                          
                              height={40}
                              title={'Add all-day closure'}
                              // loading={formik.isSubmitting}
                              // disabled={formik.isSubmitting}
                              backgroundColor={'#44B904'}
                              style={{
                                  minWidth:150 
                              }}

                              onClick={formik.handleSubmit}

                          />
                      </>

              }


          </DialogActions>
      </Dialog>
  );
}
