import { Close, Height } from '@mui/icons-material';
import { Box, Card, Grid2, IconButton, Modal, Paper, Stack, Switch, Typography } from '@mui/material';
import CommonButton from '../../../../commonButton';
import React, { useEffect, useState } from 'react';
import PrimaryHeading from '../../../../commonPrimaryHeading';
import CustomSelect from '../../../../commonCustomSelect';
import CustomDatePicker from '../../../../commonDatePicker';
import CustomTimePicker from '../../../../commonTimePicker';
import moment from 'moment';
import { useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { t } from 'i18next'
import apiFetcher from '../../../../../../utils/interCeptor';
import FSwitch from '../../../../../commonComponents/f-switch';

export default function FormModel({ open, onClose, formProps, setData, handleRemove }) {


    const [empID, setEmpID] = useState(formProps?.employee);
    // const [empObj, setEmpObj] = useState({});
    // const [mainObject, setMainObject] = useState({});

    const [employee, setEmployee]= useState([])

    console.log('formProps1', formProps);
    
    moment.locale("en");

    const transformedData = []


    const getEmployees = async () => {

        try {

            const response = await apiFetcher.get('/api/v1/store/employee/get')

            const { success, data } = response.data
            if (success) {
                console.log('data', data);
                  const updatedEmpList = data.map(item => ({
                    ...item,
                    value: item.id,
                    label: item.name
                }));

                setEmployee(updatedEmpList)
              
            }

        } catch (err) {
            console.log('err', err);

        }
    }
    

    useEffect(()=>{
        if(open){

            getEmployees()

            
            const {event, employee, detailId} = formProps
            // setEmpID(employee?.id)
            if(formProps.event){


                console.log('formprops event', event);
                
          
            formik.setValues({
                id: event?.id ?? null,
                detailId: detailId,
                employee_id: event?.employee_id,
                start_date: event?.start_date ? moment(event?.start_date) : null,
                start_time: event?.start_time ? moment(event?.start_time, "HH:mm:ss") : null,
                end_date: event?.end_date ? moment(event?.end_date) : null,
                end_time: event?.end_time ? moment(event?.end_time, "HH:mm:ss") : null,
                repeat: event?.repeat === null ? "DO_NOT_REPEAT" : event?.repeat,
                add_break: event?.add_break || false,
                start_break_time: event?.start_break_time ? moment(event?.start_break_time, "HH:mm:ss") : null,
                end_break_time: event?.end_break_time ? moment(event?.end_break_time, "HH:mm:ss") : null,
                quit: event?.end_date === null ? "NEVER_QUIT" : "TIME"
            });
        } else {
            formik.setValues({
                id: null,
                detailId: detailId,
                employee_id: employee?.id,
                start_date: formProps?.weekday,
                start_time: null,
                end_date: null,
                end_time: null,
                repeat: "DO_NOT_REPEAT",
                add_break: false,
                start_break_time: null,
                end_break_time: null,
                quit: "NEVER_QUIT"
            });
        }
        }
        

    }, [open])

    const validationSchema = Yup.object({
        // start_date , start_time, end_date , end_time , repeat , add_break , start_break_time , end_break_time ,
        employee_id: Yup.string()
            .required("Please select an employee")
            .typeError("Please select a valid employee"),

        start_date: Yup.date()
            .required("Please select a start date")
            .typeError("Please select a valid start date"),

        start_time: Yup.string()
            .required("Please select a start time")
            .typeError("Please select a valid start time"),

        end_time: Yup.string()
            .typeError("Please select a valid end time"),

        // end_date: Yup.date()
        // .required("Please select a end date")
        //     .typeError("Please select a valid end date"),

        repeat: Yup.string()
            .nullable()
            .typeError("Please select a valid repeat"),

        add_break: Yup.boolean()
            .typeError("Please select a valid add break"),

        start_break_time: Yup.string()
            .when("add_break", {
                is: true,
                then: Yup.string().required("Please select a start of break").nullable(),
            })
            .typeError("Please select a valid start of break")
            .nullable(),

        end_break_time: Yup.string()
            .when("add_break", {
                is: true,
                then: Yup.string().required("Please select an end of break").nullable(),
            })
            .typeError("Please select a valid end of break")
            .nullable(),

        quit: Yup.string()
            .when("repeat", {
                is: (repeat) => repeat !== "DO_NOT_REPEAT",
                then: Yup.string().required("Please select a quit option"),
                otherwise: Yup.string(),
            })
            .typeError("Please select a valid quit option"),

        end_date: Yup.string()
            .when("quit", {
                is: true,
                then: Yup.string().required("Please select end date").nullable(),
            })
            .typeError("Please select a valid end date")
            .nullable(),

    })


    // useEffect(() => {
    //     console.log('empId', empID);
        
    //     let MainObject = formProps?.detailId !== false && formProps?.data?.employees_opening_hour?.find((item) => item.id === empID);
    //     console.log('MainObject', MainObject);

    //     let empObj = (formProps?.detailId !== false && formProps?.employee === empID) && { ...MainObject, detail: MainObject?.detail?.find((item) => item.id === formProps?.detailId) }

    //     console.log('empObj', empObj, mainObject);

    //     setEmpObj(formProps.employee === empID ? empObj : MainObject);
    //     setMainObject(MainObject);
    //     console.log('empObj', empObj);
    // }, [empID])




    const formik = useFormik({
        initialValues:{
            id: null,
            detailId: null,
            employee_id: null,
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            repeat: "DO_NOT_REPEAT",
            add_break: false,
            start_break_time: null,
            end_break_time: null,
            quit: "NEVER_QUIT"
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    // useEffect(() => {
    //     if (formProps?.detailId !== false) {
    //         formik.setValues({
    //             employee_id: empID,
    //             start_date: event?.start_date ? moment(empObj?.detail?.start_date) : null,
    //             start_time: empObj?.detail?.start_time ? moment(empObj?.detail?.start_time, "HH:mm:ss") : null,
    //             end_date: empObj?.detail?.end_date ? moment(empObj?.detail?.end_date) : null,
    //             end_time: empObj?.detail?.end_time ? moment(empObj?.detail?.end_time, "HH:mm:ss") : null,
    //             repeat: empObj?.detail?.repeat === null ? "DO_NOT_REPEAT" : empObj?.detail?.repeat,
    //             add_break: empObj?.detail?.add_break || false,
    //             start_break_time: empObj?.detail?.start_break_time ? moment(empObj?.detail?.start_break_time, "HH:mm:ss") : null,
    //             end_break_time: empObj?.detail?.end_break_time ? moment(empObj?.detail?.end_break_time, "HH:mm:ss") : null,
    //             quit: empObj?.detail?.end_date === null ? "NEVER_QUIT" : "TIME"
    //         });
    //     } else {
    //         formik.setValues({
    //             employee_id: empID,
    //             start_date: formProps?.weekday,
    //             start_time: null,
    //             end_date: null,
    //             end_time: null,
    //             repeat: "DO_NOT_REPEAT",
    //             add_break: false,
    //             start_break_time: null,
    //             end_break_time: null,
    //             quit: "NEVER_QUIT"
    //         });
    //     }
    // }, [empObj, formProps?.detailId]);




    const handleSubmit = (values) => {

        console.log('values', values);

        const {employee, event, detailId } = formProps

        const finalValues =  {
                    
                        ...values,
                        id: values?.id ?? `ev-${moment().valueOf()}`,
                        employee_id: values?.employee_id,
                        repeat: values?.repeat === "DO_NOT_REPEAT" ? null : values?.repeat,
                        start_date: values?.start_date ? moment(values?.start_date).format("YYYY-MM-DD") : null,
                        end_date: values?.end_date && (values?.repeat !== "DO_NOT_REPEAT" || values?.repeat !== null) ? moment(values?.end_date).format("YYYY-MM-DD") : null,
                        start_time: values?.start_time ? moment(values?.start_time).format("HH:mm:ss") : null,
                        start_break_time: values?.add_break && values?.start_break_time ? moment(values?.start_break_time).format("HH:mm:ss") : null,
                        end_break_time: values?.add_break && values?.end_break_time ? moment(values?.end_break_time).format("HH:mm:ss") : null,
                        end_time: values?.end_time ? moment(values?.end_time).format("HH:mm:ss") : null,
                        add_break: values?.add_break
                    }

        // let newEmployee;
        // if (formProps.detailId !== false && formProps?.employee === empID) {
        //     console.log('empID in if', empID);

        //     newEmployee = mainObject?.detail.filter((item) => item.id !== formProps.detailId).concat([
        //         {
        //             ...formProps?.employee?.detail?.find((item) => item.id === formProps.detailId),
        //             employee_id: values?.employee_id,
        //             repeat: values?.repeat === "DO_NOT_REPEAT" ? null : values?.repeat,
        //             start_date: values?.start_date ? moment(values?.start_date).format("YYYY-MM-DD") : null,
        //             end_date: values?.end_date && (values?.repeat !== "DO_NOT_REPEAT" || values?.repeat !== null) ? moment(values?.end_date).format("YYYY-MM-DD") : null,
        //             start_time: values?.start_time ? moment(values?.start_time).format("HH:mm:ss") : null,
        //             start_break_time: values?.add_break && values?.start_break_time ? moment(values?.start_break_time).format("HH:mm:ss") : null,
        //             end_break_time: values?.add_break && values?.end_break_time ? moment(values?.end_break_time).format("HH:mm:ss") : null,
        //             end_time: values?.end_time ? moment(values?.end_time).format("HH:mm:ss") : null,
        //             add_break: values?.add_break
        //         }
        //     ]);
        // } else {

        //     newEmployee = [
        //         ...empObj?.detail,
        //         {
        //             ...formProps?.detail,
        //             employee_id: empID,
        //             repeat: values?.repeat === "DO_NOT_REPEAT" ? null : values?.repeat,
        //             start_date: values?.start_date ? moment(values?.start_date).format("YYYY-MM-DD") : null,
        //             end_date: values?.end_date && (values?.repeat !== "DO_NOT_REPEAT" || values?.repeat !== null) ? moment(values?.end_date).format("YYYY-MM-DD") : null,
        //             start_time: values?.start_time ? moment(values?.start_time).format("HH:mm:ss") : null,
        //             start_break_time: values?.add_break && values?.start_break_time ? moment(values?.start_break_time).format("HH:mm:ss") : null,
        //             end_break_time: values?.add_break && values?.end_break_time ? moment(values?.end_break_time).format("HH:mm:ss") : null,
        //             end_time: values?.end_time ? moment(values?.end_time).format("HH:mm:ss") : null,
        //             add_break: values?.add_break
        //         }
        //     ]
        // }

        // newEmployee = {
        //     ...mainObject,
        //     id: empID,
        //     detail: newEmployee
        // };

        // console.log('newEmployee', newEmployee);


        // setData(prev => ({
        //     ...prev,
        //     employees_opening_hour: prev?.employees_opening_hour?.map((item) => {
        //         if (item.id === empID) {
        //             return newEmployee;
        //         } else {
        //             return item;
        //         }
        //     })
        // }));

        setData({
            employee, event, detailId, finalValues
        })

        onClose();
    };





    const FormField = ({ label, component, error }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', }} >
            <Stack flex={1} flexDirection={'row'}  alignItems={"center"} justifyContent={'flex-end'} width={"40%"} sx={{ paddingRight:2}}>
                <Typography sx={{ fontSize: 18, fontWeight: 700, color: '#1F1F1F', textAlign:'right'  }}>{label}</Typography> 
            </Stack>
            <Stack  width={"60%"}  flexDirection={'row'}  sx={{alignItems:'center'}}>
                <Stack  flex={1} flexDirection={'column'} justifyContent={'center'}>


                {component}
                {error &&
                
                <Typography  variant='caption' color='red'>{error}</Typography>}
                </Stack>
            </Stack>
        </Box >
    );


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Paper
                sx={{
                    position: 'relative',
                    width: 'auto',
                    maxWidth: '60%',
                    padding: 4,
                    pl:1,
                    pr:1,
                    bgcolor: '#fff',
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <Close sx={{ color: '#a2a2a2' }} />
                </IconButton>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PrimaryHeading text={t('Settings.AddAnIndividualOpeningHour')} />
                </Box>

                <Formik
                    initialValues={formProps.detailId !== false ? formik.values : formik.initialValues}
                    validationSchema={validationSchema}
                    onSubmit={formik.handleSubmit}
                >
                    <Form>
                        <Grid2 container spacing={2} columnSpacing={4} marginTop={5} paddingBottom={2}>
                            {/* Employee */}
                            <Grid2 size={{ xs: 6 }} sx={{p:0, justifyContent:'flex-start'}}>
                                <FormField
                                    label={<Typography variant='body1' sx={{fontWeight:700, color:'#1f1f1f'}}>Employee</Typography>}
                                    
                                    component={
                                      
                                            <CustomSelect
                                                mt={0}
                                                value={formik.values.employee_id}
                                                name="name"
                                                onChange={(e) => {
                                                    formik.setFieldValue('employee_id', e.target.value)
                                                    // setEmpID(e.target.value);
                                                }}
                                                sx={{width:'100%', Height:40}}
                                                options={employee}
                                            />
                                    }
                                />
                            </Grid2>

                            {/* Add break */}
                            <Grid2 size={{ xs: 6 }}>
                                <FormField
                                     label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Add break</Typography>}
                                    component={
                                        <FSwitch
                                            sx={{
                                                '& .MuiSwitch-switchBase': {
                                                    '&.Mui-checked': { color: '#fff' },
                                                    '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                                                },
                                                '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                                            }}
                                            checked={formik.values.add_break}
                                            onChange={(e) => formik.setFieldValue('add_break', e.target.checked)}
                                        />
                                    }
                                />
                            </Grid2>

                            {/* Start date */}
                            <Grid2 size={{ xs: 6 }}>
                                <FormField
                                    label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Start date</Typography>}
                                    component={
                                        // <span style={{ width: '50%', overflow: 'hidden', border: '2px solid #d2d2d2', borderRadius: 8, padding: '2px' }} >
                                            <CustomDatePicker
                                                borderThickness="0px"
                                                value={formik.values.start_date}
                                                format="DD/MM-YYYY"
                                                onChange={(e) => formik.setFieldValue('start_date', e)}
                                            />
                                        // </span>
                                    }
                                    error={formik.touched.start_date && formik.errors.start_date}
                                />
                            </Grid2>

                            {/* Start break time */}
                            <Grid2 size={{ xs: 6 }}>
                                {formik.values.add_break && (
                                    <FormField
                                        // label="Start Break Time"
                                        label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Start of break</Typography>}
                                        component={
                                                <CustomTimePicker
                                                    borderThickness='0px'
                                                    value={formik.values.start_break_time}
                                                    format="HH:mm"
                                                    onChange={(e) => formik.setFieldValue('start_break_time', e)}
                                                    sx={{width:'65%'}}

                                                />
                                        }
                                        error={formik.touched.start_break_time && formik.errors.start_break_time}
                                    />
                                )}
                            </Grid2>

                            {/* Start time */}
                            <Grid2 size={{ xs: 6 }}>
                                <FormField
                                    // label="Start Time"
                                    label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Start of day</Typography>}
                                    component={
                                        // <span style={{ width: '35%', overflow: 'hidden', border: '2px solid #d2d2d2', borderRadius: 8, padding: '2px' }} >
                                            <CustomTimePicker
                                                borderThickness='0px'
                                                value={formik.values.start_time}
                                                format="HH:mm"
                                                onChange={(e) => formik.setFieldValue('start_time', e)}
                                                sx={{width:'65%'}}
                                            />
                                        // </span>
                                    }
                                    error={formik.touched.start_time && formik.errors.start_time}
                                />
                            </Grid2>

                            {/* End break time */}
                            <Grid2 size={{ xs: 6 }}>
                                {formik.values.add_break && (
                                    <FormField
                                        // label="End Break Time"
                                        label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>End of break</Typography>}
                                        component={
                                            // <span style={{ width: '35%', overflow: 'hidden', border: '2px solid #d2d2d2', borderRadius: 8, padding: '2px' }} >
                                                <CustomTimePicker
                                                    borderThickness='0px'
                                                    value={formik.values.end_break_time}
                                                    format="HH:mm"
                                                    onChange={(e) => formik.setFieldValue('end_break_time', e)}
                                                    sx={{width:'65%'}}

                                                />
                                            // </span>
                                        }
                                        error={formik.touched.end_break_time && formik.errors.end_break_time}
                                    />
                                )}
                            </Grid2>

                            {/* End time */}
                            <Grid2 size={{ xs: 6 }}>
                                <FormField
                                    // label="End Time"
                                    label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>End of day</Typography>}
                                    component={
                                        // <span style={{ width: '35%', overflow: 'hidden', border: '2px solid #d2d2d2', borderRadius: 8, padding: '2px' }} >
                                            <CustomTimePicker
                                                borderThickness='0px'
                                                value={formik.values.end_time}
                                                format="HH:mm"
                                                onChange={(e) => formik.setFieldValue('end_time', e)}
                                                sx={{width:'65%'}}
                                            />
                                        // </span>
                                    }
                                    error={formik.touched.end_time && formik.errors.end_time}
                                />
                            </Grid2>

                            {/* End date */}
                            <Grid2 size={{ xs: 6 }}>
                               

                            {formik.values.repeat !== 'DO_NOT_REPEAT' && (
                                    <FormField
                                        // label="Quit"
                                        label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Quit</Typography>}
                                        component={
                                           
                                                <CustomSelect
                                                    mt={0}
                                                    value={formik.values.quit}
                                                    name="quit"
                                                    onChange={(e) => formik.setFieldValue('quit', e.target.value)}
                                                    options={[
                                                        { value: 'NEVER_QUIT', label: 'Never quit' },
                                                        { value: 'TIME', label: 'Stop on a specific time' },
                                                    ]}
                                                    sx={{width:'85%'}}
                                                />
                                           
                                        }
                                       
                                    />
                                )}
                            </Grid2>

                            {/* Repeat */}
                            <Grid2 size={{ xs: 6 }}>
                                <FormField
                                
                                    label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Repeat</Typography>}
                                    component={
                                        // <span>
                                            <CustomSelect
                                                mt={0}
                                                value={formik.values.repeat}
                                                name="repeat"
                                                onChange={(e) => formik.setFieldValue('repeat', e.target.value)}
                                                options={[
                                                    { value: 'DO_NOT_REPEAT', label: 'Do not repeat' },
                                                    { value: 'WEEK', label: 'Weekly' },
                                                    { value: 'EVEN', label: 'Even weeks' },
                                                    { value: 'ODD', label: 'Odd weeks' },
                                                    { value: 'MONTH', label: 'Monthly' },
                                                ]}
                                                sx={{width:'100%', Height:40}}
                                            />
                                        // </span>
                                    }
                                    error={formik.touched.repeat && formik.errors.repeat}
                                />
                            </Grid2>

                            {/* Quit */}
                            <Grid2 size={{ xs: 6 }}>
                                {/* {formik.values.repeat !== 'DO_NOT_REPEAT' && (
                                    <FormField
                                        // label="Quit"
                                        label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>Quit</Typography>}
                                        component={
                                            <span>
                                                <CustomSelect
                                                    mt={0}
                                                    value={formik.values.quit}
                                                    name="quit"
                                                    onChange={(e) => formik.setFieldValue('quit', e.target.value)}
                                                    options={[
                                                        { value: 'NEVER_QUIT', label: 'Never quit' },
                                                        { value: 'TIME', label: 'Stop on a specific time' },
                                                    ]}
                                                />
                                            </span>
                                        }
                                    />
                                )} */}
                                 {formik.values.quit === 'TIME' && (
                                    <FormField
                                        // label="End Date"
                                        label={<Typography variant='body1' sx={{fontWeight:700,  color:'#1f1f1f'}}>End date</Typography>}
                                        component={
                                            // <span style={{ width: '50%', overflow: 'hidden', border: '2px solid #d2d2d2', borderRadius: 8, padding: '2px' }} >
                                                <CustomDatePicker
                                                    borderThickness="0px"
                                                    value={formik.values.end_date}
                                                    format="DD/MM-YYYY"
                                                    sx={{width:'82%'}}
                                                    onChange={(e) => formik.setFieldValue('end_date', e)}
                                                />
                                            // </span>
                                        }
                                        error={formik.touched.end_date && formik.errors.end_date}
                                    />
                                )}
                            </Grid2>
                        </Grid2>

                        <Stack
                            sx={{
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                                display: 'flex',
                                gap: 2,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 4,
                            }}
                        >
                            {
                            formik.values.id &&
                                <CommonButton
                                title="Remove opening hour"
                                onClick={()=>handleRemove(formProps?.event)}
                                backgroundColor="#D30000"
                                height={40}
                                />
                            }
                            <CommonButton
                                title="Add opening hour"
                                onClick={formik.handleSubmit}
                                backgroundColor="#44B904"
                                height={40}
                            />
                        </Stack>
                    </Form>
                </Formik>
                
           
           
            </Paper>
        </Modal>
    );

}
