import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Stack,
  Divider,
  Tooltip,
 
  TextareaAutosize,
} from "@mui/material";
import CommonButton from "../commonButton";
import TooltipIcon from "../../../assets/IconTooltip.png";
import DraggableListComponent from "../draggableList";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import CustomSelect from "../commonCustomSelect";
import CustomTextField from "../commonTextinput";
import {  useFormik } from "formik";
import apiFetcher from "../../../utils/interCeptor";

import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import { MultipleContainers } from "../../MultipleContainers/MultipleContainers";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import FSwitch from "../../commonComponents/f-switch";

const onlinBookingsIntervals = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min (standard)" },
  { value: 45, label: "45 min" },
  { value: 60, label: "1 hour" },
];

const howLongBeforeCustomerOptions = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min (standard)" },
  { value: 45, label: "45 min" },
  { value: 60, label: "1 hour" },
];

const howFarFutureCustomerOptions = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min" },
  { value: 45, label: "45 min" },
  { value: 60, label: "1 hour" },
  { value: 120, label: "2 hour (standard)" },
];

const longBeforeCustomerCancelBookingOptions = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min" },
  { value: 45, label: "45 min" },
  { value: 60, label: "1 hour" },
  { value: 120, label: "2 hour (standard)" },
];

const OnlineBookingSettingsOption = () => {
  const [Employee, setEmployee] = useState([]);
  const [reOrderEmp, setReorderEmp] = useState({})
  const dataNeedsFromClintsFirst = [
    { id: 1, label: "Name", field: "name" },
    { id: 2, label: "Phone number", field: "phoneNumber" },
    { id: 3, label: "Email", field: "email" },
    { id: 4, label: "Address", field: "address" },
  ];

  const dataNeedsFromClintsSec = [
    { id: 11, label: "Note box", field: "noteBox" },
    { id: 12, label: "Birthday", field: "birthday" },
    { id: 13, label: "CPR", field: "cpr" },
  ];

  const [isChanges, setIsChanges] = useState(false);
  const [initialValues, setInitialValues] = useState({
    onlineBooking: {
      allowOnlineBooking: false,
      specificEmployee: [],
    },

    BookingInterval: {
      miniGapCalEmployee: [],
      sameIntervalForAllEmployee: false,
      intervalForOnlineBooking: 15,
      intervalForIndividualEmployee:null
    },

    restrictionForBooking: {
      howLongBeforeCustomerBook: 15,
      howFarFutureCustomerbook: 120,
      longBeforeCustomerCancelBookingOptionBook: 120,
      cancellationPolicy: "",
      messageForTooLate: "",
    },

    DataFromClients: {
      name: false,
      phoneNumber: false,
      email: false,
      address: false,
      noteBox: false,
      birthday: false,
      cpr: false,
    },
    employeSectionOrder: [],
    iframeCode: "",
  });

  async function getOnlineBookingSettings(values) {
    try {
      const payload = {
        settings: [
          {
            settingCategory: "outlet",
            settingName: "OnlineBooking",
            value: JSON.stringify(values),
            type: "JSON",
          },
        ],
      };

      console.log('payload', payload);
      
      const response = await apiFetcher.patch(
        "/api/v1/store/outlet/setting",
        payload
      );
      console.log(response);
      console.log("response", JSON.stringify(values));
      const { success } = response.data;
      if (success) {
        setIsChanges(false);
        toast.success(`Online booking settings updated`);
      }

      formik.setSubmitting(false);
    } catch (err) {
      toast.error(`Failed to update Online booking settings`);
      console.log("err", err);
      formik.setSubmitting(false);
    }
  }

  async function updateEmpSequence(payload) {
    try {

    //  const payload =  Employee.map((empObj)=>{return{id: empObj.id, sequence: empObj.sequence}})
    
      console.log('payload', payload);
      
      const response = await apiFetcher.post(
        "/api/v1/store/employee/sequence",
        payload
      );
      console.log(response);
      const { success } = response.data;
      if (success) {
        setIsChanges(false);
        toast.success(`Employee selection order updated`);
      }

    } catch (err) {
      toast.error(`Failed to update employee selection order`);
    
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log('values', values);
      
      getOnlineBookingSettings(values);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apiFetcher.get(
          "https://api.fiind.app/api/v1/store/employee/get"
        );
        const data = res.data?.data;
        console.log(data);

        let empObj = {}

        data.map((dataObj)=>{
          delete dataObj.settings
          empObj[dataObj?.id] = {
            ...dataObj,
            title: dataObj.name,
            groupId: dataObj?.id,
            services:[],
            noSubGroup: true

          }
        })

        console.log('empObj', empObj);
        
        setReorderEmp(empObj)

        setEmployee(data);
        fetchSettings(data)
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setIsChanges(!_.isEqual(initialValues, formik.values));
  }, [formik.values]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await apiFetcher.get(
  //         "https://api.fiind.app/api/v1/store/outlet/setting"
  //       );

  //       const data = res.data.data.settings.filter(
  //         (setting) =>
  //           setting.settingCategory === "outlet" &&
  //           setting.settingName === "OnlineBooking"
  //       )[0].value;

  //       const parsedData = JSON.parse(data);
  //       console.log(parsedData);
  //       // let newParsedData = {...parsedData}
       
  //       // let individualData = {}

  //       // Employee.map((empObj)=>{
  //       //   individualData[empObj?.id] = {
  //       //     id: empObj?.id,
  //       //     name: empObj?.name,
  //       //     interval: 15
  //       //   }
  //       // })

  //       // let newBookingInterval = {...newParsedData?.BookingInterval, 'intervalForIndividualEmployee': individualData}

  //       // console.log('individualData', newBookingInterval);
        
  //       setInitialValues(parsedData);
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const fetchSettings = async (empList) => {
    try {
      const res = await apiFetcher.get(
        "https://api.fiind.app/api/v1/store/outlet/setting"
      );

      const data = res.data.data.settings.filter(
        (setting) =>
          setting.settingCategory === "outlet" &&
          setting.settingName === "OnlineBooking"
      )[0].value;

      const parsedData = JSON.parse(data);
      console.log(parsedData);
      let newParsedData = {...parsedData}
     
      let individualData = parsedData?.BookingInterval?.intervalForIndividualEmployee

      console.log('individualData', individualData);
      
      if(!individualData){

        empList.map((empObj)=>{
          individualData[empObj?.id] = {
            id: empObj?.id,
            name: empObj?.name,
            interval: 15
          }
        })
        
      }
      let newBookingInterval = {...newParsedData?.BookingInterval, 'intervalForIndividualEmployee': individualData}

      console.log('individualData', newBookingInterval);
      
      setInitialValues({...parsedData, BookingInterval:newBookingInterval});
    } catch (error) {
      console.log("error", error);
    }
  };


  // useEffect(()=>{

  //   const newParsedData = formik.values
  //   let individualData = {}

  //   Employee.map((empObj)=>{
  //     individualData[empObj?.id] = {
  //       id: empObj?.id,
  //       name: empObj?.name,
  //       interval: 15
  //     }
  //   })

  //   let newBookingInterval = {...newParsedData?.BookingInterval, 'intervalForIndividualEmployee': individualData}

  //   setInitialValues({...initialValues,'BookingInterval':newBookingInterval })
  //   formik.setFieldValue({...initialValues,'BookingInterval':newBookingInterval })

  //   // console.log('individualData', newBookingInterval);
    
  // },[Employee.length])

  const items = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
    { id: 4, name: "Item 4" },
  ]

  const tooltipContent = <div style={{ padding: "5px" }}>content here</div>;

  const handleDataNeedsChange = (option) => {
    const {id, field} = option
    if(![1, 2].includes(id)){

      formik.setFieldValue(
        `DataFromClients.${field}`,
        !formik.values.DataFromClients[field]
      );
    }
  };

  const handleEmployeeToggle = (id) => {
    const selectedEmployees = formik.values.onlineBooking.specificEmployee;
    const updatedEmployees = selectedEmployees.includes(id)
      ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
      : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue("onlineBooking.specificEmployee", updatedEmployees);
  };

  const handleMiniGapCalEmployeeToggle = (id) => {
    const selectedEmployees = formik.values.BookingInterval.miniGapCalEmployee;
    const updatedEmployees = selectedEmployees.includes(id)
      ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
      : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue(
      "BookingInterval.miniGapCalEmployee",
      updatedEmployees
    );
  };

  const handleMiniGapCalEmployeeToggleAll = (event) => {
    console.log('event', event.target.value);
   const allEmpId = Employee.map((empObj)=>empObj.id)

    console.log('allEmpId', allEmpId);
    
    // const selectedEmployees = formik.values.BookingInterval.miniGapCalEmployee;
    // const updatedEmployees = selectedEmployees.includes(id)
    //   ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
    //   : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue(
      "BookingInterval.miniGapCalEmployee",
      allEmpId
    );
  };

  const setIndividualInterval=(id,intervalData)=>{
    let updatedInterval = {...formik.values.BookingInterval.intervalForIndividualEmployee}
    updatedInterval[id] = {...updatedInterval[id], interval: intervalData}
    formik.setFieldValue(
      "BookingInterval.intervalForIndividualEmployee",
      updatedInterval
    );
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
         <Stack sx={{ paddingRight: 4, paddingLeft: 4, marginBottom: 10 }}>
        <ToastContainer />
        <Box
        
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            paddingTop: 2, paddingBottom: 2 ,
            paddingRight: 4, paddingLeft: 4,
            
          }}
        >
          {isChanges && (
            <CommonButton
              type="submit"
              width="auto"
              height={40}
              title={"Save changes"}
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            />
          )}
        </Box>

  <Stack spacing={6} sx={{ bgcolor: "#fff", borderRadius: "25px",    
                           height: "86vh",
                        //    overflowY:'auto',
                            scrollbarWidth:'none',
                           overflowX:'hidden' }}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              bgcolor: "#FFFFFF",
              borderRadius: "25px",
              flexDirection: "column",
              width: "100%",
              padding: 5,
           
            }}
          >

            <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', minHeight: 100}}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                  <PrimaryHeading text={"Online booking"} />
                  <SecondaryHeading text={"Enable/disable online booking."} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%', }}>
                    <Stack flex={1} flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    <Stack flex={1} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    
                    <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Allow online booking</Typography>

                    <FSwitch
                      checked={formik.values.onlineBooking.allowOnlineBooking}
                      onChange={(event) => {
                        formik.setValues({
                          ...formik.values,
                          onlineBooking: {
                            ...formik.values.onlineBooking,
                            allowOnlineBooking: event.target.checked,
                          },
                        });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            color: "#fff",
                          },
                          "&.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "#44B904",
                          },
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#D9D9D9",
                        },
                      }}
                    />
                    </Stack>
                    {

                    formik.values.onlineBooking.allowOnlineBooking &&
                    <Stack flex={1} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Select employees</Typography>
                      {Employee.map((option, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <FSwitch
                            checked={formik.values.onlineBooking.specificEmployee.includes(
                              option.id
                            )}
                            onChange={() => handleEmployeeToggle(option.id)}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": { backgroundColor: "#D9D9D9" },
                            }}
                          />
                          <Typography
                          variant="body1"
                            sx={{
                              fontWeight: 400,
                              color: "#1F1F1F",
                            }}
                          >
                            {option.name}
                          </Typography>
                        </Box>
                      ))}
                      </Stack>
                    }
                    </Stack>
                  </Box>
              </Box>
            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

       

            <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', minHeight: 100}}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%', pr:3 }}>
                  <PrimaryHeading text={"Booking interval"} />
                  <SecondaryHeading
                    text={
                      "Here you can change the interval of your online bookings."
                    }
                  />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%', }}>
                    <Stack flex={1} flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    <Stack flex={1} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    
                    <Stack
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        
                        }}
                      >
                  

                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Choose interval, for online bookings</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>
                <CustomSelect
                  value={formik.values.BookingInterval.intervalForOnlineBooking}
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      BookingInterval: {
                        ...formik.values.BookingInterval,
                        intervalForOnlineBooking: event.target.value,
                      },
                    });
                  }}
                  options={onlinBookingsIntervals}
                  sx={{ width: "50%" , marginTop:1}}
                />

                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                
                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Same interval for all employees</Typography>
                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                  </Stack>
                  <FSwitch
                    checked={
                      formik.values.BookingInterval.sameIntervalForAllEmployee
                    }
                    onChange={(event) => {
                      formik.setValues({
                        ...formik.values,
                        BookingInterval: {
                          ...formik.values.BookingInterval,
                          sameIntervalForAllEmployee: event.target.checked,
                        },
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                        "&.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: "#44B904",
                        },
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "#D9D9D9",
                      },
                    }}
                  />

                    {
                     ( formik.values.BookingInterval.intervalForIndividualEmployee &&  !formik.values.BookingInterval.sameIntervalForAllEmployee) &&
                      Object.values(formik.values.BookingInterval.intervalForIndividualEmployee).map((obj)=>{

                        return (
                          <Stack flexDirection={'row'} flex={1} alignItems={'center'} key={obj.id}>
                          <CustomSelect

                              width='50%'
                              value={obj.interval}
                              onChange={(event) => {
                                setIndividualInterval(obj.id, event.target.value)
                                // formik.setValues({
                                //   ...formik.values,
                                //   BookingInterval: {
                                //     ...formik.values.BookingInterval,
                                //     intervalForOnlineBooking: event.target.value,
                                //   },
                                // });
                              }}
                              options={onlinBookingsIntervals}
                              sx={{ max: "50%" , marginTop:1, minWidth:160}}
                            />
                      <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700, ml:2, width:'50%'}}>{obj.name}{obj.interval}</Typography>
                      </Stack>
                        )
                      })

                 
                  }
                    </Stack>
                    {

                    formik.values.onlineBooking.allowOnlineBooking &&
                    <Stack flex={1} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                    <Stack flex={1} flexDirection={'row'} alignItems={'center'}>
                      <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Minimize gaps in the calendar</Typography>
                      <Tooltip arrow title={tooltipContent}>
                        <img
                          src={TooltipIcon}
                          alt="IconOne"
                          style={{ marginLeft: 4, width: 18, height: 18 }}
                        />
                      </Tooltip>
                    </Stack>

                       <Box
                         
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            
                          }}
                        >
                          <FSwitch
                            checked={ formik.values.BookingInterval.miniGapCalEmployee.length == Employee.length}
                            onChange={handleMiniGapCalEmployeeToggleAll}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": { backgroundColor: "#D9D9D9" },
                            }}
                          />
                          <Typography
                          variant="body1"
                            sx={{
                              fontWeight: 400,
                              color: "#1F1F1F",
                            }}
                          >
                            All
                          </Typography>
                        </Box>

                      {Employee.map((option, index) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            
                          }}
                        >
                          <FSwitch
                            checked={formik.values.BookingInterval.miniGapCalEmployee.includes(
                              option.id
                            )}
                            onChange={() => handleMiniGapCalEmployeeToggle(option.id)}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": { color: "#fff" },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": { backgroundColor: "#D9D9D9" },
                            }}
                          />
                          <Typography
                          variant="body1"
                            sx={{
                              fontWeight: 400,
                              color: "#1F1F1F",
                            }}
                          >
                            {option.name}
                          </Typography>
                        </Box>
                      ))}
                      </Stack>
                    }
                    </Stack>
                  </Box>
              </Box>


            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                minHeight: "200px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "33%", pr:3 }}
              >
                <PrimaryHeading text={"Restrictions for bookings"} />
                <SecondaryHeading
                  text={"Here you can adjust restrictions for online bookings."}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "67%" }}
              >
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                
                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>How long before the start of the agreement can the customer book online?</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>

                <CustomSelect
                  value={
                    formik.values.restrictionForBooking
                      .howLongBeforeCustomerBook
                  }
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      restrictionForBooking: {
                        ...formik.values.restrictionForBooking,
                        howLongBeforeCustomerBook: event.target.value,
                      },
                    });
                  }}
                  options={howLongBeforeCustomerOptions}
                  sx={{ width: "25%", marginTop:1 }}
                />

                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                 
                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>How far into the future will you allow customers to book?</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>

                <CustomSelect
                  value={
                    formik.values.restrictionForBooking.howFarFutureCustomerbook
                  }
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      restrictionForBooking: {
                        ...formik.values.restrictionForBooking,
                        howFarFutureCustomer: event.target.value,
                      },
                    });
                  }}
                  options={howFarFutureCustomerOptions}
                  sx={{ width: "25%", marginTop:1 }}
                />

                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                  alignItems={'center'}
                >
                  {/* <PrimaryHeading
                    text={
                      "How long before the start of the appointment can the customer cancel booking?"
                    }
                  /> */}

                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>How long before the start of the appointment can the customer cancel booking?</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>

                <CustomSelect
                  value={
                    formik.values.restrictionForBooking
                      .longBeforeCustomerCancelBookingOptionBook
                  }
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      restrictionForBooking: {
                        ...formik.values.restrictionForBooking,
                        longBeforeCustomerCancelBookingOption:
                          event.target.value,
                      },
                    });
                  }}
                  options={longBeforeCustomerCancelBookingOptions}
                  sx={{ width: "25%", marginTop:1 }}
                />

                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                  alignItems={'center'}
                >
                  {/* <PrimaryHeading text={"Cancellation policy"} /> */}
                  <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Cancellation policy</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>
                <TextareaAutosize
                  minRows={10}
                  maxRows={50}
                  value={formik.values.restrictionForBooking.cancellationPolicy}
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      restrictionForBooking: {
                        ...formik.values.restrictionForBooking,
                        cancellationPolicy: event.target.value,
                      },
                    });
                  }}
                  style={{
                    
                    width: "100%",
                    height: "20px",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D9D9D9",
                    outline: "none",
                    resize: "none",
                    marginTop: 10,
                  }}
                />

                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
             

                <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Message when customer tries to cancel, but it’s too late</Typography>

                  <Tooltip arrow title={tooltipContent}>
                    <img
                      src={TooltipIcon}
                      alt="IconOne"
                      style={{ marginLeft: 4, width: 18, height: 18 }}
                    />
                  </Tooltip>
                </Stack>

                <CustomTextField
                  value={formik.values.restrictionForBooking.messageForTooLate}
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      restrictionForBooking: {
                        ...formik.values.restrictionForBooking,
                        messageForTooLate: event.target.value,
                      },
                    });
                  }}
                />
              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%", pr:3 }}
              >
                <PrimaryHeading text={"Data you need from your clients"} />
                <SecondaryHeading
                sx={{whiteSpace: 'pre-line', }}
                  text={`Here you can choose what information your  customer needs to provide, for your clinic to accept their booking.\n\n  You can’t deactivate:\nName\nPhone number`}
                />
               
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {dataNeedsFromClintsFirst.map((option, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                       <FSwitch
                      checked={formik.values.DataFromClients[option.field]}
                      onChange={(e) => {
                       formik.setFieldValue(`DataFromClients.${option.field}`, e.target.checked);
                      }}
                     
                    />
                    <Typography
                    variant="body1"
                      sx={{
                        fontWeight: 400,
                        color: "#1F1F1F",
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {dataNeedsFromClintsSec.map((option, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <FSwitch
                      checked={formik.values.DataFromClients[option.field]}
                      onChange={() => handleDataNeedsChange(option)}
                     
                    />
                    <Typography
                    variant="body1"
                      sx={{
                        fontWeight: 400,
                        color: "#1F1F1F",
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "33%", pr:3 }}
              >
                <PrimaryHeading text={"Employee selection order"} />

                <SecondaryHeading
                  text={
                    "Here you can change the order of employees, shown to the customer. Starting from top."
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "67%",
                  minHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {/* <DraggableListComponent list={items} /> */}
                {
                            Object.keys(reOrderEmp).length > 0 &&

                            <MultipleContainers
                                modelType={'Emp'}
                                itemCount={Object.keys(reOrderEmp).length}
                                items={reOrderEmp}
                                setItems={setReorderEmp}
                                strategy={rectSortingStrategy}
                                vertical
                                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                                // onRemove={(containerID) => handleRemoveContianer(containerID)}
                                // onRemoveItem={(itemId, containerID) => handleRemoveItem(itemId, containerID)}
                                onDragToAnotherContainer={(containerId, itemId) => {
                                    console.log(containerId, itemId);
                                }}

                                onDragComplete={(isContainer, containerId, updatedcontainers) => {

                                    console.log('on drag complete', containerId);

                                    let dataToUpdate = []
                                    if (isContainer) {

                                        console.log(`isContainer:${isContainer}`, `containerId:${containerId}`)
                                        let newjournalGroups = { ...reOrderEmp }
                                        updatedcontainers.map((containerId, index) => {
                                            if (containerId != 0) {

                                                dataToUpdate.push({ 'id': containerId, "sequence": index })
                                            }
                                            newjournalGroups[containerId].sequence = index

                                        })

                                        console.log('newjournalGroups', newjournalGroups);
                                        setReorderEmp(newjournalGroups)
                                        updateEmpSequence(dataToUpdate)
                                    } 
                                    // else {
                                    //     journalGroups[containerId].services.map((serviceObj) => {
                                    //         dataToUpdate.push({ id: serviceObj.id, group_id: containerId == '0' ? null : containerId, sequence: serviceObj?.sequence })
                                    //     })
                                    //     console.log('dataToUpdate else', dataToUpdate);
                                    // }
                                }}
                            />
                        }


              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                minHeight: "200px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "33%", pr:3 }}
              >
                <PrimaryHeading text={"Integrate booking on your website"} />

                <SecondaryHeading
                  text={
                    "If you wish to integrate online booking directly to your website, you’ll need to put in this iframe code."
                  }
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "67%" }}
              >
              
                <Typography variant="body1" sx={{color:'#1f1f1f', fontWeight:700}}>Iframe code</Typography>


                <CustomTextField
                  value={formik.values.iframeCode}
                  onChange={(event) => {
                    formik.setValues({
                      ...formik.values,
                      iframeCode: event.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        </Stack>
        </Stack>
      </form>
    </>
  );
};

export default OnlineBookingSettingsOption;
