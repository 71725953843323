import {  array, boolean, number, object, string } from 'yup';

export const generalSettingsValidate = object({
    name: string().required('Business name is required'),
    address: string().required(' business street name is required'),
    
    zip_code: string().required('Zip code is required'),
    about: string().required('About is required'),
    contact_number: string().required('contact number is required'),
    contact_number2: string().required('Alternate number  is required'),
   
    city: string().required('City is required'),
    cvr_number: string().required('CVR number is required'),
    lng: number().typeError('Longitude must be a number').required('Longitude is required'),
    lat: number().typeError('latitube must be a number').required('Latitude is required'),
    email: string().email('Invalid email format').required('Email is required'),
    hide_email: boolean().default(false),
    website : string().required('Website is required'),
    instagram : string().required('Instagram is required'),
    facebook : string().required('Facebook is required'),
    tiktok : string().required('Tiktok is required'),
    images : array(),
    profile_image : string(),
    banner_image : string()
    // bookingURL : string().required('Booking URL is required'),


})