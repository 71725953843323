import React from "react";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const CustomTimePicker = ({
    value,
    onChange,
    options,
    sx = {},
    size = "small",
    borderColor = "#D9D9D9",
    padding = 1,
    borderThickness = "1px",
    inputColor = "#A0A0A0",
    iconVisibility = false,
    borderRadius = '13px',
    border,
    ...props
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                ampm={false}
                sx={{
                    "&.Mui-focused": { outline: "none", boxShadow: "none" },
                    "& .MuiOutlinedInput-root": {
                        padding: padding,
                        border: `${borderThickness} solid ${borderColor}`,
                        borderRadius: borderRadius,
                    },
                    "& .MuiInputBase-input": {
                        pl: 0,
                        py: 0,
                        color: inputColor,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "auto",
                    },
                    
                    "& .MuiOutlinedInput-notchedOutline": { border: "none", padding: 0 },
                    "& .MuiPaper-root": {
                        // position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: "#FFFFFF",
             
                    },
                    "& .MuiButtonBase-root": {
                        // position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        margin: 0,
                        padding: 0,
                        backgroundColor: "transparent",
                    },
                    border: border ?? "1px solid #d9d9d9",
                    borderRadius: borderRadius,
                    "& .MuiSvgIcon-root": {
                        // visibility: iconVisibility ? "visible" : "hidden",
                        height:'1.2rem',
                        width:'1.2rem'
                    },
                    // "&:hover": {
                    //     "& .MuiButtonBase-root": { backgroundColor: "transparent" },
                    //     "& .MuiSvgIcon-root": { visibility: iconVisibility ? "visible" : "hidden" },
                    // },
                    ...sx, // Allow external sx overrides
                    
                  
                }}
                value={value}
                onChange={onChange}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default CustomTimePicker;
