import React, { useState, useEffect } from "react";
import { Box, Stack, useTheme, useMediaQuery, Skeleton } from "@mui/material";
import axios from "axios";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PrimaryHeading from "../../components/settings/commonPrimaryHeading";
import SecondaryHeading from "../../components/settings/commonSecondaryHeading";
import CommonButton from "../../components/settings/commonButton";
import ServiceModal from "../../components/service/addServiceModal";
import ServiceGroupModal from "../../components/service/addServiceGroupMoadal";

import { ToastContainer, toast } from "react-toastify";
import { GridMenuIcon } from "@mui/x-data-grid";
import { MultipleContainers } from "../../components/MultipleContainers/MultipleContainers";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import CustomDeleteModal from "../../components/deleteAlertModal";
import menu from '../../assets/menu.svg';


const Services = () => {
    const authTokenUser = localStorage.getItem('auth_token');
    const theme = useTheme();

    const [type, setType] = useState('create')
    const [isLoading, setLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedServiceGroup, setSelectedServiceGroup] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState(null);

    const [parent, setParent] = useState(null);



    useEffect(() => {
        setLoading(true);
        fetchServiceApi();
    }, []);

    const transformServiceList = (data) => {
        const groupedServices = data
            .filter(group => group.id !== 0)
            .map(group => ({
                title: group.group,
                groupId: group.id,
                services: group.services.length > 0 ? group.services : [],
                sequence: group.sequence
            }));

        const ungroupedServices = data
            .filter(group => group.id === 0)
            .flatMap(group => group.services)
            .map(service => ({
                ...service,
                groupId: 0,
            }));

        const serviceListObj = {
            0: {
                title: 'Services without a group',
                groupId: 0,
                services: ungroupedServices,
                sequence: 0
            }
        }

        groupedServices.map((groupObj) => {
            serviceListObj[groupObj?.groupId] = groupObj
        })

        return serviceListObj
    };

    async function fetchServiceApi() {

        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service_group`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                // console.log('response', response);

                setServiceList(transformServiceList(response.data.data));
                setLoading(false);
            } else {
                setServiceList({});
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
            setServiceList({});
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const deleteServiceAPI = async (id, isGroup = false) => {
        try {

            // console.log('id', id);

            let serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/service/${id}`
            if (isGroup) {
                serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/service_group/${id}`
            }

            const response = await axios.delete(serviceURL, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response.status == 200) {
                if (isGroup) {
                    toast.success(`Service group deleted successfully`);
                } else {
                    toast.success(`Service deleted successfully`);
                }
                fetchServiceApi();
            } else {
                if (isGroup) {
                    toast.error(`Failed to delete service group`);

                }
                else {

                    toast.error(`Failed to delete service`);
                }
            }
        } catch (error) {
            toast.error(`Failed to delete service`);
            console.log('ERROR ', error)
        }
    };

    const sequenceUpdateAPI = async (data, isGroup = false) => {
        try {




            let serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/service/sequence`
            if (isGroup) {
                serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/service_group/sequence`
            }
            console.log('serviceURL', serviceURL);

            const response = await axios.post(serviceURL, data, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response.status == 200) {
                toast.success(`Updated successfully`);
                fetchServiceApi();
            } else {
                toast.error(`Failed to update`);
            }
        } catch (error) {
            toast.error(`Failed to update service`);
            console.log('ERROR ', error)
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleClose = (command) => {
        setShowModal(false);
        if (command) {
            fetchServiceApi();
        };
    };

    const handleCloseAddGroupModal = () => {
        setShowAddGroupModal(false);
        fetchServiceApi();
    };

    function handleRemove(containerID) {
        setItemsToDelete({ 'containerId': containerID, 'type': 'container', 'itemId': null, container: serviceList[containerID], item: null })
        setShowDeleteModal(true)
    }

    function handleRemoveItem(itemId, containerID) {
        setItemsToDelete({ 'containerId': containerID, 'type': 'item', 'itemId': itemId, container: serviceList[containerID], item: serviceList[containerID].services.find((serviceObj) => serviceObj?.id == itemId) })
        setShowDeleteModal(true)

    }

    const handleDelete = async () => {

        if (itemsToDelete) {
            let newServiceList = { ...serviceList }
            const containerId = itemsToDelete?.containerId
            if (itemsToDelete?.type == 'container') {
                delete newServiceList[containerId]
                console.log('containerID', newServiceList);
                setServiceList(newServiceList)
                deleteServiceAPI(containerId, true)

            } else {
                const itemId = itemsToDelete?.itemId
                let updatedItems = newServiceList[containerId].services.filter((serviceObj) => serviceObj?.id !== itemId)
                let newContainer = { ...newServiceList[containerId], services: updatedItems }
                setServiceList({ ...newServiceList, [containerId]: newContainer })
                deleteServiceAPI(itemId, false)

            }
            setShowDeleteModal(!showDeleteModal)
        }
    }


    console.log('serviceList', serviceList);

    return (
        <>


            <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>

                <ToastContainer />

                <Box sx={{ display: "flex", bgcolor: '#FFFFFF', borderRadius: '25px', flexDirection: 'column', width: '95%', height: '92vh', padding: 5 }}>

                    <Stack flexDirection={'row'} sx={{ display: "flex", justifyContent: "space-between", width: '100%', alignItems: 'center' }}>

                        <Stack sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                            <PrimaryHeading text={"Create, edit & sort services"} />

                        </Stack>
                        <Stack flex={1} flexDirection={'column'} justifyContent={'flex-end'} >
                            <Stack flex={1} flexDirection={'row'} justifyContent={'flex-end'}>
                                <CommonButton
                                    height="40px"
                                    style={{ marginLeft: 2, width: 170 }}
                                    backgroundColor={'#D9D9D9'}
                                    onClick={() => { setType('create'); setSelectedServiceGroup(null); setShowAddGroupModal(true) }}
                                    title={'Add group'}
                                    icon={<img src={menu} />}
                                />

                                <CommonButton
                                    height="40px"
                                    style={{ marginLeft: 2, width: 170 }}
                                    onClick={() => { setType('create'); setSelectedItem(null); setShowModal(true) }}
                                    title={'+ Add service'}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack flexDirection={'row'} sx={{ display: "flex", justifyContent: "space-between", width: '100%', alignItems: 'start', mt: 0 }}>

                        <Stack sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                            <SecondaryHeading text={"Here you can create, edit and sort services and groups."} />
                        </Stack>
                        <Stack flex={1} flexDirection={'column'} justifyContent={'flex-end'} >


                            <Stack display='flex' flexDirection='column' width={'auto'} sx={{
                                mt: 2,

                                maxHeight: '78vh', overflowY: 'scroll',

                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                ' -ms-overflow-style': 'none'
                            }}>

                                {
                                    isLoading ?

                                        <Stack width="98%" spacing={2} flex={1} flexDirection={'column'} alignItems={'flex-end'} sx={{ mt: 2 }}>
                                            <Skeleton variant="rounded" width="100%" height={40} />
                                            {/* <Stack flex={1} flexDirection={'column'} justifyContent={'flex-end'} alignItems={'flex-end'}> */}
                                            {[...Array(7)].map((_, index) => (
                                                // <Box key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 4 }}>

                                                <Skeleton variant="rounded" width="94%" height={40} />

                                                // </Box>
                                            ))}
                                            {/* </Stack> */}
                                        </Stack>
                                        :
                                        Object.keys(serviceList).length > 0 &&

                                        <MultipleContainers
                                            itemCount={Object.keys(serviceList).length}
                                            items={serviceList}
                                            setItems={setServiceList}
                                            strategy={rectSortingStrategy}
                                            vertical
                                            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                                            onRemove={handleRemove}
                                            onRemoveItem={handleRemoveItem}
                                            onDragToAnotherContainer={(containerId, itemId) => {
                                                console.log(containerId, itemId);
                                            }}

                                            onDragComplete={(isContainer, containerId, updatedcontainers) => {

                                                console.log('on drag complete', containerId);

                                                let dataToUpdate = []
                                                if (isContainer) {


                                                    console.log(`isContainer:${isContainer}`, `containerId:${containerId}`)
                                                    let newServiceList = { ...serviceList }
                                                    updatedcontainers.map((containerId, index) => {
                                                        if (containerId != 0) {

                                                            dataToUpdate.push({ 'id': containerId, "sequence": index })
                                                        }
                                                        newServiceList[containerId].sequence = index

                                                    })

                                                    // console.log('newServiceList', newServiceList);
                                                    setServiceList(newServiceList)


                                                } else {
                                                    serviceList[containerId].services.map((serviceObj) => {
                                                        dataToUpdate.push({ id: serviceObj.id, group_id: containerId == '0' ? null : containerId, sequence: serviceObj?.sequence })
                                                    })

                                                    console.log('dataToUpdate else', dataToUpdate);



                                                }

                                                sequenceUpdateAPI(dataToUpdate, isContainer)

                                            }}
                                            onclickItem={(e) => { setType('edit'); setSelectedItem(e); setShowModal(true) }}
                                            onclickContainer={(e) => { setType('edit'); setSelectedServiceGroup(e); setShowAddGroupModal(true) }}
                                        />
                                }

                            </Stack>

                        </Stack>
                    </Stack>

                </Box>

                {showModal &&
                    <ServiceModal
                        type={type}
                        open={showModal}
                        handleClose={handleClose}
                        selectedItem={selectedItem}
                    />
                }

                {showAddGroupModal &&
                    <ServiceGroupModal
                        open={showAddGroupModal}
                        handleClose={handleCloseAddGroupModal}
                        serviceList={serviceList}
                        selectedServiceGroup={selectedServiceGroup}
                        type={type}
                    />
                }

                {showDeleteModal &&
                    <CustomDeleteModal
                        open={showDeleteModal}
                        handleClose={handleCloseDeleteModal}
                        description={
                            <>
                                Are you sure you want to delete
                                <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                    {itemsToDelete?.type == 'container' ? itemsToDelete?.container.title : itemsToDelete?.item.name}
                                </span>
                                {/* {itemsToDelete?.type == 'container' ? 'service group': 'service'} */}
                            </>
                        }
                        onClickDismiss={handleCloseDeleteModal}
                        onClickConfirm={() => handleDelete()}
                    />
                }


            </Stack>
        </>
    );
};

export default Services;