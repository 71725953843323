import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    ListItem, Box, Modal, TextareaAutosize, Divider, List, IconButton, Grid,
    Typography, Checkbox, FormControlLabel, Paper, ListItemText, CircularProgress,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import SecondaryHeading from '../settings/commonSecondaryHeading';
import serviceNameIcon from "../../assets/nameOfServiceIcon.png";
import cameraIcon from "../../assets/cameraIcon.png";
import cashIcon from "../../assets/priceicon.png";
import CustomSearchBar from '../settings/commonSearchBar';
import ServiceTypeIcon from "../../assets/servicetypeiconNew.png";
import DescriptionIcon from "../../assets/edit.png";
import DurationIcon from "../../assets/durationClock.png";
import CustomSelect from '../settings/commonCustomSelect';
import DeleteIcon from "../../assets/DeleteIcon.png";
import AddEmployeeicon from "../../assets/whoOffersServiceIcon.png";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PrimaryHeading from '../settings/commonPrimaryHeading';

const durationList = [
    {
        value: `5 min`,
        label: `5 min`,
        minutes: 5,
    },
    {
        value: `10 min`,
        label: `10 min`,
        minutes: 10,
    },
    {
        value: `15 min`,
        label: `15 min`,
        minutes: 15,
    }, {
        value: `20 min`,
        label: `20 min`,
        minutes: 20,
    }, {
        value: `25 min`,
        label: `25 min`,
        minutes: 25,
    },
    {
        value: '30 min',
        label: `30 min`,
        minutes: 30,
    },
    {
        value: '35 min',
        label: `35 min`,
        minutes: 35,
    },
    {
        value: '40 min',
        label: `40 min`,
        minutes: 40,
    },
    {
        value: '45 min',
        label: `45 min`,
        minutes: 45,
    },
    {
        value: '50 min',
        label: `50 min`,
        minutes: 50,
    }, {
        value: '55 min',
        label: `55 min`,
        minutes: 55,
    },
    {
        value: '1 h',
        label: `1 h`,
        minutes: 60,
    },
    {
        value: '1 h. 5 min',
        label: `1 h. 5 min`,
        minutes: 65,
    },
    {
        value: '1 h. 10 min',
        label: `1 h. 10 min`,
        minutes: 70,
    },
    {
        value: '1 h. 15 min',
        label: `1 h. 15 min`,
        minutes: 75,
    },
    {
        value: '1 h. 20 min',
        label: `1 h. 20 min`,
        minutes: 80,
    },
    {
        value: '1 h. 25 min',
        label: `1 h. 25 min`,
        minutes: 85,
    },
    {
        value: '1 h. 30 min',
        label: `1 h. 30 min`,
        minutes: 90,
    },
    {
        value: '1 h. 35 min',
        label: `1 h. 35 min`,
        minutes: 95,
    },
    {
        value: '1 h. 40 min',
        label: `1 h. 40 min`,
        minutes: 100,
    },
    {
        value: '1 h. 45 min',
        label: `1 h. 45 min`,
        minutes: 105,
    },
    {
        value: '1 h. 50 min',
        label: `1 h. 50 min`,
        minutes: 110,
    },
    {
        value: '1 h. 55 min',
        label: `1 h. 55 min`,
        minutes: 115,
    },
    {
        value: '2 h',
        label: `2 h`,
        minutes: 120,
    },
    //////////
    {
        value: '2 h. 5 min',
        label: `2 h. 5 min`,
        minutes: 125,
    },
    {
        value: '2 h. 10 min',
        label: `2 h. 10 min`,
        minutes: 130,
    },
    {
        value: '2 h. 15 min',
        label: `2 h. 15 min`,
        minutes: 135,
    },
    {
        value: '2 h. 20 min',
        label: `2 h. 20 min`,
        minutes: 140,
    },
    {
        value: '2 h. 25 min',
        label: `2 h. 25 min`,
        minutes: 145,
    },
    {
        value: '2 h. 30 min',
        label: `2 h. 30 min`,
        minutes: 150,
    },
    {
        value: '2 h. 35 min',
        label: `2 h. 35 min`,
        minutes: 155,
    },
    {
        value: '2 h. 40 min',
        label: `2 h. 40 min`,
        minutes: 160,
    },
    {
        value: '2 h. 45 min',
        label: `2 h. 45 min`,
        minutes: 165,
    },
    {
        value: '2 h. 50 min',
        label: `2 h. 50 min`,
        minutes: 170,
    },
    {
        value: '2 h. 55 min',
        label: `2 h. 55 min`,
        minutes: 175,
    },
    {
        value: '3 h',
        label: `3 h`,
        minutes: 180,
    },
    //////////
    {
        value: '3 h. 5 min',
        label: `3 h. 5 min`,
        minutes: 185,
    },
    {
        value: '3 h. 10 min',
        label: `3 h. 10 min`,
        minutes: 190,
    },
    {
        value: '3 h. 15 min',
        label: `3 h. 15 min`,
        minutes: 195,
    },
    {
        value: '3 h. 20 min',
        label: `3 h. 20 min`,
        minutes: 200,
    },
    {
        value: '3 h. 25 min',
        label: `3 h. 25 min`,
        minutes: 205,
    },
    {
        value: '3 h. 30 min',
        label: `3 h. 30 min`,
        minutes: 210,
    },
    {
        value: '3 h. 35 min',
        label: `3 h. 35 min`,
        minutes: 215,
    },
    {
        value: '3 h. 40 min',
        label: `3 h. 40 min`,
        minutes: 220,
    },
    {
        value: '3 h. 45 min',
        label: `3 h. 45 min`,
        minutes: 225,
    },
    {
        value: '3 h. 50 min',
        label: `3 h. 50 min`,
        minutes: 230,
    },
    {
        value: '3 h. 55 min',
        label: `3 h. 55 min`,
        minutes: 235,
    },
    {
        value: '4 h',
        label: `4 h`,
        minutes: 240,
    },
    //////////
    {
        value: '4 h. 5 min',
        label: `4 h. 5 min`,
        minutes: 245,
    },
    {
        value: '4 h. 10 min',
        label: `4 h. 10 min`,
        minutes: 250,
    },
    {
        value: '4 h. 15 min',
        label: `4 h. 15 min`,
        minutes: 255,
    },
    {
        value: '4 h. 20 min',
        label: `4 h. 20 min`,
        minutes: 260,
    },
    {
        value: '4 h. 25 min',
        label: `4 h. 25 min`,
        minutes: 265,
    },
    {
        value: '4 h. 30 min',
        label: `4 h. 30 min`,
        minutes: 270,
    },
    {
        value: '4 h. 35 min',
        label: `4 h. 35 min`,
        minutes: 275,
    },
    {
        value: '4 h. 40 min',
        label: `4 h. 40 min`,
        minutes: 280,
    },
    {
        value: '4 h. 45 min',
        label: `4 h. 45 min`,
        minutes: 285,
    },
    {
        value: '4 h. 50 min',
        label: `4 h. 50 min`,
        minutes: 290,
    },
    {
        value: '4 h. 55 min',
        label: `4 h. 55 min`,
        minutes: 295,
    },
    {
        value: '5 h',
        label: `5 h`,
        minutes: 300,
    },
    //////////
    {
        value: '5 h. 5 min',
        label: `5 h. 5 min`,
        minutes: 305,
    },
    {
        value: '5 h. 10 min',
        label: `5 h. 10 min`,
        minutes: 310,
    },
    {
        value: '5 h. 15 min',
        label: `5 h. 15 min`,
        minutes: 315,
    },
    {
        value: '5 h. 20 min',
        label: `5 h. 20 min`,
        minutes: 320,
    },
    {
        value: '5 h. 25 min',
        label: `5 h. 25 min`,
        minutes: 325,
    },
    {
        value: '5 h. 30 min',
        label: `5 h. 30 min`,
        minutes: 330,
    },
    {
        value: '5 h. 35 min',
        label: `5 h. 35 min`,
        minutes: 335,
    },
    {
        value: '5 h. 40 min',
        label: `5 h. 40 min`,
        minutes: 340,
    },
    {
        value: '5 h. 45 min',
        label: `5 h. 45 min`,
        minutes: 345,
    },
    {
        value: '5 h. 50 min',
        label: `5 h. 50 min`,
        minutes: 350,
    },
    {
        value: '5 h. 55 min',
        label: `5 h. 55 min`,
        minutes: 355,
    },
    {
        value: '6 h',
        label: `6 h`,
        minutes: 360,
    },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

const serviceTypeButtonsOption = [
    { id: 1, name: 'Ny behandling' },
    { id: 2, name: 'Klippekort' },
    { id: 3, name: 'Konsultation' },
    { id: 4, name: 'Tilbud til nye kunder' },
    { id: 5, name: 'Opfølgende' },
    { id: 6, name: 'Tilbud til faste kunder' },
];

function ServiceModal(props) {
    const { open, handleClose, selectedItem, type } = props;

    const formikRef = useRef(null);

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [selectedServiceTypeButtonsOption, setSelectedServiceTypeButtonsOption] = useState('');
    // const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [file, setFile] = useState(null);

    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [serviceTypeFilterList, setServiceTypeFilterList] = useState([]);
    const [serviceTypeSearch, setServiceTypeSearch] = useState('');

    const [employeesList, setEmployeesList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [selectedEmplpoyeeList, setSelectedEmplpoyeeList] = useState([]);
    const [employeeSearch, setEmployeeSearch] = useState('');

    const [group_id, setGroup_id] = useState(null);
    const [serviceDesc, setServiceDesc] = useState('');
    const [duration, setDuration] = useState(`5 min`);

    const [standardPrice, setStandardPrice] = useState('');
    const [isPriceStartFrom, setIsPriceStartFrom] = useState(false);
    const [showEmployeeList, setShowEmployeeList] = useState(false);

    const [isGovtApprovedVisible, setIsGovtApprovedVisible] = useState(false);
    const [isGovtApproved, setIsGovtApproved] = useState(false);
    const [isMachineVisible, setisMachineVisible] = useState(false);
    const [isMachine, setisMachine] = useState(false);
    const [howManyMachine, setHowManyMachine] = useState('');

    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedBundelOffer, setIsCheckedBundelOffer] = useState(false);

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (selectedItem && type === 'edit') {
            setGroup_id(selectedItem?.group_id || null);
            // setSelectedServiceTypes(selectedItem?.service_type_ids || []);
            setServiceDesc(selectedItem?.description || '');
            const matchedDuration = durationList.find(duration => duration.minutes === selectedItem?.duration_min);
            setDuration(matchedDuration?.value || null);
            setIsPriceStartFrom(selectedItem?.is_price_starts_with || false);
            if (selectedItem?.image) {
                const fetchImage = async () => {
                    try {
                        const response = await fetch(selectedItem.image);
                        const blob = await response.blob();
                        const fileFromImage = new File([blob], 'reuploaded-image.png', { type: blob.type });
                        setFile(fileFromImage);
                    } catch (error) {
                        console.error('Error fetching the image:', error);
                    }
                };
                fetchImage();
            } else {
                setFile(null);
            };

            setisMachine(selectedItem?.is_equipment_service || false);
            setHowManyMachine(selectedItem?.no_of_equipments.toString() || '');
            setIsChecked(selectedItem?.is_consultation || false);
            setIsCheckedBundelOffer(selectedItem?.is_bundle_offer || false);
        }
    }, [selectedItem]);

    useEffect(() => {
        fetchCategoryApi();
    }, []);

    async function fetchCategoryApi() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service/service_types`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response && response.data && response.data.data) {
                const arr = response.data.data.map(element => ({
                    ...element,
                    value: element.id,
                    label: element.service_type,
                }));

                setServiceTypeList(arr);
                setServiceTypeFilterList(arr);

                const item = arr.find((obj) => obj.id === selectedItem?.service_type_id);
                if (item && item.need_certification) {
                    setIsGovtApprovedVisible(true);
                    setIsGovtApproved(true);
                } else {
                    setIsGovtApprovedVisible(false);
                    setIsGovtApproved(false);
                }

                const itemEquipment = arr.find((obj) => obj.id === selectedItem?.service_type_id);
                if (itemEquipment && itemEquipment.is_equipment_based) {
                    setisMachineVisible(true);
                    setisMachine(true);
                } else {
                    setisMachineVisible(false);
                    setisMachine(false);
                }
            } else {
                setServiceTypeList([]);
                setServiceTypeFilterList([]);
            }

            fetchEmployees();
        } catch (error) {
            console.error("Error fetching service type data:", error);
            setServiceTypeList([]);
            setServiceTypeFilterList([]);
        } finally {
            setLoading(false);
        }
    }

    async function fetchEmployees() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/employee/get`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setEmployeesList(response.data.data);
                setFilterList(response.data.data);

                if (type == 'edit' && selectedItem && selectedItem.employees.length > 0) {
                    var arr = [];
                    for (let i = 0; i < response?.data.data.length; i++) {
                        const e = response?.data.data[i];
                        for (
                            let j = 0;
                            j < selectedItem.employees.length;
                            j++
                        ) {
                            const element = selectedItem.employees[j];
                            if (e.id == element.id)
                                arr.push({ ...e, price: element.price });
                        }
                    }
                    setSelectedEmplpoyeeList(arr);
                    // if (formikRef.current) {
                    //     formikRef.current.setFieldValue('selectedEmplpoyeeList', arr);
                    // }
                };

            } else {
                setServiceTypeList([]);
                setServiceTypeFilterList([]);
            }
        } catch (error) {
            console.error("Error fetching employee data:", error);
            setFilterList([]);
            setEmployeesList([]);
        } finally {
            setLoading(false);
        }
    }

    const handleRowClick = (name) => {
        setSelectedServiceTypeButtonsOption(name);
    };

    // const handleToggle = (service) => {
    //     setSelectedServiceTypes((prev) => {
    //         const isAlreadySelected = prev.some((el) => el.id === service.id) || prev.includes(service.id);
    //         let updatedList;

    //         if (isAlreadySelected) {
    //             updatedList = prev.filter((el) => el.id !== service.id && el !== service.id);
    //         } else {
    //             updatedList = [...prev, service];
    //         }

    //         setIsGovtApproved(
    //             updatedList.some((selectedItem) => selectedItem.need_certification === true)
    //         );

    //         const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);
    //         setisMachineVisible(allSelectedRequireEquipment);

    //         return updatedList;
    //     });
    // };

    const handleToggle = (service, setFieldValue, values) => {
        const isAlreadySelected = values.selectedServiceTypes.some((el) => el.id === service.id) || values.selectedServiceTypes.includes(service.id);

        let updatedList;

        if (isAlreadySelected) {
            updatedList = values.selectedServiceTypes.filter((el) => el.id !== service.id && el !== service.id);
        } else {
            updatedList = [...values.selectedServiceTypes, service];
        }

        setFieldValue('selectedServiceTypes', updatedList);

        const isGovtApproved = updatedList.some((selectedItem) => selectedItem.need_certification === true);
        const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);

        setIsGovtApproved(isGovtApproved);
        setisMachineVisible(allSelectedRequireEquipment);
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const updateServiceTypeSearch = useCallback((text) => {
        if (text) {
            const searchTerm = text.trim().toUpperCase();
            const newData = serviceTypeList.filter((item) => {
                const itemLabel = item.label ? item.label.trim().toUpperCase() : '';
                return itemLabel.includes(searchTerm);
            });
            setServiceTypeFilterList(newData);
        } else {
            setServiceTypeFilterList(serviceTypeList);
        }
        setServiceTypeSearch(text);
    }, [serviceTypeList]);

    const updateEmployeeSearch = useCallback((text) => {
        if (text) {
            const newData = employeesList.filter(function (item) {
                const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const textData = text.toUpperCase();
                return itemData.startsWith(textData);
            });
            setFilterList(newData);
        } else {
            setFilterList(employeesList);
        }
        setEmployeeSearch(text);
    }, [employeesList]);

    const handleChangePriceStartFrom = (event) => {
        setIsPriceStartFrom(event.target.checked);
    };

    function addEmployee(item) {
        if (selectedEmplpoyeeList.some((el) => el.id == item.id)) {
            const index = selectedEmplpoyeeList.findIndex(
                (e) => e.id == item.id,
            );
            if (index !== undefined) selectedEmplpoyeeList.splice(index, 1);
        } else {
            selectedEmplpoyeeList.push({
                ...item,
                price: standardPrice != '' ? standardPrice : '',
            });
        }
        setSelectedEmplpoyeeList((prev) => [...prev]);
    };


    const handlePriceChange = (id, value) => {
        setSelectedEmplpoyeeList((prevEmployees) =>
            prevEmployees.map((employee) =>
                employee.id === id ? { ...employee, price: value } : employee
            )
        );
    };

    const validationSchema = Yup.object({
        serviceName: Yup.string()
            .required('Service name is required')
            .min(3, 'Service name must be at least 3 characters long')
            .max(50, 'Service name must be less than 50 characters'),
        price: Yup.number()
            .typeError('Price must be a valid number')
            .required('Price is required')
            .positive('Price must be a positive number')
            .min(0, 'Price must be a valid number'),
        selectedServiceTypes: Yup.array()
            .min(1, 'At least one service type must be selected')
            .required('Service type selection is required')
    });

    const onSavePress = async (values) => {

        setLoading(true);

        if (values.selectedServiceTypes.length == 0) {
            toast.error("Select atleast one service type");
            setLoading(false);
            return true;
        };

        if (duration === "" || duration === null || duration === undefined) {
            toast.error("Please select service duration");
            setLoading(false);
            return true;
        };

        if (!isMachineVisible && selectedEmplpoyeeList.length == 0) {
            toast.error("Select atleast one employee");
            setLoading(false);
            return true;
        };

        if (isGovtApprovedVisible && !isGovtApproved) {
            toast.error("Please select government approve");
            setLoading(false);
            return true;
        };

        if (isMachineVisible && isMachine && howManyMachine == '') {
            toast.error("Please enter machine number");
            setLoading(false);
            return true;
        };

        if (selectedEmplpoyeeList.some((item) => item.price === '')) {
            // toast.error("Please enter price to every employee");
            setShowError(true);
            setLoading(false);
            return true;
        } else {
            setShowError(false);
        };

        const formData = new FormData();

        var objDuration = null;
        try {
            objDuration = durationList.find(item => item.value === duration);
        } catch (e) {
            objDuration = null;
        };

        var serviceTypesId = [];
        if (values.selectedServiceTypes.length > 0) {
            serviceTypesId = values.selectedServiceTypes.map((item) =>
                typeof item === 'object' ? item.id : item
            );
        };

        var employeeListForSubmit = [];
        if (selectedEmplpoyeeList.length > 0) {
            employeeListForSubmit = selectedEmplpoyeeList.map(({ id, price }) => ({
                employee_id: id,
                price,
            }));
        };

        const sendData = {
            name: values.serviceName,
            group_id: group_id,
            description: serviceDesc,
            duration_text: objDuration.label,
            duration_min: objDuration.minutes,
            price: values.price,
            is_equipment_service: isMachineVisible,
            no_of_equipments: isMachineVisible && isMachine ? howManyMachine : 1,
            is_special: false,
            special_price: 0,
            service_type_id: typeof values.selectedServiceTypes[0] === 'object' ? values.selectedServiceTypes[0].id : values.selectedServiceTypes[0],
            service_type_ids: serviceTypesId,
            employees: !isMachineVisible ? employeeListForSubmit : [],
            group: 'abc',
            is_price_starts_with: isPriceStartFrom,
            is_consultation: isChecked,
            is_bundle_offer: isCheckedBundelOffer,
        };

        if (selectedItem) {
            sendData.remove_image = file === null ? true : false;
        };

        formData.append('req_body', JSON.stringify(sendData));

        if (file) {
            formData.append('image', file);
        };

        let API = null;
        let METHOD = '';
        if (selectedItem) {
            API = `${process.env.REACT_APP_URL}/api/v1/store/service/${selectedItem?.id}`;
            METHOD = 'PATCH';
        } else {
            API = `${process.env.REACT_APP_URL}/api/v1/store/service`;
            METHOD = 'POST'
        }

        try {
            const response = await axios({
                method: METHOD,
                url: API,
                headers: {
                    Authorization: `Bearer ${authTokenUser}`,
                },
                data: formData,
            });

            if (response) {
                if (type == 'create') {
                    toast.success("Service created successfully!");
                } else {
                    toast.success("Service updated successfully!");
                };

                if (formikRef.current) {
                    formikRef.current.resetForm()
                };
                resetFields('Callapi');
                // setTimeout(() => {
                //     handleClose();
                //     if (formikRef.current) {
                //         formikRef.current.resetForm()
                //     };
                //     resetFields('Callapi');
                // }, 2000);
            } else {
                console.log(response)
            }
        } catch (error) {
            setLoading(false);
            console.log('Error during API call:', error);
        }
    };

    const resetFields = (command) => {
        setGroup_id(null);
        // setSelectedServiceTypes([]);
        setServiceDesc('');
        setDuration(null);
        setIsPriceStartFrom(false);
        setFile(null);
        setSelectedEmplpoyeeList([]);
        setisMachine(false);
        setHowManyMachine('');
        setIsChecked(false);
        setIsCheckedBundelOffer(false);
        setLoading(false);
        handleClose(command);
    };


    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const handleNoOfPeople = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setHowManyMachine(value)
    };


    return (

        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ scrollbarWidth: 'none' }}
            PaperProps={{ sx: { borderRadius: "25px" } }}
        >
            <DialogTitle id="scroll-dialog-title"></DialogTitle>
            <DialogContent sx={{ scrollbarWidth: 'none' }}>



                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        serviceName: type == 'create' ? '' : selectedItem?.name || '',
                        price: type == 'create' ? '' : selectedItem?.price || '',
                        selectedServiceTypes: type == 'create' ? [] : selectedItem?.service_type_ids || [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSavePress(values);
                    }}
                >
                    {({ touched, errors, handleSubmit, setFieldValue, values }) => (

                        <Form onSubmit={handleSubmit}>

                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', marginTop: '20px' }}>

                                <Box style={{ display: 'flex', flexDirection: 'column', width: '48%' }} rowGap={0}>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', }}>
                                        <img
                                            src={serviceNameIcon}
                                            style={{ width: 22, height: 22, marginRight: 10 }}
                                            alt=""
                                        />
                                        {/* <SecondaryHeading fontColor='#6F6F6F' text={'Name of service'} /> */}
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Name of service</Typography>
                                    </Box>
                                    <Field
                                        name="serviceName"
                                        type="text"
                                        placeholder="Enter service name"
                                        style={{
                                            height: 40,
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',
                                            marginTop: 5
                                        }}
                                    />
                                    {touched.serviceName && errors.serviceName && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.serviceName}
                                        </div>
                                    )}

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                                        <img
                                            src={ServiceTypeIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                        {/* <SecondaryHeading fontColor='#6F6F6F' text={'Service type'} /> */}
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service type</Typography>

                                    </Box>

                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, width: '100%', justifyContent: 'space-between' }}>
                                        {serviceTypeButtonsOption.slice(0, 6).map(item => (
                                            <Paper
                                                key={item.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    padding: 1,
                                                    borderRadius: '12px',
                                                    height: 30,
                                                    width: '50%',
                                                    backgroundColor: selectedServiceTypeButtonsOption === item.name ? '#44B904' : 'white',
                                                    color: selectedServiceTypeButtonsOption === item.name ? 'white' : 'black',
                                                    flex: '1 1 calc(50% - 10px)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: selectedServiceTypeButtonsOption === item.name ? `1px solid #44B904` : `1px solid #A79C92`,
                                                }}
                                                elevation={0}
                                                onClick={() => handleRowClick(item.name)}
                                            >
                                                <Typography variant="body1" textAlign="center"
                                                    sx={{
                                                        fontWeight: 500, fontSize: 13, color: selectedServiceTypeButtonsOption === item.name ? 'white' : '#A79C92',
                                                        lineHeight: 1.2,
                                                    }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        ))}
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Select the different service types your treatment includes.</Typography>

                                        {/* <SecondaryHeading fontColor='#6F6F6F' text={'Select the different service types your treatment includes.'} /> */}
                                    </Box>

                                    <Box style={{
                                        display: 'flex', flexDirection: "column", overflowY: 'auto',
                                        justifyContent: "flex-start", alignItems: 'center', width: '100%', height: '320px',
                                        marginTop: 10, borderRadius: '12px', border: '1px solid #A79C92',
                                    }}>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                                backgroundColor: 'white',
                                                width: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CustomSearchBar
                                                width={'95%'}
                                                height={40}
                                                value={serviceTypeSearch}
                                                onChange={(e) => updateServiceTypeSearch(e.target.value)}
                                            />
                                        </Box>

                                        <List
                                            style={{
                                                width: '100%',
                                                overflowY: 'scroll',
                                                scrollbarWidth: 'none',
                                            }}
                                        >
                                            {serviceTypeFilterList.map((service) => (
                                                <React.Fragment key={service.id} >
                                                    <ListItem style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleToggle(service, setFieldValue, values)}>
                                                        {/* <ListItemText> */}
                                                        <Typography sx={{ color: '#545454', fontWeight: 400, fontSize: '15px' }}>
                                                            {service.label}
                                                        </Typography>
                                                        {/* </ListItemText> */}

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    checked={values.selectedServiceTypes.some((selectedItem) => {
                                                                        if (typeof selectedItem === 'object' && selectedItem !== null) {
                                                                            return selectedItem.id === service.id;
                                                                        }
                                                                        return selectedItem === service.id;
                                                                    })}
                                                                    // onChange={() => handleToggle(service)}
                                                                    onChange={() => handleToggle(service, setFieldValue, values)}
                                                                    sx={{
                                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                                            backgroundColor: 'transparent',
                                                                            borderColor: '#A79C92',
                                                                            color: 'green',
                                                                        },
                                                                        mt: 0, mb: 0
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </ListItem>
                                                    <Divider sx={{ borderColor: '#A79C92', margin: '3px 0', borderBottomWidth: 1, marginLeft: 2, marginRight: 2 }} />
                                                </React.Fragment>
                                            ))}
                                        </List>

                                    </Box>

                                    {touched.selectedServiceTypes && errors.selectedServiceTypes && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.selectedServiceTypes}
                                        </div>
                                    )}

                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column', width: '48%', justifyContent: 'flex-start', alignItems: 'flex-start' }} sx={{ mt: 0, pt: 0 }} rowGap={0}>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                                        <img
                                            src={DescriptionIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service description (optional)</Typography>

                                    </Box>
                                    <TextareaAutosize
                                        minRows={3}
                                        maxRows={6}
                                        value={serviceDesc}
                                        onChange={(e) => setServiceDesc(e.target.value)}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',
                                            resize: 'none',
                                            marginTop: 5
                                        }}
                                    />

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                        <img
                                            src={DurationIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />

                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Duration</Typography>

                                    </Box>
                                    <CustomSelect
                                        value={duration}
                                        onChange={e => setDuration(e.target.value)}
                                        options={durationList}
                                        sx={{ width: '100%', mt: 0.5, height: 40 }}
                                        borderColor="#A79C92"
                                        borderThickness="1px"
                                        noLabel
                                    />

                                    <Stack flex={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%', mt: 2, mb: 0.8 }}>
                                        <Stack flex={0.9} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                            <img
                                                src={cashIcon}
                                                style={{ width: 20, height: 20, marginRight: 10 }}
                                                alt=""
                                            />

                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Price (kr.)</Typography>
                                        </Stack>

                                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Checkbox
                                                checked={isPriceStartFrom}
                                                onChange={handleChangePriceStartFrom}
                                                sx={{
                                                    // '& .MuiSvgIcon-root': {
                                                    //     border: '0.1px solid #A79C92',
                                                    //     borderRadius: '4px',
                                                    // },
                                                    '&.Mui-checked .MuiSvgIcon-root': {
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#A79C92',
                                                        color: 'green',
                                                    },
                                                    p: 0
                                                    // '&:hover': {
                                                    //     backgroundColor: 'transparent',
                                                    // },
                                                }}
                                            />

                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Starting from</Typography>

                                        </Stack>
                                    </Stack>
                                    <Field
                                        name="price"
                                        type="text"
                                        placeholder="Enter price"
                                        inputMode="decimal"
                                        onKeyPress={(e) => {
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const newPrice = e.target.value;
                                            setFieldValue('price', newPrice);
                                            setStandardPrice(newPrice);
                                        }}
                                        value={values.price}
                                        style={{
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',

                                            height: 40,
                                            width: '100%'
                                        }}
                                    />
                                    {touched.price && errors.price && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.price}
                                        </div>
                                    )}

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                        <img
                                            src={cameraIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service picture (optional)</Typography>

                                    </Box>

                                    {!file && (
                                        <label
                                            htmlFor="file-upload"
                                            style={{
                                                display: 'inline-block',
                                                padding: '10px',
                                                border: '1px solid #6F6F6F',
                                                width: '137px',
                                                height: 40,
                                                borderRadius: '12px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#f5f5f5',
                                                cursor: 'pointer',
                                                transition: 'border-color 0.3s',
                                                marginTop: '6px'
                                            }}
                                        >
                                            <span style={{ fontWeight: 400, fontSize: '15px', color: '#6F6F6F' }}>Upload a photo</span>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="file-upload"
                                                onChange={handleFileChange}
                                                style={{
                                                    display: 'none',
                                                }}
                                            />
                                        </label>
                                    )}

                                    {file && (
                                        <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
                                            <>
                                                <Paper
                                                    elevation={0}
                                                    style={{
                                                        width: '137px',
                                                        height: 40,
                                                        borderRadius: '12px',
                                                        border: '1px solid #367B3D',
                                                        opacity: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '0 10px',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 400, fontSize: '15px', color: '#367B3D' }}>Uploaded!</span>
                                                </Paper>

                                                <IconButton onClick={handleRemoveFile} style={{ padding: 0 }} disableRipple>
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="Delete"
                                                        style={{ width: '17px', height: '19px', marginLeft: 20 }}
                                                    />
                                                </IconButton>
                                            </>
                                        </Stack>
                                    )}

                                    {isMachineVisible && (
                                        <>
                                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} onClick={() => setisMachine(!isMachine)}>
                                                <Checkbox
                                                    disableRipple
                                                    checked={isMachine}
                                                    onChange={() => setisMachine(!isMachine)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            border: '0.1px solid #A79C92',
                                                            borderRadius: '4px',
                                                        },
                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#A79C92',
                                                            color: 'green',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                        paddingLeft: 0
                                                    }}
                                                />

                                                <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Available to multiple clints at once</Typography>
                                            </Stack>


                                            {!isMachine && (
                                                <div style={{ marginTop: '10px' }}>
                                                    <SecondaryHeading fontColor='#6F6F6F' size={'15px'} text={'Please state the amount of clients this service will be available for at once.\nExample: If you have two machines to perform the service, you’ll have to enter 2'} />
                                                </div>
                                            )}

                                            {isMachine && (
                                                <div style={{ marginTop: '10px', width: '100%' }}>
                                                    <CustomTextField
                                                        value={howManyMachine}
                                                        onChange={(event) => handleNoOfPeople(event)}
                                                        placeholder={"Number of clients"}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}


                                    {/* {!isMachineVisible && employeesList.length > 0 && ( */}
                                    <>
                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                            {/* <SecondaryHeading fontColor='#6F6F6F' text={'Who offers the service?'} /> */}
                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Who offers the service?</Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                display: 'flex', flexDirection: "column", scrollbarWidth: 'none',
                                                justifyContent: "flex-start", alignItems: 'center', width: '100%',
                                                marginTop: '10px', borderRadius: '12px', boxShadow: '0px 4px 55px 0px #0000001A',
                                                paddingBottom: 10
                                            }}>

                                            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                                                <Box style={{ display: 'flex', justifyContent: 'flex-start', width: '15%', height: '175px', marginRight: 8 }}>
                                                    <img
                                                        src={AddEmployeeicon}
                                                        style={{ width: 35, height: 35, margin: 20, cursor: 'pointer' }}
                                                        alt=""
                                                        onClick={() => setShowEmployeeList(true)}
                                                    />
                                                </Box>

                                                <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} >
                                                    <Grid container spacing={0}>
                                                        {selectedEmplpoyeeList.map((employee) => (
                                                            <Grid item xs={12} sm={4} key={employee.id} sx={{ mt: 1 }}>
                                                                <Stack

                                                                    flex={1} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}
                                                                    sx={{ m: 0, p: 0 }}
                                                                // style={{
                                                                //     display: 'flex',
                                                                //     flexDirection: 'column',
                                                                //     alignItems: 'center',

                                                                //     boxSizing: 'border-box',
                                                                //     paddingTop:0,
                                                                //     marginTop:0,
                                                                //     backgroundColor:'blue'

                                                                // }}
                                                                >
                                                                    {/* <SecondaryHeading fontColor='#6F6F6F' text={employee.name} /> */}
                                                                    <Typography variant="caption" sx={{
                                                                        color: '#1f1f1f', width: '100%', textAlign: 'center', fontSize: 14, fontWeight: 600, textAlign: 'center', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box",
                                                                        WebkitLineClamp: "2",
                                                                        WebkitBoxOrient: "vertical", lineHeight: 1.3
                                                                    }}>
                                                                        {employee.name}
                                                                    </Typography>

                                                                    <CustomTextField
                                                                        value={employee.price}
                                                                        onChange={(e) => handlePriceChange(employee.id, e.target.value)}
                                                                        width={'80%'}
                                                                        height={40}
                                                                        onKeyPress={(e) => {
                                                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                                                            if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        lineHeight={0.2}
                                                                        mt={0.4}
                                                                        pt={0}
                                                                        // placeholder={'Price'}
                                                                        placeholderFontSize={14}
                                                                        inputFontSize={14}
                                                                    // sx={{ pl:1,pt:0 }}

                                                                    />
                                                                </Stack>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Stack>
                                            </Box>

                                        </Box>

                                        {showError && (
                                            <div style={{ color: 'red', fontSize: '12px' }}>
                                                {'Please enter valid price for employee'}
                                            </div>
                                        )}
                                    </>
                                    {/* )} */}

                                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            disabled={isLoading}
                                            style={{
                                                backgroundColor: '#44B904',
                                                color: 'white',
                                                border: 0,
                                                borderRadius: '12px',
                                                width: '100%',
                                                height: '40px',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                position: 'relative',
                                            }}
                                        >
                                            {isLoading ? (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                        zIndex: 1,
                                                    }}
                                                />
                                            ) : (
                                                <span style={{ zIndex: 2 }}>
                                                    {type === 'create' ? 'Create service' : 'Update service'}
                                                </span>
                                            )}
                                        </button>
                                    </Box>

                                </Box>

                            </Box>

                        </Form>
                    )}

                </Formik>

                {showEmployeeList &&
                    <Modal
                        open={showEmployeeList}

                        // onClose={() => setShowEmployeeList(false)}
                        onClose={() => { }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={[style, {
                            zIndex: 12,
                            justifyContent: 'center', alignItems: 'center',
                            overflowY: { xs: 'auto', sm: '85vh' },
                            overflowX: 'hidden',
                            maxHeight: { xs: '90vh', sm: '85vh' },
                            position: 'relative',
                            padding: 4,
                            maxWidth: '60vh',
                            borderRadius: '25px'
                        }]}>

                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={() => setShowEmployeeList(false)}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '30px',
                                        zIndex: 10,
                                        color: 'black'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {/* <PrimaryHeading fontColor='##1f1f1f' text={'Employee list'}  sx={{textAlign:'center', }}/> */}
                            <Typography variant='h6' sx={{ fontWeight: 700, color: '#1f1f1f', textAlign: 'center' }}>
                                Employee list
                            </Typography>

                            <Box style={{
                                display: 'flex', flexDirection: "column", overflowY: 'auto',
                                justifyContent: "flex-start", alignItems: 'center', width: '100%', height: '500px',
                                marginTop: '10px', borderRadius: '12px', border: '1px solid #A79C92',
                            }}>

                                {/* <CustomSearchBar
                                    width={'98%'}
                                    value={employeeSearch}
                                    onChange={(e) => updateEmployeeSearch(e.target.value)}
                                /> */}

                                <List style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                                    {filterList.map((employee) => (
                                        <Stack key={employee.id} sx={{ cursor: 'pointer' }} onClick={() => addEmployee(employee)}>
                                            {/* <React.Fragment key={employee.id}> */}
                                            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <ListItemText>
                                                    <Typography variant='body1' sx={{ color: '#545454', fontWeight: 400 }}>
                                                        {employee.name}
                                                    </Typography>
                                                </ListItemText>

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disableRipple
                                                            checked={selectedEmplpoyeeList.some((el) => el.id === employee.id)}
                                                            onChange={() => addEmployee(employee)}
                                                            sx={{
                                                                // '& .MuiSvgIcon-root': {
                                                                //     border: '0.1px solid #A79C92',
                                                                //     borderRadius: '4px',
                                                                // },
                                                                '&.Mui-checked .MuiSvgIcon-root': {
                                                                    backgroundColor: 'transparent',
                                                                    borderColor: '#A79C92',
                                                                    color: 'green',
                                                                },
                                                                // '&:hover': {
                                                                //     backgroundColor: 'transparent',
                                                                // },
                                                            }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </ListItem>
                                            <Divider sx={{ borderColor: '#A79C92', margin: '3px 0', borderBottomWidth: 1, marginLeft: 2, marginRight: 2 }} />
                                            {/* </React.Fragment> */}
                                        </Stack>
                                    ))}
                                </List>

                            </Box>

                        </Box>

                    </Modal>
                }


            </DialogContent>

        </Dialog>



    )
}

export default ServiceModal;
