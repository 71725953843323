import React, { useEffect, useState } from "react";
import { Box, Tooltip, Stack, Divider, Switch, Typography } from "@mui/material";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import CustomSelect from "../commonCustomSelect";
import TooltipIcon from '../../../assets/IconTooltip.png';
import { useFormik } from "formik";
import CommonButton from "../commonButton";
import _ from "lodash"
import { toast, ToastContainer } from "react-toastify";
import apiFetcher from "../../../utils/interCeptor";
import FSwitch from "../../commonComponents/f-switch";
const options = [
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 60, label: '1 hours' },
    { value: 120, label: '2 hours (standard)' },
];

const optionsAfter = [
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 60, label: '1 hours' },
    { value: 120, label: '2 hours (standard)' },
];

const calendarIntervals = [
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min (standard)' },
    { value: 20, label: '20 min' },
];



// const initialValues = {
//     "setCalendarOpeningHour": 120,
//     "setCalendarClosingHour": 120,
//     "grayOutClosedHours": false,
//     "showOnlyAvailableEmployee": false,
//     "calendarInterval": 15
// }

const CalendarSettingsOption = () => {
    const [enableSave, setEnableSave] = useState(false)
    const [initialValues, setInitialValues] = useState({
        "setCalendarOpeningHour": 120,
        "setCalendarClosingHour": 120,
        "grayOutClosedHours": false,
        "showOnlyAvailableEmployee": false,
        "calendarInterval": 15
    })

    const tooltipContent = (
        <div style={{ padding: '5px' }}>content here</div>
    );


    const formik = useFormik({
            enableReinitialize: true,
            initialValues,
            onSubmit: values => {
                calendarAPI(values)
       
            },
          });

        const calendarAPI=async (value)=>{

        try{

            const payload = {
                settings:[
                {
                    "settingCategory":"outlet",
                    "settingName":"calendar",
                    value: JSON.stringify(value),
                    "type": "JSON"
                }
                ]

            }
            const response =  await apiFetcher.patch('/api/v1/store/outlet/setting', payload)
            console.log('response', JSON.stringify(value));
            const {success, data} = response.data
            if(success){
                toast.success(`Calendar settings updated`);
                getCalendarsettingsAPI()
            }

            setEnableSave(false)

            formik.setSubmitting(false)
            
        }catch(err){
            toast.error(`Failed to update calendar settings`);
            console.log('err',err);
            formik.setSubmitting(false)
            
        }
    }
    

    const getCalendarsettingsAPI=async ()=>{

        try{

            
            const response =  await apiFetcher.get('/api/v1/store/outlet/setting')
        
            const {success, data} = response.data
            if(success){

                const {settings} = data
                const calendarSettings = settings.find((settingObj)=>settingObj.settingName == 'calendar')
                if(calendarSettings){
                    console.log('calendarSettings', calendarSettings?.value);
                    
                    setInitialValues(JSON.parse(calendarSettings?.value))
                }
            
            }
            
        }catch(err){
            console.log('err',err);
            
        }
    }
   
    useEffect(()=>{
        getCalendarsettingsAPI()
    },[])

    useEffect(() => {
           console.log('formik.vlues', formik.values);
           setEnableSave( !_.isEqual(initialValues, formik.values) )
        }, [formik.values]);

    return (
        <form onSubmit={formik.handleSubmit}>
        <Stack sx={{paddingRight:4, paddingLeft:4, marginBottom:10}}>
            {/* <Box sx={{ width: '100%', padding: 5 }} /> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', paddingTop:2, paddingBottom:2}}>
            {enableSave &&
                
             <CommonButton
                type="submit"
                width="auto"
                height={40}
                title={'Save changes'}
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                />
            }   
            </Box>
            <ToastContainer />
       
            <Box sx={{ display: "flex", justifyContent: "center",  height: "82vh",
            overflowY:'auto',
            overflowX:'hidden'  }}>

                <Box sx={{ display: "flex", bgcolor: '#FFFFFF', borderRadius: '25px', flexDirection: 'column', width: '100%',  padding: 5,  }}>

                    <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                            <PrimaryHeading text={'Calendar appearance'} />
                            <SecondaryHeading text={'Here you can change the appearance of the calendar.'} />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%' }}>
                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                               
                                <Typography variant='body1' sx={{  fontWeight: 700, color: '#1F1F1F' }}>
                                     {'How many hours before opening hour, should the calendar start'}
                                    </Typography>
                                <Tooltip arrow title={tooltipContent} sx={{ml:10}}>
                                    <img
                                        src={TooltipIcon}
                                        alt="IconOne"
                                        style={{ marginLeft: 4, width: 14, height: 14 }}
                                    />
                                </Tooltip>
                            </Stack>
                            <CustomSelect
                                id={'setCalendarOpeningHour'}
                                name={'setCalendarOpeningHour'}
                                value={formik.values.setCalendarOpeningHour}
                                onChange={formik.handleChange}
                                options={options}
                                sx={{ width: '30%', mt:1 }}
                            />

                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                <Typography variant='body1' sx={{  fontWeight: 700, color: '#1F1F1F' }}>
                                {'How many hours after opening hour, should the calendar stop'}
                                    </Typography>
                                <Tooltip arrow title={tooltipContent} sx={{ml:10}}>
                                    <img
                                        src={TooltipIcon}
                                        alt="IconOne"
                                        style={{ marginLeft: 4, width: 14, height: 14 }}
                                    />
                                </Tooltip>
                            </Stack>
                            <CustomSelect
                              id={'setCalendarClosingHour'}
                              name={'setCalendarClosingHour'}
                              value={formik.values.setCalendarClosingHour}
                              onChange={formik.handleChange}
                             options={optionsAfter}
                              sx={{ width: '30%', mt:1 }}
                            />

                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }} />
                           
                            <Typography variant='body1' sx={{  fontWeight: 700, color: '#1F1F1F' }}>
                                {"Grey out closed hours"} 
                                    </Typography>
                            <FSwitch
                                id={'grayOutClosedHours'}
                                name={'grayOutClosedHours'}
                                checked={formik.values.grayOutClosedHours}
                                onChange={formik.handleChange}
                               
                            />

                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }} />
                            
                            <Typography variant='body1' sx={{  fontWeight: 700, color: '#1F1F1F' }}>
                                {"Show only employees who are at work"} 
                                    </Typography>
                            <FSwitch
                                 id={'showOnlyAvailableEmployee'}
                                 name={'showOnlyAvailableEmployee'}
                                 checked={formik.values.showOnlyAvailableEmployee}
                                 onChange={formik.handleChange}

                            />

                        </Box>

                    </Box>
                    <Divider sx={{ marginTop: 5, marginBottom: 5, border: '2.5px solid #D9D9D9', backgroundColor: '#F3F3F3', width: '100%' }} />

                    <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                            <PrimaryHeading text={'Calendar interval'} />
                            <SecondaryHeading text={'Here you can change the interval in calendar appearence.'} />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%' }}>
                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography variant='body1' sx={{  fontWeight: 700, color: '#1F1F1F' }}>
                                      {'Calendar interval'}
                                    </Typography>
                                <Tooltip arrow title={tooltipContent} sx={{ml:10}}>
                                    <img
                                        src={TooltipIcon}
                                        alt="IconOne"
                                        style={{ marginLeft: 4, width: 14, height: 14 }}
                                    />
                                </Tooltip>
                            </Stack>
                            <CustomSelect
                                 id={'calendarInterval'}
                                 name={'calendarInterval'}
                                 value={formik.values.calendarInterval}
                                //  onChange={(event)=>onChangeValue('calendarInterval', event)}
                                 onChange={formik.handleChange}
                                options={calendarIntervals}
                                sx={{ width: '30%', mt:1 }}
                            />
                        </Box>
                    </Box>

                </Box>

            </Box>
          
    </Stack>
    </form>
    )
};

export default CalendarSettingsOption;