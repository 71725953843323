import React, { useEffect } from "react";
import classNames from "classnames";

import { Handle, Remove } from "./components";

import styles from "./Item.module.scss";
import { Stack, Typography } from "@mui/material";
import { formatAmount } from "../../../../utils/format-amout";
import CountdownTimer from "../../../specialOffer/liveCountDownComponent";



export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        data,
        onclickItem,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          data
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(", "),
              "--translate-x": transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              "--translate-y": transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              "--scale-x": transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              "--scale-y": transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              "--index": index,
              "--color": color
            }
          }
          ref={ref}
        >


          {
            data?.service ?
              <Stack  flex={1} width={'100%'} flexDirection={'row'} justifyContent={'space-between'} sx={{border: "1.5px solid #D9D9D9", borderRadius:'15px',  paddingLeft:2, paddingRight:2}} className={classNames(
                styles.Item,
                dragging && styles.dragging,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
              )}
              >
                  <Stack flex={0.4} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                  {handle ? <Handle {...handleProps} {...listeners}  item ={true}/> : null}
                    <Typography variant="body1" color="black" sx={{ml:2}} > {data?.service?.name}</Typography>
                  </Stack>
                
                  <Stack flex={0.15} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                  <CountdownTimer createdAt={data?.service.created_at} />
                    </Stack>

                    <Stack flex={0.15} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                     <Typography sx={{
                                                                          //  width: 150,
                                                                           fontWeight: 400,
                                                                           fontSize: '20px',
                                                                           color: '#D30000',
                                                                           marginLeft: 10,
                                                                       }}>
                                                                           {`${data?.service?.special_percentage}%`}
                                                                       </Typography>
                    </Stack>

             
                    <Stack  flex={0.15} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                
                 <Typography sx={{
                                                                        // width: 140,
                                                                        fontWeight: 400,
                                                                        fontSize: '20px',
                                                                        color: '#A0A0A0',
                                                                        marginLeft: 2,
                                                                        textDecorationLine: 'line-through',
                                                                    }}>
                                                                        {`${data.service?.price}kr.`}
                                                                    </Typography>
                  </Stack>
                  <Stack  flex={0.15} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    {/* <Typography variant="body1" color="black" sx={{ml:2}}> {formatAmount(data?.price)}</Typography> */}
                      <Typography sx={{
                                                                            // width: 140,
                                                                            fontWeight: 400,
                                                                            fontSize: '20px',
                                                                            color: '#367B3D',
                                                                            marginLeft: 2,
                                                                        }}>
                                                                            {`${data.service?.special_price}kr.`}
                                                                        </Typography>
                  </Stack>
                  {onRemove ? (
                    <Remove className={styles.Remove} onClick={onRemove} />
                  ) : null}
              
                </Stack>
              :
                <Stack  flex={1} width={'100%'} flexDirection={'row'} justifyContent={'space-between'} sx={{border: "1.5px solid #D9D9D9", borderRadius:'15px',  paddingLeft:2, paddingRight:2, pt:0}} className={classNames(
                  styles.Item,
                  dragging && styles.dragging,
                  handle && styles.withHandle,
                  dragOverlay && styles.dragOverlay,
                  disabled && styles.disabled,
                )}>
                    <Stack flex={0.5} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    {handle ? <Handle {...handleProps} {...listeners}  item ={true}/> : null}
                    <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ height:'40px', cursor:'pointer'}} onClick={()=>onclickItem(data)}>

                      
                      <Typography variant="body1" color="black" sx={{pl:2,  width:'100%'}}> {data?.name}</Typography>
                    </Stack>
                    </Stack>
                   
                  
                      <Stack flex={0.25} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ height:'40px', cursor:'pointer'}} onClick={()=>onclickItem(data)}>
                        <Typography variant="body1" color="black" sx={{ml:2}}> {data?.duration_text}</Typography>
                        </Stack>
                      <Stack  flex={0.25} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ height:'40px', mr:2, cursor:'pointer'}}  onClick={()=>onclickItem(data)}>
                        <Typography variant="body1" color="black" sx={{ml:2}}> {formatAmount(data?.price)}</Typography>
                      </Stack>
                    {onRemove ? (
                      <Remove className={styles.Remove} onClick={onRemove} />
                    ) : null}
                
                  </Stack>
              }


       
        </li>
      );
    }
  )
);
