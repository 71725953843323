import { Button, createTheme, ThemeProvider, Typography } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 20px",
          textTransform: "none",
          opacity: "0px",
          borderRadius: "25px",
        },
      },
      variants: [
        {
          props: { variant: "delete" },
          style: {
            backgroundColor: "#C74141",
            fontWeight: 700,
            color: "white",
          },
        },
        {
          props: { variant: "save" },
          style: {
            backgroundColor: "#44B904",
            fontWeight: 700,
            color: "white",
          },
        },
        {
          props: { variant: "f_outline" },
          style: {
            border: "1px solid #A19D99",
            fontWeight: 700,

            color: "#BBB0A4",
            backgroundColor: "#fff",
          },
        },
      ],
    },
  },
});

const FButton = ({
  title,
  sx,
  variant,
  height,
  width,
  style,
  backgroundColor,
  onClick,
  titleColor,
  loading,
  disabled,
  className,
  btnVar,
  type,
  icon,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        disableRipple
        variant={variant}
        type={type}
        fullWidth={"160px"}
        style={style}
        height={height || "52px"}
        className={className}
        width={width || "257px"}
        backgroundColor={backgroundColor}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        sx={sx}
      >
        <Typography
          variant={btnVar ? btnVar : "body1"}
          sx={{
            fontWeight: 700,
            paddingLeft: 2,
            paddingRight: 2,
            color: titleColor,
          }}
        >
          {icon && <span style={{ marginRight: "10px" }}><img src={icon} alt="icon"/></span>}
          {title || "Custom Button"}
        </Typography>
      </Button>
    </ThemeProvider>
  );
};

export default FButton;
