import React, { useEffect, useState } from "react";
import upload from "../../../assets/Group.png";
import "./index.css";

import Masonry from "@mui/lab/Masonry";
import {
  Box,
  Typography,
  Grid2,
  CardContent,
  IconButton,
  Stack,
  Divider,
  TextareaAutosize,
  Button,
  Checkbox,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomTextField from "../commonTextinput";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import { Add } from "@mui/icons-material";
import { useFormik } from "formik";
import { generalSettingsValidate } from "./validations";
import apiFetcher from "../../../utils/interCeptor";
import { toast, ToastContainer } from "react-toastify";
import CommonButton from "../commonButton";
import _ from "lodash";
import { Loader2Icon } from "lucide-react";
import F_imageUpload from "../../commonComponents/F_imageUpload";
import FimageUpload from "../../commonComponents/F_imageUpload";

const extractCoordinates = (geoLocation) => {
  if (!geoLocation) return { lng: "", lat: "" }; // Default values if geoLocation is missing

  const match = geoLocation.match(/POINT \(([-\d.]+) ([-\d.]+)\)/);
  if (match) {
    return {
      lng: parseFloat(match[1]), // Longitude
      lat: parseFloat(match[2]), // Latitude
    };
  }

  return { lng: "", lat: "" }; // Return empty values if format is incorrect
};

const GeneralSettingsOption = () => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    city: "",
    contact_number: "",
    contact_number2: "",
    about: "",
    cvr_number: "",
    lng: "",
    zip_code: "",
    lat: "",
    email: "",
    hide_email: false,
    website: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    profile_image: "",
    banner_image: "",
    images: [],
  });

  const [profile, setProfile] = useState("");
  const [Banner, setBanner] = useState("");
  const [files, setFiles] = useState([]);

  const [isChanges, setIsChanges] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiFetcher.get(
          "https://api.fiind.app/api/v1/store/setting/profile"
        );

        const getImg = await apiFetcher.get(
          "https://api.fiind.app/api/v1/store/gallery"
        );
        

        console.log("apiimg", getImg.data.data);
        const data = response.data.data;
        const mainResonse = { ...data, ...getImg.data.data };
        console.log("mainResonse", mainResonse);

        const images = mainResonse.images;
        const profile = mainResonse.profile_image;
        const banner = mainResonse.banner_image;

        setProfile(profile);
        setBanner(banner);
        setFiles(images);

        console.log("API Response:", data);

        const { lng, lat } = extractCoordinates(data.geo_location);

        setInitialValues((prevValues) => ({
          ...prevValues,
          ...mainResonse,
          lng,
          lat,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: generalSettingsValidate,
    onSubmit: (values) => {
      handlesubmit(values);
    },
  });

  useEffect(() => {
    setIsChanges(!_.isEqual(initialValues, formik.values));
  }, [formik.values]);

  //
  const handleCopy = () => {
    navigator.clipboard
      .writeText("fiind.app/popatbhai")
      .then(() => {
        alert("Text copied");
      })
      .catch((err) => {
        alert("Failed to copy text: " + err);
      });
  };

  const handleDrop = (setter) => (acceptedFiles) => {
    console.log(acceptedFiles);
    setter((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  // Generic Remove Handler
  const handleRemove = (setter) => {
    setter("");
  };

  const handleFileRemove = (file) => {
    formik.setFieldValue(
      "images",
      files.filter((f) => f !== file)
    );
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  // Dropzone Configuration for Profile Image
  const {
    getRootProps: getProfileRootProps,
    getInputProps: getProfileInputProps,
  } = useDropzone({
    onDrop: handleDrop(setProfile),
    accept: "image/*",
  });

  // Dropzone Configuration for Banner Image
  const {
    getRootProps: getBannerRootProps,
    getInputProps: getBannerInputProps,
  } = useDropzone({
    onDrop: handleDrop(setBanner),
    accept: "image/*",
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop(setFiles),
    accept: "image/*",
    multiple: true,
  });

  const handleInputFileChange = (event) => {
    const file = event.target.files[0];

    formik.setFieldValue("profile_image", file);
    setProfile(file);
  };

  const handleBannerInputFileChange = (event) => {
    const file = event.target.files[0];

    formik.setFieldValue("banner_image", file);
    setBanner(file);
  };

  const handleInputImageFileChange = (event) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        formik.setFieldValue("images", [...files, URL.createObjectURL(file)]);
        setFiles((prevFiles) => [...prevFiles, file]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlesubmit = async (values) => {
    console.log(values);

    try {
      if (_.isEqual(initialValues.images, formik.values.images)) {
        setIsChanges(false);
        const response = await apiFetcher.post(
          `https://api.fiind.app/api/v1/store/setting/profile`,
          values
        );
        console.log(response);
        const { success } = response.data;

        if (success) {
          toast.success(`General settings updated`);
          return;
        } else {
          toast.error(`Failed to update general settings`);
          return;
        }
      }
      setIsChanges(false);
      const response = await apiFetcher.post(
        `https://api.fiind.app/api/v1/store/setting/profile`,
        values
      );

      const formdata = new FormData();

      formdata.append("profile_image", profile);
      formdata.append("banner_image", Banner);

      formdata.append("images", files);

      console.log("formdata", formdata);

      await apiFetcher
        .post(`https://api.fiind.app/api/v1/store/gallery`, formdata)
        .then((res) => console.log("imagesres", res.data));

      // const data = await apiFetcher.get("https://api.fiind.app/api/v1/store/gallery").then((res) => console.log(res.data.data));

      const { success } = response.data;

      if (success) {
        toast.success(`General settings updated`);
      } else {
        toast.error(`Failed to update general settings`);
      }

      console.log("res", response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        onReset={() => {
          setFiles([]);
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handlesubmit(formik.values);
        }}
        style={{ display: "flex", justifyContent: "center" }}
      >
          <Stack sx={{ paddingRight: 4, paddingLeft: 4, marginBottom: 10, width:'100%' }}>
             <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', paddingTop: 2, paddingBottom: 2 }}>
                    {isChanges &&

                    <CommonButton
                      type="submit"
                      width="auto"
                      height={40}
                      title={"Save changes"}
                      loading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                    }
                </Box>
                <ToastContainer />
         
   <Stack spacing={1} sx={{ bgcolor: "#fff", borderRadius: "25px",    
                           height: "86vh",
                        //    overflowY:'auto',
                            scrollbarWidth:'none',
                           overflowX:'hidden' }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              px:5,
              pt:5,
              pb:5
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "33%" , pr:3}}
            >
              <PrimaryHeading text="Store Information" />
              <SecondaryHeading text="If you wish to change your clinic’s information, you can proceed here. Information provided here, will be shown on our marketplace." />
            </Box>

              <Stack flexDirection={'column'} width={'67%'} justifyContent={'flex-start'}>

                <Stack flexDirection={'row'} width={'100%'} justifyContent={'flex-start'} gap={6} mb={2} >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", width: "49%" }}
                  >
                    <CommonTypoGraphy text={"Name of your business"} />
                    <CustomTextField
                      {...formik.getFieldProps("name")}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      id={"name"}
                      name={"name"}
                    />

                    {formik.errors.name && formik.touched.name && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.name}
                      </Box>
                    )}

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text="Booking URL" />
                    <CustomTextField
                      value={"fiind.app/popatbhai"}
                      onFocus={(e) => e.target.select()}
                      onChange={() => { }}
                      showCopyButton
                      handleCopy={handleCopy}
                      bgColor={"#D9D9D9"}
                    />

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text="Business phone number" />
                    <CustomTextField
                      {...formik.getFieldProps("contact_number")}
                      value={formik.values.contact_number}
                      onChange={formik.handleChange}
                      name={"contact_number"}
                      id={"contact_number"}
                    />
                    {formik.errors.contact_number &&
                      formik.touched.contact_number && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.contact_number}
                        </Box>
                      )}

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text="Alternative phone number (optional)" />
                    <CustomTextField
                      value={formik.values.contact_number2}
                      {...formik.getFieldProps("contact_number2")}
                      onChange={formik.handleChange}
                      name={"contact_number2"}
                      id={"contact_number2"}
                    />

                    {formik.errors.contact_number2 &&
                      formik.touched.contact_number2 && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.contact_number2}
                        </Box>
                      )}

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text="Email" />
                    <CustomTextField
                      {...formik.getFieldProps("email")}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      name={"email"}
                      id={"email"}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.email}
                      </Box>
                    )}

                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      <Checkbox
                        {...formik.getFieldProps("hide_email")}
                        onChange={formik.handleChange}
                        name={"hide_email"}
                        id={"hide_email"}
                        checked={formik.values.hide_email}
                      />
                      <Typography
                        sx={{
                          height: 20,
                          fontWeight: 100,
                          fontFamily: "DM Sans",
                          marginTop: 1.15,
                        }}
                      >
                        Don’t share email address with customers
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    sx={{ display: "flex", flexDirection: "column", width: "49%" }}
                  >
                    <CommonTypoGraphy text="Street" />
                    <CustomTextField
                      {...formik.getFieldProps("address")}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      name={"address"}
                      id={"address"}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.address}
                      </Box>
                    )}

                    <Stack
                      style={{
                        display: "flex",
                        marginTop: 20,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <>
                        <Stack
                          style={{
                            display: "flex",
                            width: "150px",
                            flexDirection: "column",
                          }}
                        >
                          <CommonTypoGraphy text="Zip code" />
                          <CustomTextField
                            {...formik.getFieldProps("zip_code")}
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                            name={"zip_code"}
                            id={"zip_code"}
                          />

                          {formik.errors.zip_code && formik.touched.zip_code && (
                            <Box
                              component={"span"}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {formik.errors.zip_code}
                            </Box>
                          )}
                        </Stack>

                        <Stack
                          style={{
                            display: "flex",
                            marginLeft: 10,
                            flexDirection: "column",
                          }}
                        >
                          <CommonTypoGraphy text="City" />
                          <CustomTextField
                            {...formik.getFieldProps("city")}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            name={"city"}
                            id={"city"}
                          />
                          {formik.errors.city && formik.touched.city && (
                            <Box
                              component={"span"}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {formik.errors.city}
                            </Box>
                          )}
                        </Stack>
                      </>
                    </Stack>

                    <Stack
                      style={{
                        display: "flex",
                        marginTop: 20,
                        flexDirection: "column",
                      }}
                    >
                      <>
                        <CommonTypoGraphy text={"Marketplace pointer"} />
                        <Stack style={{ display: "flex", flexDirection: "row" }}>
                          <Stack
                            style={{
                              display: "flex",
                              width: "203px",
                              flexDirection: "column",
                            }}
                          >
                            <SecondaryHeading text="Latitude" />
                            <CustomTextField
                              {...formik.getFieldProps("lat")}
                              value={formik.values.lat}
                              onChange={formik.handleChange}
                              name={"lat"}
                              id={"lat"}
                            />
                            {formik.errors.lat && formik.touched.lat && (
                              <Box
                                component={"span"}
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                {formik.errors.lat}
                              </Box>
                            )}
                          </Stack>

                          <Stack
                            style={{
                              display: "flex",
                              marginLeft: 10,
                              flexDirection: "column",
                              width: "203px",
                            }}
                          >
                            <SecondaryHeading text="Longitude" />
                            <CustomTextField
                              {...formik.getFieldProps("lng")}
                              value={formik.values.lng}
                              onChange={formik.handleChange}
                              name={"lng"}
                              id={"lng"}
                            />
                            {formik.errors.lng && formik.touched.lng && (
                              <Box
                                component={"span"}
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                {formik.errors.lng}
                              </Box>
                            )}
                          </Stack>
                        </Stack>
                      </>

                      <Stack style={{ marginTop: 95 }} />
                      <CommonTypoGraphy text="CVR number" />
                      <CustomTextField
                        {...formik.getFieldProps("cvr_number")}
                        value={formik.values.cvr_number}
                        onChange={formik.handleChange}
                        name={"cvr_number"}
                        id={"cvr_number"}
                      />
                      {formik.errors.cvr_number && formik.touched.cvr_number && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.cvr_number}
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Stack>
                
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box style={{ width: "100%" }}>
                    <CommonTypoGraphy text="About us" />
                    <TextareaAutosize
                      {...formik.getFieldProps("about")}
                      minRows={10}
                      maxRows={50}
                      value={formik.values.about}
                      onChange={formik.handleChange}
                      name="about"
                      id="about"
                      style={{
                        width: "100%",
                        height: "20px",
                        padding: "10px",
                        fontSize: "16px",
                        borderRadius: "10px",
                        border: "2px solid #D9D9D9",
                        outline: "none",
                        resize: "none",
                        marginTop: 10,
                      }}
                    />
                    {formik.errors.about && formik.touched.about && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.about}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Stack>
          </Box>

        
          <Divider
            sx={{
              marginTop: 5,
              marginBottom: 5,
              border: "2.5px solid #D9D9D9",
              backgroundColor: "#F3F3F3",
              width: "100%",
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              px:5,
              pt:2,
              pb:5
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "33%" }}
            >
              <PrimaryHeading text="Marketplace links" />
              <SecondaryHeading text="Here, you can add your website and social media links for customers to view on the marketplace." />
            </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', width: '67%', }} gap={2}>

                <Box
                  sx={{ display: "flex", flexDirection: "column", width: "49%" }}
                >
                  <CommonTypoGraphy text="Website link" />
                  <CustomTextField
                    {...formik.getFieldProps("website")}
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    id={"website"}
                    name={"website"}
                  />
                  {formik.errors.website && formik.touched.website && (
                    <Box
                      component={"span"}
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {formik.errors.website}
                    </Box>
                  )}

                  <Stack style={{ marginTop: 20 }} />
                  <CommonTypoGraphy text="TikTok link" />
                  <CustomTextField
                    {...formik.getFieldProps("tiktok")}
                    id={"tiktok"}
                    name={"tiktok"}
                    value={formik.values.tiktok}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.tiktok && formik.touched.tiktok && (
                    <Box
                      component={"span"}
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {formik.errors.tiktok}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", width: "49%" }}
                >
                  <CommonTypoGraphy text="Instagram link" />
                  <CustomTextField
                    id={"instagram"}
                    name={"instagram"}
                    {...formik.getFieldProps("instagram")}
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.instagram && formik.touched.instagram && (
                    <Box
                      component={"span"}
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {formik.errors.instagram}
                    </Box>
                  )}

                  <Stack style={{ marginTop: 20 }} />
                  <CommonTypoGraphy text="Facebook link" />
                  <CustomTextField
                    id="facebook"
                    name={"facebook"}
                    {...formik.getFieldProps("facebook")}
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.facebook && formik.touched.facebook && (
                    <Box
                      component={"span"}
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {formik.errors.facebook}
                    </Box>
                  )}
                </Box>
              </Box>

          </Box>

          <Divider
            sx={{
              marginTop: 5,
              marginBottom: 5,
              border: "2.5px solid #D9D9D9",
              backgroundColor: "#F3F3F3",
              width: "100%",
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              minHeight: "200px",
              px:5,
              pt:2,
              pb:5
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "33%" }}
            >
              <PrimaryHeading text={"Gallery"} />
              <SecondaryHeading
                text={
                  "Here you can upload, delete and sort your gallery. Sort by drag and dropping."
                }
              />
            </Box>

            <Box
              style={{ width: "67%", display: "flex", flexDirection: "column" }}
            >
            <Box
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "50%" }}>
                <CommonTypoGraphy text="Profile picture" />
                <Grid2
                  container
                  marginLeft={-2.2}
                  spacing={2}
                  sx={{ marginTop: "20px" }}
                >
                 

                <FimageUpload formik={formik} setter={setProfile} id={"profile_image"} field={profile}/>
                </Grid2>

             
              </div>

              <div  style={{  marginLeft:"10px" , width: "50%" }}>
                <CommonTypoGraphy text="Banner picture" />
                <Grid2 container spacing={2} style={{ marginTop: "20px" , marginLeft:"-16px" }}>
                <FimageUpload  formik={formik} setter={setBanner} id="banner_image" field={Banner} />
                </Grid2>
              </div>
            </Box>

              
            <Stack mt={5}/>
                <CommonTypoGraphy text="Gallery" />

                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    zIndex: 1,
                  }}
                >
                  <Masonry columns={3} spacing={2}>
                    {formik.values.images.map((item, index) => (
                      <Box
                        component={"div"}
                        key={index}
                        sx={{
                          height: "auto",
                          zIndex: 1,
                          width: "50%",
                        }}
                      >
                        <Stack
                          component={"img"}
                          // src={URL.createObjectURL(item)}
                          src={
                            item && typeof item.image === "string"
                              ? `${process.env.REACT_APP_IMG_URL}${item.image}`
                              : item instanceof Blob || item instanceof File
                              ? URL.createObjectURL(item)
                              : ""
                          }
                          alt="img"
                          sx={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            borderRadius: "12px",
                            display: "block",
                            width: "95%",
                            border: "5px solid transparent",
                            transition: "border-color 0.3s ease", // Smooth hover transition
                            "&:hover": {
                              borderColor: "rgb(60, 155, 234)",
                            },
                          }}
                          loading="lazy"
                        />

                        <CardContent>
                          <IconButton
                            onClick={() => handleFileRemove(item)}
                            color="secondary"
                          >
                            <Typography variant="h6">Remove</Typography>
                          </IconButton>
                        </CardContent>
                      </Box>
                    ))}

                    {/* Upload Section */}
                    <Box
                      {...getRootProps()}
                      component={"div"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "300px",
                        borderRadius: "12px",
                        padding: 2,
                        backgroundColor: "#f5f5f5", // Light background to distinguish
                        border: "1px solid #ddd",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <input
                          {...getInputProps()}
                          onChange={handleInputImageFileChange}
                        />
                        <Button
                          sx={{
                            height: "80px",
                            width: "80px",
                            backgroundColor: "#fff",

                            borderRadius: "58px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#6F6F6F50",
                            },
                          }}
                        >
                          <Add
                            sx={{
                              height: "48px",
                              width: "48px",
                              color: "#A79C92",
                            }}
                          />
                        </Button>
                      </Stack>
                    </Box>
                  </Masonry>
                </Box>
          </Box>
          </Box>
        </Stack>
          
        </Stack>
      </form>
    </>
  );
};

export default GeneralSettingsOption;

const CommonTypoGraphy = ({ text }) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 700, color: "#1F1F1F" }}>
      {text}
    </Typography>
  );
};
