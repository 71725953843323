import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 43,
  height: 22, 
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
        ...theme.applyStyles?.('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18, 
    height: 18,
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    borderRadius: 15,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles?.('dark', {
      backgroundColor: '#39393D',
    }),
  },
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 25,
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: 'translateX(16px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 20,
      height: 20,
    },
  },
}));

export default function FSwitch({label, checked,onChange , sx ,id,name, inputProps, ...props}) {
  return (
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }}   />} checked={checked} onChange={onChange} id={id} name={name} {...props} sx={sx} label={label} />
  );
}
