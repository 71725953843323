import { Box, Button, Popover } from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { subMonths } from "date-fns";
import { da } from "date-fns/locale"; // Import the desired locale
import downArrow from '../../assets/arrow-down.svg';
import calendarIcon from '../../assets/calendar.png';
import moment from "moment";

export const DropDown = ({ startdate, endDate, setStartDate, setEndDate }) => {
    const [openCalendar, setOpenCalendar] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: subMonths(new Date(), 1),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    
    
    const anchorRef = useRef(null);
    const openPicker = Boolean(anchorRef.current && openCalendar);

    const handleCalendarClick = () => {
        setOpenCalendar((prevOpen) => !prevOpen);
    };

    const handleDateSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        setStartDate(start);
        setEndDate(end);

        setDateRange([
            {
                ...ranges.selection,
                startDate: start.toDate(),
                endDate: end.toDate(),
            },
        ]);
        if (moment(startDate).isSame(moment(endDate))) {

        } else {

            setOpenCalendar(false);
        }
    };

    return (
        <Box width={'100%'}>
            <Button
                variant="outlined"
                size="small"
                onClick={handleCalendarClick}
                ref={anchorRef}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "#A0A0A0",
                    border: "1.5px solid #D9D9D9",
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    borderRadius: "15px",
                    height: "42px",
                    fontSize: "14px",
                    textTransform: "none",
                    padding: "0 16px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <img src={calendarIcon} alt="Calendar Icon" style={{ width: "22.7px", height: "21.7px" }} />
                    {dayjs(dateRange[0].startDate).format("MMM D, YYYY")} -{" "}
                    {dayjs(dateRange[0].endDate).format("MMM D, YYYY")}
                </Box>
                {/* <img src={downArrow} alt="Down Arrow" style={{ height: "14px" ,paddingRight: '2px', transform:'translateY(-5px)'}} /> */}
            </Button>
            <Popover
                open={openPicker}
                onClose={() => setOpenCalendar(false)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <DateRangePicker
                    ranges={dateRange}
                    onChange={handleDateSelect}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    direction="horizontal"
                    locale={da}
                    rangeColors={["#BBB0A4"]}
                />
            </Popover>
        </Box>
    );
};
