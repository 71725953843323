import React, {  useState } from "react";
import { AppBar, Box, Paper, Stack, Typography } from "@mui/material";
import OnlineBookingSettingsOption from "../../components/settings/onlineBooking";
import GeneralSettingsOption from "../../components/settings/general";
import EmployeeSettingsOption from "../../components/settings/employee";
import CalendarSettingsOption from "../../components/settings/calendar";
import OpeningHours from "../../components/settings/opningHours";

const commonStyle = {
    marginLeft: "100px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: 500,
    color: "#BBB0A4",
};

const SettingsOption = () => {
    const [selectedOption, setSelectedOption] = useState("General");

    const handleClick = (option) => {
        setSelectedOption(option);
    };

    return (
        // <Paper style={{position: 'fixed'}}>
        <>
        <AppBar position="sticky" sx={{  boxShadow: "0px 1px 50px 0px rgba(0, 0, 0, 0.05)"}}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#FFFFFF", }}>
                <Typography
                variant="body1"
                    sx={{
                        ...commonStyle,
                        ...(selectedOption === "General" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                    }}
                    onClick={() => handleClick("General")}
                >
                    General
                </Typography>
                <Typography
                variant="body1"
                    sx={{
                        ...commonStyle,
                        ...(selectedOption === "Online booking" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                    }}
                    onClick={() => handleClick("Online booking")}
                >
                    Online booking
                </Typography>
                <Typography
                variant="body1"
                    sx={{
                        ...commonStyle,
                        ...(selectedOption === "Calendar" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                    }}
                    onClick={() => handleClick("Calendar")}
                >
                    Calendar
                </Typography>
                <Typography
                variant="body1"
                    sx={{
                        ...commonStyle,
                        ...(selectedOption === "Employees" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                    }}
                    onClick={() => handleClick("Employees")}
                >
                    Employees
                </Typography>
                <Typography
                variant="body1"
                    sx={{
                        ...commonStyle,
                        ...(selectedOption === "Opening hours" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                    }}
                    onClick={() => handleClick("Opening hours")}
                >
                    Opening hours
                </Typography>
            </Box>
            </AppBar>
            <Stack >


            {selectedOption === 'General' && <GeneralSettingsOption />}

            {selectedOption === 'Online booking' && <OnlineBookingSettingsOption />}

            {selectedOption === 'Calendar' && <CalendarSettingsOption />}

            {selectedOption === 'Employees' && <EmployeeSettingsOption />}

            {selectedOption === 'Opening hours' && <OpeningHours />}
            </Stack>
         
            </>
            // </Paper>
    )
};

export default SettingsOption;