import moment from "moment";

export function getCurrentWeekInfo(date) {
    moment.locale("en");
    const currentDate = date || moment();

    function getWeekDates(currentDate) {

        const startOfWeek = currentDate.startOf('week');
        const weekDates = [];

        for (let i = 1; i < 8; i++) {
            weekDates.push(startOfWeek.clone().add(i, 'day'));
        }

        return weekDates;
    }

    const weekDates = getWeekDates(currentDate);

    return {
        weekNumber: date.isoWeek(),
        weekDates,
        weekArray: []
    };
}

export function shiftWeek(date, format, shift) {
    const currentWeek = getCurrentWeekInfo(date);
    const weekIndex = currentWeek.weekNumber;

    const shiftByWeeks = (weeks) => {
        let newDate = moment(date).add(weeks, 'week');
        return [newDate, getCurrentWeekInfo(newDate)];
    }

    let weekShift = 1;
    if (format === 'evenweek' || format === 'oddweek') {
        const isEvenWeek = (weekIndex % 2 === 0);
        weekShift = (format === 'evenweek' && isEvenWeek || format === 'oddweek' && !isEvenWeek) ? 2 : 1;
    }

    return shift === 'previous' ? shiftByWeeks(-weekShift) : shift === 'next' ? shiftByWeeks(weekShift) : null;
}



export function dayChecker(emp, controller, index) {
    moment.locale("en");

    if (!emp?.detail || emp.detail.length === 0) return false;

    let currentDate = controller.weekDates[index];
    let currentWeek = controller.weekNumber;

    for (let i = 0; i < emp.detail.length; i++) {

        let item = emp.detail[i];
        let startdate = moment(item.start_date, 'YYYY-MM-DD');
        let enddate = item.end_date ? moment(item.end_date,  'YYYY-MM-DD') : null;
        let repeat = item.repeat ?? 'DO_NOT_REPEAT';

        if (moment(currentDate).isBefore(startdate)) continue;

        if (enddate && moment(currentDate).isAfter(enddate)) continue;

        // console.log('repeat', repeat, emp?.name, currentDate, startdate );
        
        switch (repeat) {
            case "EVEN":
                if (currentWeek % 2 !== 0) {
                    continue;
                }
                break;
            case "ODD":
                if (currentWeek % 2 === 0) {
                    continue;
                }
                break;
            case "MONTH":
                if (startdate.format("DD") !== currentDate.format("DD")) {
                    continue;
                }
                break;

            case "DO_NOT_REPEAT":
                if (currentDate.format("DD") !== startdate.format("DD")) {
                    continue;
                }
                break;
          
            default:
                break;
        }

        if (repeat === 'MONTH') {
            return true;
        } else {
           
   

                if (currentDate.format("ddd") === startdate.format("ddd")) {
                    // console.log('in if', emp, currentDate.format("ddd") ,startdate.format("ddd") );
                    
                    return true;
                }
           
        }
    }

    return false;
}


export function dateIdFinder({ emp, date }) {

    if (!emp?.detail || emp.detail.length === 0) return false;

    for (let i = 0; i < emp.detail.length; i++) {
        let item = emp.detail[i];

        if (moment(item.start_date).format("ddd") === moment(date).format("ddd")) {
            return item.id;
        }
    }

    return false;
}

export function eventFinder({ emp, date }) {

    if (!emp?.detail || emp.detail.length === 0) return null;

    for (let i = 0; i < emp.detail.length; i++) {
        let item = emp.detail[i];

        if (moment(item.start_date).format("ddd") === moment(date).format("ddd")) {
            return item;
        }
    }

    return null;
}

export function getStartEndTime({ emp, date, type = "WEEK" }) {
    if (!emp?.detail || emp.detail.length === 0) return false;

    for (let i = 0; i < emp.detail.length; i++) {
        let item = emp.detail[i];
        type = item.repeat;

        if (type === "WEEK" || type === "EVEN" || type === "ODD" || type === null && moment(item.start_date).format("ddd") === moment(date).format("ddd")) {
            const startTime = moment(item.start_time, 'HH:mm:ss').format('HH:mm');
            const endTime = moment(item.end_time, 'HH:mm:ss').format('HH:mm');
            return { startTime, endTime, item };
        }

        if (type === "MONTH" || type === null && moment(item.start_date).format("DD") === moment(date).format("DD")) {
            const startTime = moment(item.start_time, 'HH:mm:ss').format('HH:mm');
            const endTime = moment(item.end_time, 'HH:mm:ss').format('HH:mm');
            return { startTime, endTime, item };
        }




    }

    return false;
}
