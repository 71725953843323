import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import CustomCalendar from "./scenes/Calendar";
import EmployeeTable from "./scenes/Employee/Index";
import SettingsOption from "./scenes/Settings/Index";
import AddCustomerForm from "./scenes/Coustmer";
import JournalBox from "./scenes/journal";
import JournalDetail from "./scenes/journal/JournalDetail";
import Journal from "./scenes/journal";
import EditJournal from "./scenes/journal/EditJournal";
import LoginFlow from "./scenes/LoginForm";
import HistoryTable from "./scenes/History";
import InsightsPage from "./scenes/Insights";
import Services from "./scenes/Services";
import Specialoffers from "./scenes/Specialoffers";

import { DropDown } from "./components/insight/customDropDown";
import { ToastContainer } from "react-toastify";

// phone_number: '67867867',
//       password: '12345678',

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const token = localStorage.getItem("auth_token");

  // const getPageName = (pathname) => {
  //   switch (pathname.toLowerCase()) {
  //     case "/dashboard":
  //       return "DASHBOARD";
  //     case "/inquiry":
  //       return "INQUIRY";
  //     case "/role":
  //       return "ROLE";
  //     case "/product":
  //       return "PRODUCT";
  //     case "/user":
  //       return "USER";
  //     case "/consumer":
  //       return "CONSUMER";
  //     case "/consultant":
  //       return "CONSULTANT";
  //     case "/genralfollowup":
  //       return "GENRAL FOLLOW UP";
  //     case "/forgotpassword":
  //       return "FORGOT PASSWORD";
  //     case "/otpvalidate":
  //       return "OTP VALIDATE";
  //     case "/newpassword":
  //       return "NEW PASSWORD";
  //     default:
  //       return "LOGIN";
  //   }
  // };

  // Hide only the Topbar for certain routes like "/inquiry/:id"
  const shouldHideTopbar = location.pathname.startsWith("/inquiry/");

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  const ProtectedRoute = ({ element }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" state={{ from: location.pathname }} />;
    }
    return element;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <ToastContainer />
          {/* Sidebar remains collapsed on all pages except login */}
          {location.pathname !== "/" && <Sidebar className="collapsed" />}
          <main
            className={`content ${location.pathname !== "/" ? "shifted" : ""}`}
          >
            <Routes>
              {/* Login Route */}
              <Route
                path="/"
                element={
                  !isAuthenticated ? (
                    <LoginFlow />
                  ) : (
                    <Navigate to={location.state?.from || "/calendar"} />
                  )
                }
              />

              {/* Protected Routes */}
              <Route
                path="/calendar"
                element={<ProtectedRoute element={<CustomCalendar />} />}
              />
              <Route
                path="/employee"
                element={<ProtectedRoute element={<EmployeeTable />} />}
              />
              <Route
                path="/customers"
                element={<ProtectedRoute element={<AddCustomerForm />} />}
              />
              <Route
                path="/journal/:id"
                element={<ProtectedRoute element={<Journal />} />}
              />
              <Route
                path="/journal/:journalId"
                element={<ProtectedRoute element={<JournalDetail />} />}
              />
              <Route
                path="/journal/edit/:journalId"
                element={<ProtectedRoute element={<EditJournal />} />}
              />
              <Route
                path="/test"
                element={<ProtectedRoute element={<JournalBox />} />}
              />
              <Route
                path="/history"
                element={<ProtectedRoute element={<HistoryTable />} />}
              />

              <Route
                path="/services"
                element={<ProtectedRoute element={<Services />} />}
              />


              <Route
                path="/specialOffers"
                element={<ProtectedRoute element={<Specialoffers />} />}
              />

              <Route
                path="/settings"
                element={<ProtectedRoute element={<SettingsOption />} />}
              />

              <Route
                path="/insights"
                element={<ProtectedRoute element={<InsightsPage />} />}
              />

              <Route
                path="/dropDown"
                element={<ProtectedRoute element={<DropDown />} />}
              />

              {/* Fallback Route */}
              <Route
                path="/*"
                element={<Navigate to={isAuthenticated ? "/calendar" : "/"} />}
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
