import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryHeading from "../../commonPrimaryHeading";
import SecondaryHeading from "../../commonSecondaryHeading";
import CalendarComponent from "./calander";
import Shifter from "./calander/Shifter";
import { getCurrentWeekInfo } from "../utils/Functions";
import moment from "moment";
import FormModel from "./calander/popup/FormModel";
import FSwitch from "../../../commonComponents/f-switch";


export default function IndividualOpeningHours({ data, setData }) {
    moment.locale("en");


    console.log('data individual opening hours',data);
    const {employees_opening_hour} = data
    
    const [controller, setController] = useState({
        date: moment(),
        format: "all",
    });
    const [showCalander, setShowCalander] = useState(false);
    const [openFormModel, setOpenFormModel] = useState(false);
    const [formProps, setFormProps] = useState({});
    


    useEffect(() => {
        const currentWeekInfo = getCurrentWeekInfo(controller.date);


        setController((prev) => ({
            ...prev,
            weekNumber: currentWeekInfo?.weekNumber,
            weekDates: currentWeekInfo?.weekDates,
            weekArray: currentWeekInfo?.weekArray
        }))
    }, [controller.date]);


    useEffect(()=>{
        if(employees_opening_hour !== undefined){

            // const {employees_opening_hour} = data
            const isEnable = employees_opening_hour.find((empObj)=>empObj.detail.length > 0)
            setShowCalander(isEnable)
        }

    },[employees_opening_hour])


    const updateEvent=(updatedEvent)=>{
        console.log('data',data);
        
        console.log('updatedEvent', updatedEvent);
        const{finalValues, detailId} =  updatedEvent
        let {employees_opening_hour} = data

        const foundEmployee = employees_opening_hour.map((empObj)=>{
            if(empObj.id == finalValues.employee_id){

                console.log('empObj', empObj);

                if(detailId){
                  const detailIndex =  empObj.detail.findIndex((detailObj)=>detailObj.id == detailId)
                  console.log('detailIndex', detailIndex);
                  let newDetail = [...empObj.detail]
                  if(detailIndex != -1){

                    newDetail[detailIndex] = finalValues
                    return {
                        ...empObj, 
                        detail: newDetail
                    }
                }else{
                    return {
                            ...empObj, 
                            detail: [...empObj.detail,finalValues]
                            }
                }
                  
                }else{

                return {
                        ...empObj, 
                        detail: [...empObj.detail,finalValues]
                    }
                }
                        
           
            }else{
                return empObj
            }
        })

        console.log('found Employee', foundEmployee);
        
        setData({
            ...data,
            employees_opening_hour: foundEmployee,

        })

        // check if need to update event or create event
        // create event


        // foundEmployee?.details.push(newEvent)


        

    }

     const handleRemove = (event) => {
           

            console.log('event', event);
            
                let newEmployee = {
                    ...formProps?.employee,
                    detail: formProps?.employee?.detail.filter((item) => item.id !== event?.id)
                };
    
                console.log('newEmployee', newEmployee);
                
    
                setData(prev => ({
                    ...prev,
                    employees_opening_hour: prev?.employees_opening_hour?.map((item) => {
                        if (item.id === formProps?.employee?.id) {
                            return newEmployee;
                        } else {
                            return item;
                        }
                    })
                }));
            
    
                setOpenFormModel(false)
        };
    
    // console.log('openFormModel', openFormModel);
    // console.log('formProps', formProps);
    

    return (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "start", pb:10, mt:0, pl:4, pr:4 }}>
            <Box sx={{ width: "33%", display: "flex", flexDirection: "column", justifyContent: "between", alignItems: "start",pr:3 }}>
                <PrimaryHeading text={"Individual opening hours"} />
                <SecondaryHeading text={"Here you can set individual opening hours for each employee."} />
            </Box>



            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "67%", overflow: 'hidden' }}>

                <Stack display={"flex"} width={"100%"} flexDirection={"column"} justifyContent={"start"} alignItems={"start"} >
                  

                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                        {"Individual opening hours"}
                                    </Typography>

                    <FSwitch
                        
                        sx={{
                            '& .MuiSwitch-switchBase': {
                                '&.Mui-checked': { color: '#fff' },
                                '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                            },
                            '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                       
                            mx:0
                        }}
                        checked={showCalander}
                        onChange={(e) => setShowCalander(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Stack>
                {showCalander &&
                    <React.Fragment >
                        <Shifter controller={controller} setController={setController} />
                        {/* <Stack position={"relative"}> */}
                            <CalendarComponent data={data}  controller={controller}   setOpenFormModel={(data)=>{
                              
                                
                                const {openModel, formProps} = data 
                                setFormProps(formProps)
                                setOpenFormModel(openModel)
                            }} />
                        {/* </Stack> */}
                    </React.Fragment>
                }

            </Box>

               {openFormModel && <FormModel 
               formProps={formProps}
                setData={(eventObj)=>updateEvent(eventObj)}
                handleRemove={handleRemove}
                 open={openFormModel}
                onClose={() => setOpenFormModel(false)} />}
        </Box>
    );
}
