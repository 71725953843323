import React from 'react';

import {Action} from '../Action';

export function Remove(props) {
  return (
    <Action
      {...props}
      active={{
        fill: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 255, 255, 1)',
      }}
    >
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.125 4.75065H3.54167M3.54167 4.75065H14.875M3.54167 4.75065V15.834C3.54167 16.2539 3.69092 16.6566 3.9566 16.9536C4.22228 17.2505 4.58261 17.4173 4.95833 17.4173H12.0417C12.4174 17.4173 12.7777 17.2505 13.0434 16.9536C13.3091 16.6566 13.4583 16.2539 13.4583 15.834V4.75065M5.66667 4.75065V3.16732C5.66667 2.74739 5.81592 2.34466 6.0816 2.04773C6.34728 1.7508 6.70761 1.58398 7.08333 1.58398H9.91667C10.2924 1.58398 10.6527 1.7508 10.9184 2.04773C11.1841 2.34466 11.3333 2.74739 11.3333 3.16732V4.75065M7.08333 8.70898V13.459M9.91667 8.70898V13.459" stroke="#D30000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </Action>
  );
}
