import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/locales";
import { Provider } from "react-redux";
import store,{persistor} from "./context/store";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root")); // Import the service worker to enable background notifications
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/firebase-messaging-sw.js")
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);
//     })
//     .catch((err) => {
//       console.log("Service Worker registration failed:", err);
//     });
// }
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
  <I18nextProvider i18n={i18n}>
  
    {/* <React.StrictMode> */}
      <BrowserRouter>
      
        <App />
        
      </BrowserRouter>
  
    {/* </React.StrictMode> */}

  </I18nextProvider>
  </PersistGate>
  </Provider>
);
