import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Stack, IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import axios from "axios";
import moment from "moment";

import PrimaryHeading from "../../components/settings/commonPrimaryHeading";
import SecondaryHeading from "../../components/settings/commonSecondaryHeading";
import CommonButton from "../../components/settings/commonButton";
import DeleteIcon from "../../assets/Trash.png";
import ServiceGroupFrontIcon from "../../assets/addServiceGroup.png";

import AddSpecialOfferModal from "../../components/specialOffer/addSpecialofferModal";
import AddCampaignOfferModal from "../../components/specialOffer/addCampaignOfferModal";
import CustomDeleteModal from "../../components/deleteAlertModal";

import CountdownTimer from "../../components/specialOffer/liveCountDownComponent";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { MultipleContainers } from "../../components/MultipleContainers/MultipleContainers";
import { useSelector } from "react-redux";
import Notauthorized from "../../components/commonComponents/F_Notauthorized";

const Specialoffers = () => {

    const user = useSelector((state) => state.user.data);

    const authTokenUser = localStorage.getItem('auth_token');

    // console.log('DATA')

    const [isLoading, setIsloading] = useState(false);
    const [createSpecialOfferMoadl, setCreateSpecialOfferModal] = useState(false);
    const [createCampaignOfferMoadl, setCreateCampaignOfferMoadl] = useState(false);
    const [specialOfferList, setSpecialOfferList] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
 const [itemsToDelete, setItemsToDelete] = useState(null);
    const [selectedSpecialOffer, setSelectedSpecialOffer] = useState(null);
    const [selectedCampaignOffer, setSelectedCampaignOffer] = useState(null);
    

    useEffect(() => {
        fetchSpecialOfferApi();
    }, []);

    if(!user.settings.crud_special_offers && user.role !== "ADMIN"){
        return <Notauthorized/>
    }
    const transformServiceList = (data) => {
        console.log('HERE IT IS ', JSON.stringify(data))
        const groupedSpecialoffer = data
            .filter(group => group.id !== 0)
            .map(group => ({
                title: group.name,
                groupId: group.id,
                services: group.special_offers.length > 0 ? group.special_offers : [],
                start_datetime: group.special_offers[0].start_datetime,
                end_datetime: group.special_offers[0].end_datetime,
                sequence: group.sequence
            }));

        const ungroupedSpecialoffer = data
            .filter(group => group.id === 0)
            .flatMap(group => group.special_offers)
            .map(specialOffer => ({
                ...specialOffer,
                groupId: 0,
            }));

            const specialOffersListObj = {
                0:{
                  title: 'Special offers without a campaign',
                  groupId: 0,
                  services: ungroupedSpecialoffer,
                  sequence: 0
                }
              }
    
              groupedSpecialoffer.map((groupObj)=>{
                specialOffersListObj[groupObj?.groupId]=groupObj
              })
    
            return specialOffersListObj

        // return [
        //     {
        //         title: 'Special offers without a campaign',
        //         groupId: 0,
        //         sequence: 0,
        //         // data: ungroupedSpecialoffer,
        //         services: ungroupedSpecialoffer
        //     },
        //     ...groupedSpecialoffer,
        // ];
    };


    console.log('specialOfferList',specialOfferList);
    
    async function fetchSpecialOfferApi() {
        setIsloading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/campaign`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setSpecialOfferList(transformServiceList(response.data.data));
                setIsloading(false);
            } else {
                setSpecialOfferList([]);
                setIsloading(false);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
            setSpecialOfferList([]);
            setIsloading(false);
        } finally {
            setIsloading(false);
        }
    };

    const deleteServiceAPI = async (id, isGroup = false) => {
        try {

            // console.log('id', id);
            
            let serviceURL =`${process.env.REACT_APP_URL}/api/v1/store/special_offer/${id}` 
            if(isGroup){
                serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/campaign/${id}`
            }
            
            const response = await axios.delete(serviceURL, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response.status == 200) {
                if(isGroup){
                toast.success(`Campaign deleted successfully`);
            }else{
                toast.success(`Special offer deleted successfully`);

            }
                fetchSpecialOfferApi();
            } else {
                if(isGroup){
                toast.error(`Failed to delete Campaign`);
                }else{
                toast.error(`Failed to delete special offer`);

                }
            }
        } catch (error) {
            toast.error(`Failed to delete service`);
            console.log('ERROR ', error)
        }
    };

    const sequenceUpdateAPI = async (data, isGroup = false) => {
        try {

          
            
            
            let serviceURL =`${process.env.REACT_APP_URL}/api/v1/store/special_offer/sequence` 
            if(isGroup){
                serviceURL = `${process.env.REACT_APP_URL}/api/v1/store/campaign/sequence`
            }
            console.log('serviceURL', serviceURL);
            
            const response = await axios.post(serviceURL,data, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response.status == 200) {
                toast.success(`Updated successfully`);
                fetchSpecialOfferApi();
            } else {
                toast.error(`Failed to update`);
            }
        } catch (error) {
            toast.error(`Failed to update service`);
            console.log('ERROR ', error)
        }
    };



    const handleSpecialOfferRowClick = (dataItem) => {
        setSelectedSpecialOffer(dataItem);
        setCreateSpecialOfferModal(true);
    };

    const handleCampaignRowClick = (campaign) => {
        if (campaign.groupId === 0) {
            return;
        };
        setSelectedCampaignOffer(campaign);
        setCreateCampaignOfferMoadl(true);
    };

    function SpecialoffersList({ title, items, id, index }) {
        return (
            <Droppable droppableId={id} type="item">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Box sx={{
                            display: 'flex', width: '100%', height: '42px',
                            // marginLeft: '20%',
                            borderRadius: '15px', backgroundColor: '#D9D9D9',
                            justifyContent: "flex-start", alignItems: 'center', marginTop: index === 0 ? 5 : 3
                        }}
                            onClick={() => handleCampaignRowClick(items)}
                        >
                            {isLoading ? (
                                <Skeleton variant="rectangular" width={20} height={12} sx={{ marginLeft: 15 }} />
                            ) : (
                                <img
                                    src={ServiceGroupFrontIcon}
                                    alt="icon"
                                    style={{
                                        width: 20,
                                        height: 12,
                                        marginLeft: 15,
                                    }}
                                />
                            )}

                            {isLoading ? (
                                <Skeleton variant="text" width={400} height={20} sx={{ marginLeft: 20 }} />
                            ) : (
                                <Typography style={{ width: 400, fontSize: '20px', color: '#737373', marginLeft: 20 }}>
                                    {items.title}
                                </Typography>
                            )}

                            {items.groupId != 0 &&
                                <IconButton sx={{ pr: 2, marginLeft: 'auto' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedCampaignOffer(items);
                                        setShowDeleteModal(true);
                                    }}>
                                    {isLoading ? (
                                        <Skeleton variant="rectangular" width={17} height={19} sx={{ marginLeft: 10 }} />
                                    ) : (
                                        <img
                                            src={DeleteIcon}
                                            alt="Delete"
                                            style={{ width: '17px', height: '19px', marginLeft: 10 }}
                                        />
                                    )}
                                </IconButton>
                            }

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            marginTop: 2,
                            marginBottom: 1,
                            padding: '0 15px',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: '#737373',
                            width: '55%',
                            marginLeft: 'auto'
                        }}>
                            <Typography sx={{ width: 170, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>Expiry</Typography>
                            <Typography sx={{ width: 160, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>Percentage</Typography>
                            <Typography sx={{ width: 150, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>Old Price</Typography>
                            <Typography sx={{ width: 140, mr: 3, fontWeight: 400, fontSize: '16px', color: '#1F1F1F' }}>New Price</Typography>
                        </Box>

                        <div>
                            {items.data.map((dataItem, dataIndex) => (
                                <Draggable draggableId={dataItem.id.toString()} index={dataIndex} key={dataItem.id}>
                                    {(provided) => (
                                        <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                        >
                                            <Box sx={{
                                                display: 'flex', width: '95%',
                                                marginLeft: '5%',
                                                height: '42px',
                                                borderRadius: '15px', border: '1.5px solid #D9D9D9',
                                                marginTop: 2,
                                                marginBottom: 2,
                                                justifyContent: "space-between",
                                                alignItems: 'center',
                                            }}
                                                onClick={() => handleSpecialOfferRowClick(dataItem)}
                                            >

                                                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {isLoading ? (
                                                        <Skeleton variant="circle" width={18} height={18} sx={{ marginLeft: 3 }} />
                                                    ) : (
                                                        <DensityMediumIcon sx={{ width: 18, height: 18, marginLeft: 3, color: '#A0A0A0' }} />
                                                    )}

                                                    {isLoading ? (
                                                        <Skeleton variant="text" width={250} height={20} sx={{ marginLeft: 2 }} />
                                                    ) : (
                                                        <Typography sx={{
                                                            width: 300,
                                                            fontWeight: 400,
                                                            fontSize: '20px',
                                                            color: '#A0A0A0',
                                                            marginLeft: 2,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {dataItem?.service.name}
                                                        </Typography>
                                                    )}
                                                </Stack>

                                                <CountdownTimer createdAt={dataItem?.service.created_at} isLoading={isLoading} />

                                                {isLoading ? (
                                                    <Skeleton variant="text" width={150} height={20} />
                                                ) : (
                                                    <Typography sx={{
                                                        width: 150,
                                                        fontWeight: 400,
                                                        fontSize: '20px',
                                                        color: '#D30000',
                                                        marginLeft: 10,
                                                    }}>
                                                        {`${dataItem?.service?.special_percentage}%`}
                                                    </Typography>
                                                )}

                                                {isLoading ? (
                                                    <Skeleton variant="text" width={140} height={20} />
                                                ) : (
                                                    <Typography sx={{
                                                        width: 140,
                                                        fontWeight: 400,
                                                        fontSize: '20px',
                                                        color: '#A0A0A0',
                                                        marginLeft: 2,
                                                        textDecorationLine: 'line-through',
                                                    }}>
                                                        {`${dataItem.service?.price}kr.`}
                                                    </Typography>
                                                )}

                                                {isLoading ? (
                                                    <Skeleton variant="text" width={140} height={20} />
                                                ) : (
                                                    <Typography sx={{
                                                        width: 140,
                                                        fontWeight: 400,
                                                        fontSize: '20px',
                                                        color: '#367B3D',
                                                        marginLeft: 2,
                                                    }}>
                                                        {`${dataItem.service?.special_price}kr.`}
                                                    </Typography>
                                                )}

                                                <IconButton sx={{
                                                    paddingRight: 2,
                                                }} onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedService(dataItem);
                                                    setShowServiceDeleteModal(true);
                                                }}>
                                                    {isLoading ? (
                                                        <Skeleton variant="rectangular" width={17} height={19} sx={{ marginLeft: 10 }} />
                                                    ) : (
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                            style={{ width: '17px', height: '19px', marginLeft: 10 }}
                                                        />
                                                    )}
                                                </IconButton>
                                            </Box>

                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    const handleClose = () => {
        setCreateSpecialOfferModal(false);
        setSelectedSpecialOffer(null);
        fetchSpecialOfferApi();
    };

    const handleCloseCampaignOffer = () => {
        setCreateCampaignOfferMoadl(false);
        setSelectedCampaignOffer(null);
        fetchSpecialOfferApi();
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleCloseServiceDeleteModal = () => {
        setShowServiceDeleteModal(false);
    };


    function handleRemove(containerID) {
        console.log('hello', containerID);
        
        setItemsToDelete({'containerId':containerID, 'type':'container', 'itemId': null, container: specialOfferList[containerID], item: null})
        setShowDeleteModal(true)
      }
    
      function handleRemoveItem(itemId, containerID) {
        setItemsToDelete({'containerId':containerID, 'type':'item', 'itemId': itemId, container: specialOfferList[containerID], item: specialOfferList[containerID].services.find((serviceObj) => serviceObj?.id == itemId)?.service})
        setShowDeleteModal(true)
   
      }

      const handleDelete=async()=>{
        
        if(itemsToDelete){
            let newSpecialOfferList = {...specialOfferList}
            const containerId = itemsToDelete?.containerId
            if(itemsToDelete?.type == 'container'){
                delete newSpecialOfferList[containerId]
                console.log('containerID', newSpecialOfferList);
                setSpecialOfferList(newSpecialOfferList)
                deleteServiceAPI(containerId, true)

            }else{
                const itemId = itemsToDelete?.itemId
                let updatedItems = newSpecialOfferList[containerId].services.filter((serviceObj) => serviceObj?.id !== itemId)
                let newContainer = {...newSpecialOfferList[containerId], services: updatedItems}
                setSpecialOfferList( {...newSpecialOfferList, [containerId]: newContainer} )
                deleteServiceAPI(itemId, false)

            }
            setShowDeleteModal(!showDeleteModal)
        }
      }

    return (
        <>
              <Stack  flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>

                <ToastContainer />

                <Box sx={{ display: "flex", bgcolor: '#FFFFFF', borderRadius: '25px', flexDirection: 'column', width: '95%',  height: '92vh', padding: 5 }}>

                    <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', minHeight: '200px' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                            <PrimaryHeading fontSize="22px" text={"Special offers"} />

                            <SecondaryHeading fontSize="20px" text={"Here you can create, edit & delete campaigns and offers."} />
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '60%',
                            maxHeight: '100vh', overflowY: 'auto',
                            '&::-webkit-scrollbar': { display: 'none' },
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}>

                            <Box style={{
                                display: 'flex', flexDirection: 'row',
                                justifyContent: 'flex-end', gap: 0,
                                position: 'sticky', top: 0,
                                zIndex: 10,
                                backgroundColor: 'white',
                                paddingBottom: 20
                            }}>
                                <CommonButton
                                    // width="257px"
                                    height="40px"
                                    style={{width:'auto', minWidth: 180}}
                                    backgroundColor={'#D9D9D9'}
                                    onClick={() => { setCreateCampaignOfferMoadl(true) }}
                                    title={
                                        <>
                                            <img
                                                src={ServiceGroupFrontIcon}
                                                alt="icon"
                                                style={{
                                                    width: 20,
                                                    height: 12,
                                                    marginRight: 15
                                                }}
                                            />
                                            Add campaign
                                        </>
                                    }
                                />

                                <CommonButton
                                    // width="257px"
                                    height="40px"
                                    style={{width:'auto', minWidth: 180, ml:2}}
                                    onClick={() => { setCreateSpecialOfferModal(true) }}
                                    title={'+  Add single offer'}
                                />
                            </Box>

                            {/* <DragDropContext onDragEnd={handelDragDrop}>
                                <Droppable droppableId="ROOT" type="group">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {specialOfferList.map((section, index) => {
                                                return (
                                                    <Draggable
                                                        draggableId={section.title}
                                                        key={section.title}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <SpecialoffersList
                                                                    title={section.title}
                                                                    items={section}
                                                                    id={section.title}
                                                                    mainId={section.id}
                                                                    index={index}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext> */}


                                {
                                    isLoading ? 

                                    <Stack width="98%" spacing={2} flex={1} flexDirection={'column'} alignItems={'flex-end'} sx={{mt:2}}>
                                    <Skeleton variant="rounded" width="100%" height={40}/>
                                    {/* <Stack flex={1} flexDirection={'column'} justifyContent={'flex-end'} alignItems={'flex-end'}> */}
                                    {[...Array(7)].map((_, index) => (
                                        // <Box key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 4 }}>
                                          
                                                <Skeleton variant="rounded" width="94%" height={40}/>
                                      
                                        // </Box>
                                    ))}
                                        {/* </Stack> */}
                                        </Stack>
                                    :
                                
                                    Object.keys(specialOfferList).length > 0 &&

                                <MultipleContainers
                                itemCount={Object.keys(specialOfferList).length}
                                items={specialOfferList}
                                setItems={setSpecialOfferList }
                                strategy={rectSortingStrategy}
                                vertical
                                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                                onRemove={handleRemove}
                                onRemoveItem={handleRemoveItem}
                                onDragToAnotherContainer={(containerId, itemId)=>{console.log(containerId, itemId);
                                }}

                                onDragComplete={(isContainer, containerId, updatedcontainers)=>{

                                    // console.log('on drag complete', containerId);
                                    
                                    let dataToUpdate = []
                                    if(isContainer){

                                    
                                    // console.log(`isContainer:${isContainer}`, `containerId:${containerId}`)
                                    let newServiceList = {...specialOfferList}
                                    updatedcontainers.map((containerId, index)=>{
                                        if(containerId!=0){

                                            dataToUpdate.push({'id': containerId, "sequence":index})
                                        }
                                            newServiceList[containerId].sequence = index
                                       
                                    })

                                    // console.log('newServiceList', newServiceList);
                                    setSpecialOfferList(newServiceList)

                                   
                                }else{
                                    specialOfferList[containerId].services.map((serviceObj)=>{
                                        dataToUpdate.push({id:serviceObj.id, group_id: containerId == '0' ? null : containerId, sequence: serviceObj?.sequence})
                                    })

                                    // console.log('dataToUpdate else', dataToUpdate);
                                    


                                }

                                sequenceUpdateAPI(dataToUpdate, isContainer)
                                
                                    
                                }}
                                />
                            }
                        </Box>

                    </Box>

                </Box>

                {createSpecialOfferMoadl &&
                    <AddSpecialOfferModal
                        open={createSpecialOfferMoadl}
                        handleClose={handleClose}
                        selectedSpecialOffer={selectedSpecialOffer}
                        type={selectedSpecialOffer ? 'edit' : 'create'}
                    />
                }

                {createCampaignOfferMoadl &&
                    <AddCampaignOfferModal
                        open={createCampaignOfferMoadl}
                        handleClose={handleCloseCampaignOffer}
                        selectedCampaignOffer={selectedCampaignOffer}
                        type={selectedCampaignOffer ? 'edit' : 'create'}
                    />
                }

                {/* {showDeleteModal &&
                    <CustomDeleteModal
                        open={showDeleteModal}
                        handleClose={handleCloseDeleteModal}
                        description={
                            <>
                                Are you sure you want to delete this
                                <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                    {selectedCampaignOffer.title}
                                </span>
                                campaign?
                            </>
                        }
                        onClickDismiss={handleCloseDeleteModal}
                        onClickConfirm={() => deleteSpecialOfferCampaign(selectedCampaignOffer.groupId)}
                    />
                } */}

                    {showDeleteModal &&
                        <CustomDeleteModal
                            open={showDeleteModal}
                            handleClose={handleCloseDeleteModal}
                            description={
                                <>
                                    Are you sure you want to delete 
                                    <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                        {itemsToDelete?.type == 'container' ? itemsToDelete?.container.title : itemsToDelete?.item.name}
                                    </span>
                                    {/* {itemsToDelete?.type == 'container' ? 'service group': 'service'} */}
                                </>
                            }
                            onClickDismiss={handleCloseDeleteModal}
                            onClickConfirm={() => handleDelete()}
                        />
                    }

            </Stack>
        </>

    )

}

export default Specialoffers;

