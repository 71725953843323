import { AppBar, Badge, Box, Button, Grid2, Stack, styled, Table, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatAmount } from "../../utils/format-amout";
import PrimaryHeading from "../../components/settings/commonPrimaryHeading";

const commonStyle = {
    marginLeft: "100px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: 500,
    color: "#BBB0A4",
};


const HistoryTable = () => {
    const authTokenUser = localStorage.getItem('auth_token');
    const [selectedButton, setSelectedButton] = useState('0');
    const [employeeId, setEmployeeId] = useState('null');
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({ id: '0', name: '' });
    const [status, setStatus] = useState('PENDING');
    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [previousBookings, setPreviousBookings] = useState([]);
    const [badgeStatus, setBadgeStatus] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [hasMore, setHasMore] = useState(false)
    const font_size = 16;


    const handleButtonSelect = (value) => {
        const today = moment().startOf("day").format("YYYY-MM-DD");

        if (value === '4') {
            setOffset(0);
            // All: Set startDate to null (fetch all)
            setStartDate(null);
            setEndDate(moment().endOf("year").format("YYYY-MM-DD"));
        } else if (value === '3') {
            setOffset(0);
            // This Year
            setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
            setEndDate(moment().endOf("year").format("YYYY-MM-DD"));
        } else if (value === '2') {
            setOffset(0);
            // This Month
            setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
            setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
        } else if (value === '1') {
            setOffset(0);
            // This Week
            setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
            setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
        } else if (value === '0') {
            setOffset(0);
            // Today
            setStartDate(today);
            setEndDate(today);
        }

        setSelectedButton(value);
    };

    const statusColors = {
        'BOOKED': "#A79C92",
        'CANCELLED': "#C74141",
        'COMPLETED': "#367B3D",
        'OFFERED': "#447BCD",
        'OFFER_ACCEPTED': "#447BCD",
        'NOSHOW': "#E19957",
        'RESCHEDULED': "#A79C92",
        'ABSENCE': "#A36437",
        'AWAITING': "#E19957",
        'PENDING': '#E19957',
    };


    const StyledBadge = styled(Badge)(({ status }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: statusColors[status?.trim()] || "#E0E0E0",
            color: statusColors[status?.trim()] || "#E0E0E0",
        },
    }));

    const fetchUpcomingEmployeeData = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/booking`,
                {
                    params: {
                        date: moment().startOf("day").format("YYYY-MM-DD"),
                        to_date: endDate || moment().endOf("year").format("YYYY-MM-DD"),
                        booking_type: "Upcoming",
                        filter_equipment_bookings: false,
                        employee_id: selectedEmployee?.id || '0',
                    },
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`,
                    },
                }
            );

            const allData = response?.data?.data;

            const uniqueEmployees = [];
            const seenEmployeeIds = new Set();

            allData.forEach((item) => {
                const { employee_id, booking_details } = item;
                const employee_name = booking_details?.employee_name;

                if (employee_id && !seenEmployeeIds.has(employee_id)) {
                    seenEmployeeIds.add(employee_id);
                    uniqueEmployees.push({ employee_id, employee_name });
                }
            });
            setUpcomingBookings(allData);

            const isDifferent = uniqueEmployees.some(
                (newEmp) =>
                    !employees.some(
                        (existingEmp) =>
                            existingEmp.employee_id === newEmp.employee_id &&
                            existingEmp.employee_name === newEmp.employee_name
                    )
            );

            if (isDifferent) {
                setEmployees(uniqueEmployees);
            }
            console.log("alldata", allData);
            console.log("employees", employees);


        } catch (error) {
            console.error("Error fetching bookings:", error);
        }
    };

    const fetchPreviousEmployeeData = async () => {
        console.log('next fetched');
        

        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/booking`,
                {
                    params: {
                        date: startDate,
                        to_date: moment().endOf("day").format("YYYY-MM-DD"),
                        limit: limit,
                        offset: offset * limit,
                        booking_type: "Previous",
                        filter_equipment_bookings: false,
                        employee_id: selectedEmployee?.id || '0',
                    },
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`,
                    },
                }
            );

            const allData = response?.data?.data;
            const newStatus = [];

            allData.forEach((item) => {
                const { status } = item;
                const booking_status = status;

                if (booking_status) {
                    newStatus.push(status);
                }
            });

            setBadgeStatus(newStatus);
            setPreviousBookings(prevItems => [...prevItems, ...allData]);


            if (allData.length === limit) {
                setOffset((prev) => prev + 1);


                console.log('in if');

                setHasMore(true);
            } else {
                console.log('else');
                
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching bookings:", error);
        }
    };

    const normalizeStatus = (status) => {
        const mappedStatuses = {
            BOOKED: "PENDING",
            RESCHEDULED: "PENDING",
            NOSHOW: "PENDING",
        };
        return mappedStatuses[status] || status;
    };

    const statusCounts = previousBookings.reduce((acc, booking) => {
        const normalizedStatus = normalizeStatus(booking.status?.trim() || "UNKNOWN");
        acc[normalizedStatus] = (acc[normalizedStatus] || 0) + 1;
        return acc;
    }, {});


    const displayLabels = ["Pending", "Completed", "Cancelled", "Absence"];

    useEffect(() => {
        setOffset(0);
        setHasMore(true);
        setPreviousBookings([]);
        fetchUpcomingEmployeeData();
        fetchPreviousEmployeeData();
    }, [startDate, endDate, selectedButton, selectedEmployee?.id]);

    useEffect(() => {
        if (selectedButton) {
            handleButtonSelect(selectedButton);
        }
    }, [selectedButton]);

    return (
        <Box>
            

              <AppBar position="sticky" sx={{  boxShadow: "0px 1px 50px 0px rgba(0, 0, 0, 0.05)"}}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#FFFFFF", }}>
                            <Typography
                            variant="body1"
                                sx={{
                                    ...commonStyle,
                                    ...(selectedButton === "0" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                }}
                                onClick={() => handleButtonSelect("0")}
                            >
                                Today
                            </Typography>
                            <Typography
                            variant="body1"
                                sx={{
                                    ...commonStyle,
                                    ...(selectedButton === "1" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                }}
                                onClick={() => handleButtonSelect("1")}
                            >
                                This week
                            </Typography>
                            <Typography
                            variant="body1"
                                sx={{
                                    ...commonStyle,
                                    ...(selectedButton === "2" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                }}
                                onClick={() => handleButtonSelect("2")}
                            >
                                This month
                            </Typography>
                            <Typography
                            variant="body1"
                                sx={{
                                    ...commonStyle,
                                    ...(selectedButton === "3" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                }}
                                onClick={() => handleButtonSelect("3")}
                            >
                                This year
                            </Typography>
                            <Typography
                            variant="body1"
                                sx={{
                                    ...commonStyle,
                                    ...(selectedButton === "4" ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                }}
                                onClick={() => handleButtonSelect("4")}
                            >
                                All
                            </Typography>
                        </Box>
                        </AppBar>

            {/* <Stack spacing={6} sx={{   
                           height: "95vh",
                           width:'100%',
                        
                        //    overflowY:'auto',
                            scrollbarWidth:'none',
                           overflowX:'hidden' }}> */}
            <Box sx={{px:4,}}  height={'60vh'}>
                <Grid2 spacing={2}>
                    <Grid2 item >
                        <Typography sx={{ mt: 4, mb: 2, color: 'black' }} variant="h2" component="div">
                            <Grid2 container sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                {/* <Grid2 item sx={{ fontWeight: 600 }}>Upcoming</Grid2> */}
                                <PrimaryHeading text={"Upcoming"} />
                                <Grid2 item>
                                    <div className="dropdown-container">
                                        <select
                                            className="dropdown"
                                            value={selectedEmployee?.id || '0'}
                                            onChange={(e) => {
                                                const selectedId = e.target.value;
                                                const selectedEmployeeObj = employees.find(emp => emp.employee_id.toString() === selectedId);
                                                setSelectedEmployee({
                                                    id: selectedId,
                                                    name: selectedEmployeeObj ? selectedEmployeeObj.employee_name : '',
                                                });
                                            }}
                                            style={{paddingLeft:'30px' , color: "black" , fontSize: 17 }}
                                        >
                                            <option value="0"  >All Employees</option>
                                            {employees.map((employee) => (
                                                <option key={employee.employee_id} value={employee.employee_id}>
                                                    {employee.employee_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Grid2>
                            </Grid2>
                        </Typography>
                        <Table className="history-table" aria-label="customized table" sx={{ boxShadow: 'rgba(99, 99, 99, 0.3) 0px 1px 8px 0px' }}>
                            <thead >
                                <tr style={{ fontSize: font_size }}>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Time</th>
                                    <th>Service</th>
                                    <th>Employee</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {upcomingBookings.slice()
                                    .sort((a, b) => {
                                        const dateA = new Date(a.booking_datetime_start);
                                        const dateB = new Date(b.booking_datetime_start);
                                        return dateB - dateA;
                                    }).map((booking, index) => {
                                        const normalizedStatus = normalizeStatus(booking.status?.trim());
                                        return (
                                            <tr key={index} style={{ fontSize: font_size }}>
                                                <td>{moment(booking.booking_datetime_start).format('ddd. DD, MM-YYYY')}</td>
                                                <td>{booking.booking_details.customer_name}</td>
                                                <td>
                                                    {moment(booking.booking_datetime_start).format('hh:mm')}-
                                                    {moment(booking.booking_datetime_end).format('hh:mm')}
                                                </td>
                                                <td>{booking.booking_details.service_name}</td>
                                                <td>{booking.booking_details.employee_name}</td>
                                                <td style={{ color: statusColors[normalizedStatus] || "#3174ad", fontWeight: "700" }}>{normalizedStatus}</td>
                                                <td>{formatAmount(booking.total_amount)}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>

                    </Grid2>
                    <Grid2 item >
                        <Typography sx={{ mt: 4, mb: 2, color: 'black' }} variant="h2" component="div">
                            <Grid2 container sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                              
                                <PrimaryHeading text={"Previous"} />
                                <Grid2 item sx={{ width: '50%' }}>
                                    <Grid2 container sx={{ flex: 1, justifyContent: 'end' }}>
                                        {["PENDING", "COMPLETED", "CANCELLED", "ABSENCE"].map((statusKey, index) => (
                                            <Grid2 item key={statusKey} sx={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                                                <StyledBadge sx={{ mr: '15px', mt: '13px',  }} status={statusKey} badgeContent=" " />
                                                <Typography sx={{ fontSize: 18}}>
                                                    {statusCounts[statusKey] || 0}
                                                    {" " + displayLabels[index]}
                                                </Typography>
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Typography>

                        <div style={{ overflowX: 'auto', width: '100%', backgroundColor: 'white', boxShadow: 'rgba(99, 99, 99, 0.3) 0px 1px 8px 0px', borderRadius: '15px' }}>
                            <InfiniteScroll
                                className="history-infinitscroll"
                                dataLength={previousBookings.length}
                                loader={<Typography variant="body1" sx={{ textAlign: 'center', my: 2 }} color={'gray'}>Loading ...</Typography>}
                                endMessage={
                                    <p style={{ textAlign: 'center', marginTop: 20 }}>
                                        <Typography variant="body1" color={'gray'}>No more bookings</Typography>
                                    </p>
                                }
                                height={'62vh'}
                                style={{ minWidth: '100%' }}
                                next={fetchPreviousEmployeeData}
                                hasMore={hasMore}
                            >
                                <Table className="history-table" aria-label="customized table">
                                    <thead>
                                        <tr style={{ fontSize: font_size }}>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Time</th>
                                            <th>Service</th>
                                            <th>Employee</th>
                                            <th>Status</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {previousBookings.slice()
                                            .sort((a, b) => {
                                                const dateA = new Date(a.booking_datetime_start);
                                                const dateB = new Date(b.booking_datetime_start);
                                                return dateB - dateA;
                                            }).map((booking, index) => {
                                                const normalizedStatus = normalizeStatus(booking.status?.trim());
                                                return (
                                                    <tr key={index} style={{ fontSize: font_size }}>
                                                        <td>{moment(booking.booking_datetime_start).format('ddd. DD, MM-YYYY')}</td>
                                                        <td>{booking.booking_details.customer_name}</td>
                                                        <td>
                                                            {moment(booking.booking_datetime_start).format('hh:mm')}-
                                                            {moment(booking.booking_datetime_end).format('hh:mm')}
                                                        </td>
                                                        <td>{booking.booking_details.service_name}</td>
                                                        <td>{booking.booking_details.employee_name}</td>
                                                        <td style={{ color: statusColors[normalizedStatus] || "#3174ad", fontWeight: "700" }}>{normalizedStatus}</td>
                                                        <td>{formatAmount(booking.total_amount)}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </div>
                    </Grid2>
                </Grid2>
            </Box>
            {/* </Stack> */}
        </Box>
    );
}

export default HistoryTable;