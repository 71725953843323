import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    ListItem, Box, Modal, CircularProgress, IconButton, Grid,
    Typography, Checkbox, FormControlLabel, Paper, ListItemText, TextField,
    Stack, Dialog, DialogTitle, DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import SecondaryHeading from '../settings/commonSecondaryHeading';
import CommonButton from '../settings/commonButton';
import serviceNameIcon from "../../assets/nameOfServiceIcon.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

function ServiceGroupModal(props) {
    const { open, handleClose, serviceList, type, selectedServiceGroup } = props;

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [serviceGroupName, setServiceGroupName] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [showError, setShowError] = useState(false);

    // useEffect(() => {
    //     if (selectedServiceGroup) {
    //         setServiceGroupName(selectedServiceGroup?.title);
    //         const ids = selectedServiceGroup?.data?.map(item => parseInt(item.id, 10));
    //         setSelectedIds(ids);
    //     }
    // }, [selectedServiceGroup]);

    useEffect(() => {
        if (selectedServiceGroup) {
            setServiceGroupName(selectedServiceGroup?.title);
            const ids = selectedServiceGroup?.services?.map(service => parseInt(service.id, 10));
            setSelectedIds(ids);
        }
    }, [selectedServiceGroup]);

    const onSavePress = async () => {
       

        if(serviceGroupName == '' ){
            setShowError(true)
            return
        }

        if (type == 'create') {
            callCreateServiceGroupApi();
        } else {
            callUpdateServiceGroupApi();
        }
    };

    const callCreateServiceGroupApi = async () => {
        setLoading(true)
        try {
            const data = {
                group: serviceGroupName,
                services: selectedIds
            };

            const response = await axios.post(`${process.env.REACT_APP_URL}/api/v1/store/service_group`, data, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response !== undefined) {
                if (response.data.success) {
                    toast.success(`Service group created!`);
                    resetFields('Callapi');
                    // setTimeout(() => {
                    //     resetFields('Callapi');
                    // }, 2000);
                } else {
                    toast.error(`Failed to create service group`);
                    setLoading(false);
                }
            }
        } catch (error) {
            toast.error(`Failed to create service group`);
            setLoading(false);
            console.log("Error fetching service data:", error);
        } finally {
            setLoading(false);
        }
    };

    const callUpdateServiceGroupApi = async () => {
        try {
            const data = {
                group: serviceGroupName,
                services: selectedIds
            };

            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/store/service_group/${selectedServiceGroup?.groupId}`, data, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response !== undefined) {
                if (response.data.success) {
                    toast.success(`Service group updated!`);
                    resetFields('Callapi');
                    // setTimeout(() => {
                    //     resetFields('Callapi');
                    // }, 2000);
                } else {
                    toast.error(`Failed to update service group`);
                    setLoading(false);
                }
            }
        } catch (error) {
            toast.error(`Failed to update service group`);
            console.log("Error fetching service data:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteServiceGroup = async () => {
        setDeleteLoading(true);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/v1/store/service_group/${selectedServiceGroup?.groupId}`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response.status == 200) {
                toast.success(`Service group deleted successfully`);
                resetFields('Callapi');
                // setTimeout(() => {
                //     resetFields('Callapi');
                // }, 2000);
            } else {
                setDeleteLoading(false);
                toast.error(`Failed to delete service group`);
            }
        } catch (error) {
            toast.error(`Failed to delete service group`);
            setDeleteLoading(false);
            console.log('ERROR ', error)
        }
    };

    const resetFields = (command) => {
        setServiceGroupName('');
        setSelectedIds([]);
        handleClose(command);
        setLoading(false);
        setDeleteLoading(false);
    };

    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const handleCircleClick = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="md"
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ scrollbarWidth: 'none' }}
            PaperProps={{ sx: { borderRadius: "25px" } }}
        >
            <DialogTitle id="scroll-dialog-title"></DialogTitle>

            <DialogContent sx={{ scrollbarWidth: 'none' }}>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    style={{
                        display: 'flex', flexDirection: 'row', justifyContent: "flex-start",
                        alignItems: 'center', marginTop: '20px'
                    }}>
                    <img
                        src={serviceNameIcon}
                        style={{ width: "23px", height: "22px", marginRight: 10 }}
                        alt=""
                    />
                    <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}>Name of group</Typography>
                </Box>

                <Stack style={{ width: '100%', marginBottom: 20 }}>
                    <CustomTextField
                        value={serviceGroupName}
                        onChange={(event) => {setShowError(false);setServiceGroupName(event.target.value)}}
                        placeholder={"Enter group name"}
                        placeholderFontSize={'16px'}
                        inputFontSize={'16px'}
                        borderColor={'#A79C92'}
                        borderThickness={'1px'}
                        height={40}
                    />
                    {
                        showError && 
                            <Typography variant='caption' style={{ color: 'red' }}>
                                {'Please enter group name'}
                            </Typography>
                        }
                                        
                </Stack>

                <Box sx={{
                    overflowY: 'scroll',
                    maxHeight: '60vh',
                    paddingBottom: 8,
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}>
                    {Object.keys(serviceList).map((groupId) => {
                        const group = serviceList[groupId];
                        return (
                            <Stack key={groupId} >
                                {/* <Typography style={{ width: 700, fontSize: '22px', color: '#1F1F1F' }}>
                                    {group.title}
                                </Typography> */}

                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f',mt:2 }}>
                                    {group.title}
                                </Typography>

                                    {group.services.map((serviceItem) => (
                                        <Stack
                                            key={serviceItem.id}
                                            flex={1}
                                            flexDirection={'row'}
                                            justifyContent={'flex-start'}
                                            // height= {50}
                                            py={1}
                                            sx={{
                                                borderRadius: '15px',
                                                border: '1.5px solid #D9D9D9',
                                            }}
                                           my={0.5}
                                           onClick={()=> handleCircleClick(serviceItem.id)}
                                           
                                        >

                                            <Stack flex={0.6} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                            <IconButton
                                           
                                                disableRipple
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: '50%',
                                                    border: '2px solid #F7C098',
                                                    backgroundColor: selectedIds.includes(serviceItem.id) ? '#F7C098' : 'white',
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    opacity: 1,
                                                    marginLeft: 1,
                                                    transition: 'background-color 0.3s ease, border 0.3s ease',
                                                    '&:hover': {
                                                        border: '2px solid #F7C098',
                                                    },
                                                }}
                                            >
                                                {selectedIds.includes(serviceItem.id) && (
                                                    <Typography sx={{
                                                        fontSize: '12px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}>
                                                        {selectedIds.indexOf(serviceItem.id) + 1}
                                                    </Typography>
                                                )}
                                            </IconButton>

                                            <Typography variant='body1' style={{
                                              
                                                fontWeight: 400,
                                                color: '#A0A0A0',
                                                marginLeft: 20,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {serviceItem.name}
                                            </Typography>

                                            </Stack>

                                           
                                            <Stack flex={0.4} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>


                                            <Typography variant='body1' style={{
                                                width:'50%',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: '#A0A0A0',
                                                marginLeft: 10
                                            }}>
                                                {serviceItem.duration_text}
                                            </Typography>

                                            <Typography variant='body1' style={{
                                                width:'50%',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: '#367B3D',
                                                marginLeft: 10
                                            }}>
                                                {`${serviceItem.price}kr.`}
                                            </Typography>
                                            </Stack>


                                            
                                        </Stack>
                                    ))}
                            </Stack>
                        )
                    })}
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '16px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                    }}
                >
                    <CommonButton
                        width="40%"
                        height="40px"
                        disabled={isLoading || deleteLoading}
                        title={
                            isLoading ? (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'white',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            ) : type === 'create' ? (
                                'Create group'
                            ) : (
                                'Update group'
                            )
                        }
                        onClick={onSavePress}
                    />

                    {type == 'edit' && (
                        <>
                            <Stack sx={{ ml: 2 }} />
                            <CommonButton
                                width="40%"
                                height="40px"
                                disabled={deleteLoading || isLoading}
                                title={
                                    deleteLoading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    ) : ("Delete group")
                                }
                                backgroundColor={"#D30000"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    deleteServiceGroup();
                                }}
                            />
                        </>
                    )}
                </Box>

            </DialogContent>

        </Dialog>
    )
}

export default ServiceGroupModal;
