import { Box, Container, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import IndividualOpeningHours from "./individualOpeningHours";
import GenralOpeningHoursComponent from "./genralOpeningHours";
import HolidaysAndClosedDaysComponent from "./holidaysAndClosedDays";
import apiFetcher from "../../../utils/interCeptor";
import CommonButton from "../commonButton";
import { HttpStatusCode } from "axios";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { t } from "i18next";
import { cloneDeep } from 'lodash';
import _ from 'lodash';

const OpeningHours = () => {
    let currentYear = moment().format("YYYY");
    const [data, setData] = useState({});
    const [initalData, setInitalData] = useState({});
    const [holidays, setHolidays] = useState([]);
    const [publicholidays, setPublicHolidays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [enableSave, setEnableSave] = useState(false)

    const [loadingButton, setLoadingButton] = useState(false);


    const fetchApiData = async () => {
       
        try {
            const response = await apiFetcher(`api/v1/store/opening_hour`);
            const response1 = await apiFetcher(
                `/api/v1/store/opening_hour/public/holidays?year=${currentYear}`
            );
            console.log('update initaldata');
            let resp = cloneDeep(response?.data?.data)
            setInitalData(resp)
            setData(response?.data?.data);
            setHolidays(response?.data?.data?.outlet_holidays);
            setPublicHolidays(response1?.data?.data);
            
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchApiData();
    }, []);



    const handleDeleteHoliday = (index) => {
        setHolidays((prevHolidays) => prevHolidays.filter((_, i) => i !== index));
    };

    const handleOpenStatus = (id) => {
        let newOpenStatus = [...data.schedule];
        newOpenStatus.map((item) => {
            if (item.id === id && (!item.open_by_appointment)) {
                item.is_closed = !item.is_closed;
            }

        });
        setData({ ...data, schedule: newOpenStatus });
    };

    const handleByAppointment = (id) => {
        let updatedByAppointment = [...data.schedule];
        updatedByAppointment.map((item) => {
            // if (item.id === id && item.is_closed) {
            //     item.open_by_appointment = !item.open_by_appointment;
            // } else if (item.id === id && item.open_by_appointment) {
            //     item.open_by_appointment = !item.open_by_appointment;
            // }

            if (item.id === id ) {
                item.open_by_appointment = !item.open_by_appointment;
            }
        });
        setData({ ...data, schedule: updatedByAppointment });
    };

    const handleSave = async () => {
        setLoadingButton(true)
        try {
            let validDetailsOfEmp = data?.employees_opening_hour
                ?.filter((item) => item.detail.length > 0)
                .map((item) => item.detail)

            validDetailsOfEmp = validDetailsOfEmp.flat();

            let update = {
                schedule: data.schedule,
                holiday: holidays.map((obj)=> {
                    let newObj ={...obj}
                    if(typeof obj.id == 'string'){
                        delete newObj.id
                    }

                    return newObj
                    // {...obj, id: typeof obj.id == 'string'? null : obj.id}}),
            }),
                employee_opening_hour: validDetailsOfEmp.map((obj)=> {
                    let newObj ={...obj}
                    if(typeof obj.id == 'string'){
                        delete newObj.id
                    }

                    return newObj
                    // {...obj, id: typeof obj.id == 'string'? null : obj.id}}),
            })
            }

            console.log('update', update)
        
            
            const response = await apiFetcher.patch(
                `api/v1/store/opening_hour`,
                update
            );

            if (response.status === HttpStatusCode.Ok) {
                toast.success("Opening hours updated successfully");
                console.log("Opening hours updated successfully");

                fetchApiData();
            }
        setLoadingButton(false)


        } catch (error) {
            setLoadingButton(false)
            toast.error("Failed to update opening hours");

            console.error("Error saving opening hours:", error);
        }
    };

      useEffect(() => {

        if(Object.keys(data).length >0){
            
            let sortedScheduleData = data?.schedule.sort((a, b) => a.id - b.id)
            let sortedInitScheduleData = initalData?.schedule.sort((a, b) => a.id - b.id)


            let sorrtedEmployees_opening_hour = data?.employees_opening_hour.sort((a,b)=>a.id - b.id)
            let sorrtedInitEmployees_opening_hour = initalData?.employees_opening_hour.sort((a,b)=>a.id - b.id)

            setEnableSave( !_.isEqual(sortedScheduleData,sortedInitScheduleData) || !_.isEqual(sorrtedEmployees_opening_hour,sorrtedInitEmployees_opening_hour) )
        }
            }, [data]);


            useEffect(() => {

                if(Object.keys(holidays).length >0){
        
                    setEnableSave( !_.isEqual(holidays,initalData?.outlet_holidays) )
                }
                    }, [holidays]);

    return (

        
        <Stack sx={{ paddingRight: 4, paddingLeft: 4, marginBottom: 10 }}>
            <ToastContainer />
            
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', paddingTop: 2, paddingBottom: 2}}>
            {enableSave &&
                <CommonButton 
                 type="submit"
                 width="auto"
                 height={40}
                 loading={loadingButton}
                title={t("Settings.SaveChanges")} 
                onClick={()=>handleSave()} />
            }
            </Box>

            <Stack spacing={6} sx={{ bgcolor: "#fff", borderRadius: "25px",    
            height: "86vh",
            overflowY:'auto',
            overflowX:'hidden' }}>
                <GenralOpeningHoursComponent
                    loading={loading}
                    data={data}
                    setData={setData}
                    handleOpenStatus={handleOpenStatus}
                    handleByAppointment={handleByAppointment}
                />

                <Divider sx={{ border: '2px solid #D9D9D9', backgroundColor: '#F3F3F3' }} />


                <HolidaysAndClosedDaysComponent
                    loading={loading}
                    holidays={holidays}
                    publicholidays={publicholidays}
                    setHolidays={setHolidays}
                    handleDeleteHoliday={handleDeleteHoliday}
                />

                <Divider sx={{ border: '2px solid #D9D9D9', backgroundColor: '#F3F3F3' }} />

                <IndividualOpeningHours data={data} setData={setData} />


            </Stack>
        </Stack>
    );
};

export default OpeningHours;
