import { Close } from '@mui/icons-material'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Modal, Stack, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import PrimaryHeading from '../../../commonPrimaryHeading'
import moment from 'moment'
import CommonButton from '../../../commonButton'
import CloseIcon from '@mui/icons-material/Close';
import FSwitch from '../../../../commonComponents/f-switch'

export default function PublicHolidaysModel({ open, onClose, setHolidays, publicholidays, holidays }) {

    let newPublicHolidays = publicholidays.map((holidayObj)=> {return ({...holidayObj, type:'holiday'})})

    // console.log('holidays',holidays);
    
    const [selectedHoliday, setSelectedHoliday] = useState(holidays)

    const checker = (id) => {


        let check = false;
        selectedHoliday.map((item) => {
            if (item.id === id) {
                check = true;
            }
        });
        return check;

    }

    const handleChangeHolidays = (id) => {

        // console.log('heree', id);
        
        if (checker(id)) {
            let filterdHolidays = selectedHoliday.filter((item) => item.id !== id);
            
            // console.log('filterdHolidays',filterdHolidays);
            
            setSelectedHoliday(filterdHolidays);
        } else {
            let filterdHolidays = selectedHoliday.filter((item) => item.id !== id);
            let newHolidays = [...filterdHolidays, newPublicHolidays.find((item) => item.id === id)];

            // console.log('filterdHolidays1',newHolidays);

            setSelectedHoliday(newHolidays);
        }

    }

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#6f6f6f',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        // <Modal
        //     open={open}
        //     onClose={onClose}
        //     aria-labelledby="public-holidays-modal-title"
        //     aria-describedby="public-holidays-modal-description"

        //     sx={{ position: "fixed", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        // >
        //     <Box
        //         className='scrollbar-hidden'
        //         overflow="scroll"
        //         sx={{ position: "relative", width: "60%", height: "auto", bgcolor: "#fff", borderRadius: "25px", padding: 5, overflow: 'hidden' }}>
        //         <IconButton onClick={onClose} sx={{ position: "absolute", top: 4, right: 4 }}>
        //             <Close sx={{ color: "#a2a2a2", fontSize: "30px" }} />
        //         </IconButton>
        //         <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }} >
        //             <PrimaryHeading text={"Public holidays"} />
        //         </Box>

        //         <Grid2 marginTop={3} maxHeight={"400px"} paddingBottom={5} container spacing={2}>
        //             {publicholidays?.map((holiday, index) => {
        //                 return (
        //                     <Grid2 size={{ xs: 6 }} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }} key={index}>
        //                         <Typography style={{ width: '100%', size: '20px', color: '#1f1f1f', fontWeight: 600 }}>
        //                             {holiday?.description}
        //                         </Typography>
        //                         <Box sx={{ width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
        //                             <Switch
        //                                 checked={checker(holiday?.id)}
        //                                 onChange={() => handleChangeHolidays(holiday?.id)}
        //                                 sx={{
        //                                     '& .MuiSwitch-switchBase': {
        //                                         '&.Mui-checked': { color: '#fff' },
        //                                         '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
        //                                     },
        //                                     '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
        //                                 }}
        //                             />
        //                         </Box>
        //                         <Typography style={{ width: '100%', size: '20px', color: '#A0A0A0' }}>
        //                             {/* date , Month */}
        //                             {moment(holiday?.date).format("DD. MMMM")}
        //                         </Typography>
        //                     </Grid2>
        //                 )
        //             })}
        //         </Grid2>

        //         <Box sx={{ display: "flex", justifyContent: "center", gap: 4 }}>
        //             <CommonButton
        //                 variant="outlined"
        //                 title={"Cancel"}
        //                 backgroundColor={"#D9D9D9"}
        //                 onClick={onClose}
        //             />
        //             <CommonButton variant="contained" title={"+ Add Holiday"} onClick={onClose} />
        //         </Box>
        //     </Box>
        // </Modal>

        <Dialog

            PaperProps={{ sx: { borderRadius: "25px" } }}

            keepMounted
            fullWidth
            // aria-describedby="alert-dialog-slide-description"
            // sx={{
            //     display: !open ? 'none' : '',
            //     '& .MuiDialog-paper': {
            //         width: '900px'
            //     },
            // }}
            // className=".mui-fixed"

            maxWidth="md"
            open={open}
            onClose={() => console.log('here')
            }
            // aria-labelledby="responsive-dialog-title"
        >
        
            <BootstrapDialogTitle id="customized-dialog-title"  sx={{  color: '#1F1F1F', fontWeight: 700,pl:6,pt:5 , mb:0  }}>
                {'Public holidays'}
            </BootstrapDialogTitle>

            <DialogContent sx={{pl:6, pr:6, pt:0, pb:0, mt:0, mb:0}}>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        p: 0,
                        mt:0,
                        // pl: 2,
                        // pr: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: '100%',
                    }}
                >
                        <Grid2 marginTop={1} maxHeight={"400px"} paddingBottom={5} container spacing={2} >
                     {newPublicHolidays?.map((holiday, index) => {
                        return (
                            <Grid2  size={{ xs: 6 }} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center",  }} key={index}>
                                <Typography style={{ width: '100%', size: '20px', color: '#1f1f1f', fontWeight: 600 }}>
                                    {holiday?.description}
                                </Typography>
                                {/* <Box sx={{ width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", pl:0, pr:0 }}> */}
                                    <FSwitch
                                        checked={checker(holiday?.id)}
                                        onChange={() => handleChangeHolidays(holiday?.id)}
                                        sx={{
                                            '& .MuiSwitch-switchBase': {
                                                '&.Mui-checked': { color: '#fff' },
                                                '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                                            },
                                            '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                                            mr:2
                                        }}
                                    />
                                {/* </Box> */}
                                <Typography style={{ width: '100%', size: '20px', color: '#A0A0A0'}}>
                                    {/* date , Month */}
                                    {moment(holiday?.date).format("DD. MMMM")}
                                </Typography>
                            </Grid2>
                        )
                    })}
                </Grid2>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mb: 2, gap:2 }} >
                {
                  
                        <>

                            <CommonButton
                                type="submit"
                                // width={120}
                                height={40}
                                title={'Cancel'}
                                backgroundColor={'#D9D9D9'}
                                style={{
                                    minWidth:150 
                                }}
                                onClick={onClose}
                                // loading={formik.isSubmitting}
                                // disabled={formik.isSubmitting}
                            />
                            <CommonButton
                                type="submit"
                             
                                height={40}
                                title={'+ Add holiday'}
                                // loading={formik.isSubmitting}
                                // disabled={formik.isSubmitting}
                                backgroundColor={'#44B904'}
                                style={{
                                    minWidth:150 
                                }}

                                onClick={()=>{setHolidays(selectedHoliday);
                                    onClose()

                                }}

                            />
                        </>

                }


            </DialogActions>
        </Dialog>
    )
}
