import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Modal, Select, MenuItem, Switch, IconButton,
    Typography, Checkbox, TextField, Autocomplete, FormControl
} from '@mui/material';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import CustomSelect from '../settings/commonCustomSelect';
import PrimaryHeading from '../settings/commonPrimaryHeading';
import CommonButton from '../settings/commonButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

const dateOptions = [
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
];

const timeOptions = [
    {
        label: 'Now',
        value: 'now',
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
];

const endDateOptions = [
    {
        label: '24 hrs',
        value: '24hrs',
    },
    {
        label: '3 days',
        value: '3days',
    },
    {
        label: '1 week',
        value: '1week',
    },
    {
        label: '2 weeks',
        value: '2weeks',
    },
    {
        label: '1 month',
        value: '1month',
    },
    {
        label: 'End of month',
        value: 'endOfMonth',
    },
    {
        label: 'Custom',
        value: 'custom',
    },
];

function AddSpecialOfferModal(props) {
    const { open, handleClose, selectedSpecialOffer, type } = props;

    // console.log('SELECTED SPECIAL OFFER ', JSON.stringify(selectedSpecialOffer));

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [standardprice, setStandardPrice] = useState('0');
    const [serviceList, setServiceList] = useState([]);

    const [cancellationOfferDetails, setCancellationOfferDetails] = useState({
        newPrice: '',
        percentage: '',
    });

    const [selectedValues, setSelectedValues] = useState({
        startDate: { value: '', time: '' },
        endDate: { value: '', time: '' },
    });

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [customStartTime, setCustomStartTime] = useState(null);
    const [customEndTime, setCustomEndTime] = useState(null);

    const [searchService, setSearchService] = useState('');

    useEffect(() => {
        if (type === 'edit') {
            const startDate = new Date(selectedSpecialOffer?.start_datetime);
            const endDate = new Date(selectedSpecialOffer?.end_datetime);

            let startDateOption = 'custom';
            if (startDate.toDateString() === new Date().toDateString()) {
                startDateOption = 'today';
            } else if (startDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
                startDateOption = 'tomorrow';
            }

            let startTimeOption = 'custom';
            if (startDate.getHours() === new Date().getHours() && startDate.getMinutes() === new Date().getMinutes()) {
                startTimeOption = 'now';
            }

            let endDateOption = 'custom';
            if (endDate.toDateString() === new Date().toDateString()) {
                endDateOption = 'today';
            } else if (endDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
                endDateOption = 'tomorrow';
            }

            let endTimeOption = 'custom';
            if (endDate.getHours() === new Date().getHours() && endDate.getMinutes() === new Date().getMinutes()) {
                endTimeOption = 'now';
            }

            setSelectedEndDate('custom');

            setSelectedValues({
                startDate: { value: startDateOption, time: startTimeOption },
                endDate: { value: endDateOption, time: endTimeOption },
            });

            setCustomStartDate(startDate);
            setCustomStartTime(startDate);

            setCustomEndDate(endDate);
            setCustomEndTime(endDate);
        }
    }, [selectedSpecialOffer, type]);

    useEffect(() => {
        fetchServiceApi();
    }, []);

    async function fetchServiceApi() {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setServiceList(response.data.data);
                setLoading(false);
            } else {
                setServiceList([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
            setServiceList([]);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (type === 'edit' && selectedSpecialOffer && serviceList.length > 0) {
            serviceList.forEach(service => {
                if (service.id === selectedSpecialOffer?.service.special_source_id) {
                    setSelectedService(service);
                    setStandardPrice(parseFloat(service.price));

                    setSelectedEmployeeIds(service ? service.employees?.map(employee => employee.id) : []);

                    const newPrice = parseFloat(selectedSpecialOffer?.service.special_price);
                    const percentage = parseFloat(selectedSpecialOffer?.service.special_percentage);

                    setCancellationOfferDetails({
                        ...cancellationOfferDetails,
                        newPrice: newPrice ? newPrice : 0,
                        percentage: percentage ? percentage : 0,
                    });
                }
            });
        }
    }, [serviceList, selectedSpecialOffer, type]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handleServiceChange = (event) => {
        const selectedServiceId = event.target.value;
        const service = serviceList.find(service => service.id === selectedServiceId);

        if (service) {
            setSelectedService(service);
            setStandardPrice(parseFloat(service.price));
            setSelectedEmployeeIds(service.employees.map(employee => employee.id));
        } else {
            setSelectedService(null);
            setStandardPrice(0);
            setSelectedEmployeeIds([]);
        }
    };

    const handleEmployeeToggle = (employeeId) => {
        setSelectedEmployeeIds((prevSelectedEmployeeIds) => {
            if (prevSelectedEmployeeIds.includes(employeeId)) {
                return prevSelectedEmployeeIds.filter(id => id !== employeeId);
            } else {
                return [...prevSelectedEmployeeIds, employeeId];
            }
        });
    };

    const resetFields = (command) => {
        handleClose(command);
    };

    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const changeText = useCallback((text, key) => {
        setCancellationOfferDetails((prevDetails) => ({
            ...prevDetails,
            [key]: text,
        }));
    }, []);

    const handlePercentageChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if (value == '.') {
            return;
        };

        if (value === '') {
            changeText('', 'percentage');
            changeText('', 'newPrice');
            return;
        }

        if (regex.test(value)) {
            const percentage = parseFloat(value);

            if (percentage > 100) {
                changeText('100', 'percentage');
            } else {
                const calculatedNewPrice = (selectedService?.price * percentage) / 100;
                changeText(value, 'percentage');
                changeText(calculatedNewPrice.toFixed(2), 'newPrice');
            }
        }
    };

    const handleNewPriceChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if (value == '.') {
            return;
        };

        if (value === '') {
            changeText('', 'newPrice');
            changeText('', 'percentage');
            return;
        }

        if (regex.test(value)) {
            const newPrice = parseFloat(value);

            if (newPrice > selectedService?.price) {
                changeText(selectedService?.price.toString(), 'newPrice');
            } else {
                const calculatedPercentage = (newPrice / selectedService?.price) * 100;
                changeText(value, 'newPrice');
                changeText(calculatedPercentage.toFixed(2), 'percentage');
            }
        }
    };

    const handleDateChange = (field, selectedValue) => {
        setSelectedValues((prev) => ({
            ...prev,
            [field]: { ...prev[field], value: selectedValue },
        }));
    };

    const handleTimeChange = (field, selectedTime) => {
        setSelectedValues((prev) => ({
            ...prev,
            [field]: { ...prev[field], time: selectedTime },
        }));
    };

    async function createOrUpdateSingleOffer() {
        let startDate = null;
        let startTime = null;
        let endDate = null;
        let endTime = null;

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formatTime = (date) => {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

        if (!checked) {
            if (selectedValues.startDate.value === 'custom' || selectedValues.startDate.time === 'custom') {
                if (!customStartDate || !customStartTime) {
                    toast.error('Custom start date and time must be provided.');
                    return;
                }
                startDate = formatDate(new Date(customStartDate));
                startTime = formatTime(new Date(customStartTime));
            } else if (selectedValues.startDate.value === 'today') {
                const today = new Date();
                startDate = formatDate(today);
                startTime = formatTime(today);
            } else if (selectedValues.startDate.value === 'tomorrow') {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                startDate = formatDate(tomorrow);
                startTime = formatTime(tomorrow);
            }

            if (!selectedEndDate) {
                toast.error('End date must be selected.');
                return;
            }

            if (selectedEndDate === 'custom') {

                if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
                    toast.error('Custom end date and time must be provided.');
                    return;
                }

                if (selectedValues.endDate.value === 'custom') {
                    if (!customEndDate) {
                        toast.error('Custom end date must be provided.');
                        return;
                    }
                    endDate = formatDate(new Date(customEndDate));
                } else if (selectedValues.endDate.value === 'today') {
                    endDate = formatDate(new Date());
                } else if (selectedValues.endDate.value === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endDate = formatDate(tomorrow);
                } else {
                    toast.error('Invalid end date value.');
                    return;
                }

                if (selectedValues.endDate.time === 'custom') {
                    if (!customEndTime) {
                        toast.error('Custom end time must be provided.');
                        return;
                    }
                    endTime = formatTime(new Date(customEndTime));
                } else if (selectedValues.endDate.time === 'now') {
                    endTime = formatTime(new Date());
                } else if (selectedValues.endDate.time === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endTime = formatTime(tomorrow);
                } else {
                    toast.error('Invalid end time value.');
                    return;
                }

            } else if (selectedEndDate === '24hrs') {
                const endOf24hrs = new Date();
                endOf24hrs.setHours(endOf24hrs.getHours() + 24);
                endDate = formatDate(endOf24hrs);
                endTime = formatTime(endOf24hrs);
            } else if (selectedEndDate === '3days') {
                const endOf3Days = new Date();
                endOf3Days.setDate(endOf3Days.getDate() + 3);
                endDate = formatDate(endOf3Days);
                endTime = formatTime(endOf3Days);
            } else if (selectedEndDate === '1week') {
                const endOf1Week = new Date();
                endOf1Week.setDate(endOf1Week.getDate() + 7);
                endDate = formatDate(endOf1Week);
                endTime = formatTime(endOf1Week);
            } else if (selectedEndDate === '2weeks') {
                const endOf2Weeks = new Date();
                endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
                endDate = formatDate(endOf2Weeks);
                endTime = formatTime(endOf2Weeks);
            } else if (selectedEndDate === '1month') {
                const endOf1Month = new Date();
                endOf1Month.setMonth(endOf1Month.getMonth() + 1);
                endDate = formatDate(endOf1Month);
                endTime = formatTime(endOf1Month);
            } else if (selectedEndDate === 'endOfMonth') {
                const endOfMonth = new Date();
                endOfMonth.setMonth(endOfMonth.getMonth() + 1);
                endOfMonth.setDate(0);
                endDate = formatDate(endOfMonth);
                endTime = formatTime(endOfMonth);
            }
        } else {
            const currentDate = new Date();
            startDate = formatDate(currentDate);
            startTime = formatTime(currentDate);

            if (!selectedEndDate) {
                toast.error('End date must be selected.');
                return;
            }

            if (selectedEndDate === '24hrs') {
                const endOf24hrs = new Date();
                endOf24hrs.setHours(endOf24hrs.getHours() + 24);
                endDate = formatDate(endOf24hrs);
                endTime = formatTime(endOf24hrs);
            } else if (selectedEndDate === '3days') {
                const endOf3Days = new Date();
                endOf3Days.setDate(endOf3Days.getDate() + 3);
                endDate = formatDate(endOf3Days);
                endTime = formatTime(endOf3Days);
            } else if (selectedEndDate === '1week') {
                const endOf1Week = new Date();
                endOf1Week.setDate(endOf1Week.getDate() + 7);
                endDate = formatDate(endOf1Week);
                endTime = formatTime(endOf1Week);
            } else if (selectedEndDate === '2weeks') {
                const endOf2Weeks = new Date();
                endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
                endDate = formatDate(endOf2Weeks);
                endTime = formatTime(endOf2Weeks);
            } else if (selectedEndDate === '1month') {
                const endOf1Month = new Date();
                endOf1Month.setMonth(endOf1Month.getMonth() + 1);
                endDate = formatDate(endOf1Month);
                endTime = formatTime(endOf1Month);
            } else if (selectedEndDate === 'endOfMonth') {
                const endOfMonth = new Date();
                endOfMonth.setMonth(endOfMonth.getMonth() + 1);
                endOfMonth.setDate(0);
                endDate = formatDate(endOfMonth);
                endTime = formatTime(endOfMonth);
            } else if (selectedEndDate === 'custom') {
                if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
                    toast.error('Custom end date and time must be provided.');
                    return;
                }

                if (selectedValues.endDate.value === 'custom') {
                    if (!customEndDate) {
                        toast.error('Custom end date must be provided.');
                        return;
                    }
                    endDate = formatDate(new Date(customEndDate));
                } else if (selectedValues.endDate.value === 'today') {
                    endDate = formatDate(new Date());
                } else if (selectedValues.endDate.value === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endDate = formatDate(tomorrow);
                } else {
                    toast.error('Invalid end date value.');
                    return;
                }

                if (selectedValues.endDate.time === 'custom') {
                    if (!customEndTime) {
                        toast.error('Custom end time must be provided.');
                        return;
                    }
                    endTime = formatTime(new Date(customEndTime));
                } else if (selectedValues.endDate.time === 'now') {
                    endTime = formatTime(new Date());
                } else if (selectedValues.endDate.time === 'tomorrow') {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    endTime = formatTime(tomorrow);
                } else {
                    toast.error('Invalid end time value.');
                    return;
                }
            }
        };

        if (!startDate || !startTime ||
            (selectedValues.endDate.value === 'custom' && (!endDate || !endTime))) {
            toast.error('Invalid date or time selection');
            return;
        };

        if (selectedService === null) {
            toast.error('Please select service for special offer');
            return;
        };

        if (cancellationOfferDetails.newPrice === '' || cancellationOfferDetails.percentage === '') {
            toast.error('Please enter discounted price or percentage');
            return;
        };

        const specialPrice = Number(cancellationOfferDetails.newPrice);
        const formattedPercentage = Number(cancellationOfferDetails.percentage) % 1 === 0 ? Number(cancellationOfferDetails.percentage) : Number(cancellationOfferDetails.percentage).toFixed(2).replace('.00', '');

        try {
            const data = {
                start_datetime: `${startDate} ${startTime}`,
                end_datetime: `${endDate} ${endTime}`,
                service_id: type === 'edit' ? selectedSpecialOffer?.service_id : selectedService?.id,
                special_price: specialPrice,
                special_percentage: formattedPercentage,
                employee_ids: selectedEmployeeIds,
            };

            let response;

            if (type === 'create') {
                response = await axios.post(`${process.env.REACT_APP_URL}/api/v1/store/special_offer`, data, {
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`
                    }
                });

                if (response?.status === 200) {
                    toast.success("Special offer created successfully!");
                }

                setTimeout(() => {
                    resetFields('Callapi');
                }, 2000);

            } else if (type === 'edit') {
                response = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/store/special_offer/${selectedSpecialOffer?.id}`, data, {
                    headers: {
                        Authorization: `Bearer ${authTokenUser}`
                    }
                });

                if (response?.status === 200) {
                    toast.success("Special offer updated successfully!");
                }

                setTimeout(() => {
                    resetFields('Callapi');
                }, 2000);

            } else {
                toast.error(`Failed to ${type} special offer`);
            }

        } catch (error) {
            console.log(`Error while ${type} special offer:`, error);
        } finally {
            setLoading(false);
        }
    };

    const filteredServices = serviceList.filter(service =>
        service.name.toLowerCase().includes(searchService?.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchService(e.target.value);
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[style, {
                zIndex: 12,
                justifyContent: 'center', alignItems: 'center',
                overflowY: { xs: 'auto', sm: '85vh' },
                overflowX: 'hidden',
                maxHeight: { xs: '90vh', sm: '85vh' },
                position: 'relative'
            }]}>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <PrimaryHeading text={type == 'edit' ? 'Update special offer' : 'Create a special offer'} fontSize='26px' />
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '49%', alignItems: 'flex-start' }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                            width: '50%',
                            maxWidth: '1440px',
                            '@media (max-width: 1440px)': {
                                width: '63%',
                            }
                        }}>
                            <Box sx={{ ml: 2.5 }}>
                                <PrimaryHeading text={'Start instantly'} />
                            </Box>

                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{
                                    '& .MuiSwitch-switchBase': {
                                        '&.Mui-checked': { color: '#fff' },
                                        '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                                    },
                                    '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                                }}
                            />
                        </Box>

                        {!checked &&
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.startDate.value === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set starting date'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.startDate.value}
                                            onChange={(e) => handleDateChange('startDate', e.target.value)}
                                            options={dateOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.startDate.value === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={customStartDate}
                                                    onChange={(newValue) => setCustomStartDate(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.startDate.time === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set starting time'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.startDate.time}
                                            onChange={(e) => handleTimeChange('startDate', e.target.value)}
                                            options={timeOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.startDate.time === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={customStartTime}
                                                    onChange={(newValue) => setCustomStartTime(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>
                            </>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', mt: 5 }}>
                            <Box sx={{ ml: 5 }}>
                                <PrimaryHeading text={'End of offer'} />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: '100%',
                                maxWidth: '60%',
                                '@media (max-width: 1440px)': {
                                    maxWidth: '50%',
                                },
                                '@media (max-width: 900px)': {
                                    maxWidth: '40%',
                                }
                            }}>
                                <CustomSelect
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    options={endDateOptions}
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                        </Box>

                        {selectedEndDate === 'custom' &&
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.endDate.value === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set ending date'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.endDate.value}
                                            onChange={(e) => handleDateChange('endDate', e.target.value)}
                                            options={dateOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.endDate.value === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={customEndDate}
                                                    onChange={(newValue) => setCustomEndDate(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: selectedValues.endDate.time === 'custom' ? null : 'center', mt: 2 }}>
                                    <PrimaryHeading text={'Set ending time'} />

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                        <CustomSelect
                                            value={selectedValues.endDate.time}
                                            onChange={(e) => handleTimeChange('endDate', e.target.value)}
                                            options={timeOptions}
                                            sx={{ width: '100%' }}
                                        />
                                        {selectedValues.endDate.time === 'custom' &&
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={customEndTime}
                                                    onChange={(newValue) => setCustomEndTime(newValue)}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            border: '2px solid #D9D9D9',
                                                            borderRadius: '10px',
                                                            height: 48,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        }
                                                    }}
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                                ".MuiPaper-root": { borderRadius: "10px" },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '49%', alignItems: 'flex-start' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>

                            <Box sx={{ mr: 3.3 }}>
                                <PrimaryHeading text={'Who has the offer?'} />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}>
                                <Select
                                    multiple
                                    fullWidth
                                    value={selectedEmployeeIds}
                                    onChange={() => { }}
                                    disabled={selectedService == null ? true : false}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fff',
                                                color: '#A0A0A0',
                                                maxHeight: '300px',
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        const serviceEmployees = selectedService ? selectedService?.employees : [];

                                        if (selected.length === serviceEmployees.length) {
                                            return "All employees";
                                        }

                                        if (selected.length === 1) {
                                            const selectedEmployee = serviceEmployees.find(employee => employee.id === selected[0]);
                                            return selectedEmployee ? selectedEmployee.name : '';
                                        }

                                        return `${selected.length} employees`;
                                    }}
                                    sx={{
                                        height: 45,
                                        fontSize: '0.85rem',
                                        borderRadius: '10px',
                                        border: `2px solid #D9D9D9`,
                                        '& .MuiSelect-select': {
                                            color: '#545454',
                                            fontSize: '15px',
                                            fontWeight: 400
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#D9D9D9',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#D9D9D9',
                                        },
                                        '& .MuiSelect-icon': {
                                            color: '#000',
                                        },
                                        backgroundColor: selectedService == null ? '#D9D9D9' : 'transparent'
                                    }}
                                >
                                    {selectedService && selectedService?.employees.length === 0 ? (
                                        <MenuItem disabled sx={{ color: 'black' }}>
                                            No employees available
                                        </MenuItem>
                                    ) : (
                                        selectedService && selectedService?.employees.map(employee => (
                                            <MenuItem
                                                key={employee.id}
                                                value={employee.id}
                                                onClick={() => handleEmployeeToggle(employee.id)}
                                                sx={{
                                                    color: '#545454',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                    margin: 0,
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    checked={selectedEmployeeIds.includes(employee.id)}
                                                    sx={{
                                                        // '& .MuiSvgIcon-root': {
                                                        //     border: '0.1px solid #A79C92',
                                                        //     borderRadius: '4px',
                                                        // },
                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#A79C92',
                                                            color: 'green',
                                                        },
                                                        // '&:hover': {
                                                        //     backgroundColor: 'transparent',
                                                        // },
                                                    }}
                                                />
                                                {employee.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                            <Box sx={{ mr: 4 }}>
                                <PrimaryHeading text={'Choose service'} />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                maxWidth: '60%',
                                '@media (max-width: 1440px)': {
                                    maxWidth: '50%',
                                },
                                '@media (max-width: 900px)': {
                                    maxWidth: '40%',
                                }
                            }}>
                                <Autocomplete
                                    value={selectedService}
                                    onChange={handleServiceChange}
                                    options={filteredServices}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    disabled={type === 'edit' ? true : false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={searchService}
                                            onChange={handleSearchChange}
                                            placeholder="Select service"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                mt: 1,
                                                height: 45,
                                                fontSize: '0.85rem',
                                                borderRadius: '10px',
                                                border: `2px solid #D9D9D9`,
                                                '& .MuiInputBase-root': {
                                                    padding: '5px',
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: '#545454',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#D9D9D9',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#D9D9D9',
                                                },
                                                '& .MuiAutocomplete-popupIndicator': {
                                                    color: '#000',
                                                },
                                            }}
                                            onFocus={(event) => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <MenuItem
                                            {...props}
                                            value={option.id}
                                            sx={{
                                                color: '#545454',
                                                fontSize: '15px',
                                                fontWeight: 400,
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5',
                                                },
                                            }}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    )}
                                    noOptionsText="No results found"
                                    disableClearable
                                />
                            </Box>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                            <Box sx={{ ml: 4 }}>
                                <PrimaryHeading text={'Standard price'} />
                            </Box>

                            <Box sx={{
                                ml: 3.5,
                                width: '100%',
                                maxWidth: '60%',
                                '@media (max-width: 1440px)': {
                                    maxWidth: '50%',
                                },
                                '@media (max-width: 900px)': {
                                    maxWidth: '40%',
                                }
                            }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#D9D9D9',
                                        width: '100px',
                                        padding: '6px',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        border: '2px solid #D9D9D9',
                                        mt: 1.5,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'black',
                                            fontSize: '20px',
                                            fontWeight: 400,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {`${standardprice} kr.`}
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                            <Box sx={{ mr: 4 }}>
                                <PrimaryHeading text={'New price'} />
                            </Box>

                            <CustomTextField
                                disabled={selectedService == null ? true : false}
                                bgColor={selectedService == null ? '#D9D9D9' : 'transparent'}
                                width={{
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}
                                placeholder={'Enter discount price'}
                                value={cancellationOfferDetails?.newPrice}
                                onChange={handleNewPriceChange}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                            <Box sx={{ mr: 4 }}>
                                <PrimaryHeading text={'Percentage'} />
                            </Box>

                            <CustomTextField
                                disabled={selectedService == null ? true : false}
                                bgColor={selectedService == null ? '#D9D9D9' : 'transparent'}
                                width={{
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}
                                placeholder={'Enter discount percentage'}
                                value={cancellationOfferDetails?.percentage}
                                onChange={handlePercentageChange}
                            />
                        </Box>

                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: 4
                }}>
                    <CommonButton
                        width="257px"
                        height="52px"
                        backgroundColor={'#44B904'}
                        onClick={() => createOrUpdateSingleOffer()}
                        title={type == 'edit' ? 'Update single offer' : 'Add single offer'}
                    />
                </Box>

            </Box>

        </Modal >
    )
}

export default AddSpecialOfferModal;