import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";


import "../../index.css";
import greaterImg from "../../assets/greter.png";
import lessImg from "../../assets/less.png";
import closeImg from "../../assets/X.png";
import BookingForm from "./BookingForm";
import { Modal, Button, FormControl } from "react-bootstrap";
import axios from "axios";
import "moment/locale/nb"; // Import Norwegian Bokmål locale

// import "moment/locale/nb"; // Import Norwegian Bokmål locale
// import CustomDateHeader from "./calenderHeaders"; // Import the custom date header
import BookingDetailModal from "./BookingDetailModal";
import "moment/locale/da"
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en-gb';
import { Box, MenuItem } from "@mui/material";
import { Select } from "antd";
import { View } from "lucide-react";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";

const DnDCalendar = withDragAndDrop(Calendar);


dayjs.extend(isoWeek);
dayjs.locale('en-gb');
moment.locale("da"); // Set the global locale to Norwegian Bokmål
moment.updateLocale("da", { week: { dow: 1 } }); // Ensure the week starts on Monday
const localizer = momentLocalizer(moment);


// console.log('authTokenUser', authTokenUser);

const CustomCalendar = () => {
  const [showForm, setShowForm] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [employeeId, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  );
  const [view, setView] = useState("week");
  const [employees, setEmployees] = useState([]);
  const [showAllEmployeesCalendar, setShowAllEmployeesCalendar] =
    useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [duration, setDuration] = useState("");
  const [authTokenUser, setauthTokenUser] = useState(null)
  const [currentWeek, setCurrentWeek] = useState();
  const [lastValidWeek, setLastValidWeek] = useState(null);
  const apiUrl = process.env.REACT_APP_URL;

  const handleEventClick = (event) => {
    // console.log("Event details:", event); // Debug log to confirm event details

    setSelectedBooking({
      id: event.id,
      name: event.customerName,
      phone: event.customerPhone,
      employee: event.employeeName,
      service: `${event.serviceDuration} ${event.title}`,
      date: event.start,
      startTime: event.start,
      endTime: event.end,
      status: event.status,
      created: event.createdDate,
      price: event.servicePrice,
      serviceId: event.serviceId || "Not Available", // Handle undefined serviceId
      employeeId: event.employeeId || "Not Available", // Handle undefined employeeId
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    setauthTokenUser(token)
    setSelectedDate(dayjs());
  }, []);

  useEffect(() => {
    fetchBookings();
    fetchEmployees();
  }, [selectedDate, view, employeeId]);

  const fetchBookings = async () => {
    const startOfWeek = moment(selectedDate)
      .startOf("week")
      .format("YYYY-MM-DD");
    const endOfWeek = moment(selectedDate).endOf("week").format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/store/booking`,
        {
          params: {
            date: startOfWeek,
            to_date: endOfWeek,
            limit: 100,
            offset: 0,
            booking_type: "All",
            filter_equipment_bookings: false,
            employee_id: employeeId,
          },
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        }
      );

      if (response.data.success) {
        const bookings = response.data.data.map((booking) => ({
          id: booking.id,
          title: booking.booking_details.service_name,
          start: moment(booking.booking_datetime_start).toDate(),
          end: moment(booking.booking_datetime_end).toDate(),
          status: booking.status,
          resourceId: booking.employee_id,
          customerName: booking.booking_details.customer_name,
          customerPhone: booking.booking_details.customer_phone_number,
          employeeName: booking.booking_details.employee_name,
          serviceDuration: booking.booking_details.duration_text,
          createdDate: booking.created_at,
          servicePrice: booking.booking_details.price,
          serviceType: booking.booking_details.service_type,
          serviceId: booking.service_id, // Now correctly retrieving serviceId
          employeeId: booking.employee_id,
        }
        ));

        //console.log('bookings', bookings);

        setEvents(bookings);
        setDuration(bookings.serviceDuration);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  console.log('events', events);
  

  const fetchEmployees = () => {
    const storedEmployees = JSON.parse(localStorage.getItem("employees"));
    if (storedEmployees) setEmployees(storedEmployees);
  };

  const openForm = () => setShowForm(true);
  const closeForm = () => setShowForm(false);

  // const scrollToTime = dayjs();
  // scrollToTime.setHours(
  //   scrollToTime.hour() - 1,
  //   scrollToTime.minute() - 30
  // );

  const scrollToTime = dayjs()
    .set('hour', dayjs().hour() - 1)
    .set('minute', dayjs().minute() - 30);

  const handleEmployeeSelection = (id) => {
    setEmployeeId(id);
    setShowAllEmployeesCalendar(id === "0");
  };

  const formats = {
    dayFormat: (date, culture = "en", localizer) => {
      if (employeeId === "0") {
        return employees
          .map((employee) => employee.name || "No Name")
          .join("\n");
      } else {
        return (
          localizer.format(date, "dddd", culture).charAt(0).toUpperCase() +
          localizer.format(date, "dddd", culture).slice(1) +
          `\n${localizer.format(date, "DD/MM", culture)}`
        );
      }
    },
  };


  const fetchEmployeeData = () => {
    const storedEmployees = JSON.parse(localStorage.getItem("employees"));
    if (storedEmployees) {
      const employeeResources = storedEmployees
        .filter((employee) => employee.role === "EMPLOYEE")
        .map((employee) => ({
          id: employee.id,
          title: employee.name || "Unnamed Employee",
        }));
      setEmployees(employeeResources);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);


  useEffect(() => {
    setView(showAllEmployeesCalendar ? 'day' : 'week');
  }, [showAllEmployeesCalendar]);

  // useEffect(() => {
  //   const runOnViewChange = () => {
  //     console.log('View changed to:', view);

  //     updateTimeIndicator(view)


  //     // Place your custom code here that should run on view change
  //   };

  //   // Call the function on mount
  //   runOnViewChange();

  //   // Cleanup function (optional, if you need to clean up after effects)
  //   return () => {
  //     console.log('Cleaning up...');
  //   };
  // }, [view]); 

  const [currentTimeLinePosition, setCurrentTimeLinePosition] = useState(0);
  // console.log('CURRENT TIME LINE POSITION ', currentTimeLinePosition)

  // Calculate the top position for the current time line
  // useEffect(() => {
  //   const updateCurrentTimeLinePosition = () => {
  //     const now = new Date();
  //     const hours = now.getHours();
  //     const minutes = now.getMinutes();
  //     const position = ((hours * 60 + minutes) / (24 * 60)) * 100; // Position in percentage
  //     setCurrentTimeLinePosition(position);
  //   };

  //   updateCurrentTimeLinePosition();
  //   const interval = setInterval(updateCurrentTimeLinePosition, 60000); // Update every minute
  //   return () => clearInterval(interval);
  // }, []);

  const refreshBookings = () => {
    fetchBookings();
  };
  const isToday = moment(selectedDate).isSame(dayjs(), "day");
  useEffect(() => {
    fetchBookings(); // Fetch bookings when the component mounts
  }, []);

  const updateTimeIndicator = (view) => {
    const timeIndicator = document.querySelector('.rbc-current-time-indicator');

    // console.log('view', view);

    // console.log('timeIndicator', timeIndicator);

    if (timeIndicator) {
      const nDayOfWeek = dayjs().day();
      const nOfEmployee = employees.length;
      let left;
      let width;

      if (view == 'day') {
        left = 0;
        width = 100;

        if (nOfEmployee > 0) {
          width = nOfEmployee * 100;
        }
      }
      else {
        left = (nDayOfWeek - 1) * -100;
        width = 700;
      }

      // console.log('width', width);
      // console.log('left', left);
      timeIndicator.style.setProperty('--width', `${width}%`);
      timeIndicator.style.setProperty('--left', `${left}%`);
      timeIndicator.style.setProperty('--display', `flex`);

    }
  };

  const CustomTimeHeader = ({ date, culture, localizer }) => {
    const validDate = date;
    if (validDate === undefined) {
      setCurrentWeek(lastValidWeek);
    }
    else {
      const weekNumber = getWeekNumber(validDate);
      setCurrentWeek(weekNumber);
      setLastValidWeek(weekNumber);
    }

    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Week <br />{currentWeek}
      </div>
    );
  };

  // Function to calculate the week number
  const getWeekNumber = (date) => {
    return dayjs(date).isoWeek();
  };

  function isEventOverlapping(eventsList, newEvent) {

    let events = [...eventsList]

    const newStart = moment(newEvent.start);
    const newEnd = moment(newEvent.end);

    let updatedEvents = [] 

    for (const event of events) {
      
        const start = moment(event.start);
        const end = moment(event.end);

        // Check if new event overlaps with the current event
        if ((event.id !== newEvent?.event?.id )&&newStart.isBefore(end) && newEnd.isAfter(start)) {
            return {isOverlap:true, updatedEvents}; // Overlap found
        }else{

          // console.log('event in map', event?.id);
          // console.log('newEvent in map', newEvent?.event?.id);
          
          if (event.id === newEvent?.event?.id ) {
            updatedEvents.push({...event, start:newEvent.start, end:newEvent.end})
            // event.start = start;
            // event.end = end;
          }else{
            updatedEvents.push(event)
          }
        }
    }

    // console.log('events', updatedEvents);
    return {isOverlap:false, updatedEvents}; // Overlap found
    // return false; // No overlap
}

  const dropEvent = async (eventData) => {
    const {event, start, end} = eventData
    // console.log('event', event);
   



    const {isOverlap,updatedEvents } = isEventOverlapping(events, eventData)
    console.log('isOverLap', isOverlap);
    console.log('updatedEvents', updatedEvents);
    
    // newEvents.map((e) => {
    //   const isBetween = moment(end).isBetween(moment(e.start), moment(e.end), 'minute', '[]')
    //   console.log('isbetween', isBetween);
      
    //   if (e.id === event.id && !isBetween) {
    //     e.start = start;
    //     e.end = end;
    //   }
    // })
    // console.log('newEvent', newEvents);


    if(!isOverlap && (updatedEvents.length > 0)){
      setEvents(updatedEvents);

      const date = moment(start).format("YYYY-MM-DD");
      const time_slot = `${moment(start).format("HH:mm")} - ${moment(end).format("HH:mm")}`;
      console.log('date', date);
      console.log('time_slot', time_slot);
      
      try {
        const payload = {
          id: event.id,
          booking_date: date, // Format as YYYY-MM-DD
          time_slot: time_slot, // Selected time slot
          employee_id: event.employeeId, // Employee ID
          total_amount: event.servicePrice, // Total amount
          update_duration: false, // allow to extend duration of service regardless of the service duration
          allow_past_date: true
        };

        // console.log("Confirm reschedule payload:", payload); // Debugging to check payload

        const endpoint = `${apiUrl}/api/v1/store/booking/reschedule`;

        const response = await axios.post(endpoint, payload, {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        });

        console.log('response', response);
        
        if (response.data.success) {
          toast.success("Booking rescheduled successfully");
    
        
        } else {
          toast.error("Failed to reschedule booking");
        }
        refreshBookings(); 
      } catch (error) {
        console.error("Error rescheduling booking:", error.response.data.detail);
        toast.error(error.response.data.detail);
        refreshBookings(); 
      }
    }else{
      toast.error("Service can't be overlapped");
    }
  }


  const resizeEvent = async (eventData) => {
    const {event, start, end} = eventData

    
    const filteredEvents = events.filter((eventObj)=>eventObj.id !== event.id)
    
    const {isOverlap,updatedEvents } = isEventOverlapping(events, eventData)

    if(!isOverlap && (updatedEvents.length > 0)){
      setEvents(updatedEvents);

      const date = moment(start).format("YYYY-MM-DD");
      const time_slot = `${moment(start).format("HH:mm")} - ${moment(end).format("HH:mm")}`;
      console.log('date', date);
      console.log('time_slot', time_slot);
      
      try {
        const payload = {
          id: event.id,
          booking_date: date, // Format as YYYY-MM-DD
          time_slot: time_slot, // Selected time slot
          employee_id: event.employeeId, // Employee ID
          total_amount: event.servicePrice, // Total amount
          update_duration: true, // allow to extend duration of service regardless of the service duration
          allow_past_date: true
        };

        // console.log("Confirm reschedule payload:", payload); // Debugging to check payload

        const endpoint = `${apiUrl}/api/v1/store/booking/reschedule`;

        const response = await axios.post(endpoint, payload, {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        });

        console.log('response', response);
        
        if (response.data.success) {
          toast.success("Booking rescheduled successfully");
    
        
        } else {
          toast.error("Failed to reschedule booking");
        }
        refreshBookings(); 
      } catch (error) {
        console.error("Error rescheduling booking:", error.response.data.detail);
        toast.error(error.response.data.detail);
        refreshBookings(); 
      }
    }else{
      toast.error("Service can't be overlapped");
    }
  }

  
  const dayPropGetter = (
    (date) => ({
      ...(moment(date).day() === 2 && {
        className: 'tuesday',
      }),
      ...(moment(date).day() === 4 && {
        style: {
          backgroundColor: 'darkgreen',
          color: 'white',
        },
      }),
    }),
    []
  )


  return (
    <div className="calendar-wrapper" style={{ backgroundColor: "#f0f8ff" }}>
        <ToastContainer />
      <DnDCalendar
        localizer={localizer}
        formats={formats}
        events={events}
        view={showAllEmployeesCalendar ? 'day' : view}
        date={selectedDate}
        onNavigate={(date) => setSelectedDate(date)}
        onView={(newView) => setView(newView)}
        onSelectEvent={handleEventClick}
        defaultDate={dayjs()}
        scrollToTime={scrollToTime}
        style={{ height: "100vh", backgroundColor: "#FFFFFF" }}
        step={15}
        timeslots={4}
        dayPropGetter={date => (moment(date).day() === 2) && ({ className: 'rbc-selected-day' })}
        onEventDrop={(e)=>{
          dropEvent(e)
        }}
        onEventResize={(e)=>{
          resizeEvent(e)
        }}
        dayLayoutAlgorithm={'no-overlap'}
        
        eventPropGetter={(event) => {
          
          let backgroundColor = "#3174ad";
          let fontSize = "1rem";

          if (
            event.status === "BOOKED" ||
            event.status === "Awaiting new customer"
          ) {
            backgroundColor = "#A79C92";
          } else if (
            event.status === "CANCELLED" ||
            event.status === "Cancelled by customer"
          ) {
            backgroundColor = "#C74141";
          } else if (event.status === "COMPLETED") {
            backgroundColor = "#367B3D";
          } else if (
            event.status === "OFFERED" ||
            event.status === "Awaiting new customer"
          ) {
            backgroundColor = "#E19957";

          } else if (
            event.status === "Cancellation offer accepted" ||
            event.status === "OFFER_ACCEPTED"
          ) {
            backgroundColor = "#447BCD";
          } else if (
            event.status === "Absence from booking NOSHOW " ||
            event.status === "NOSHOW"
          ) {
            backgroundColor = "#E19957";
          } else if (event.status === "RESCHEDULED") {
            backgroundColor = "#A79C92";
          }

          const durationInMinutes =
            (dayjs(event.end) - dayjs(event.start)) / (1000 * 60);

          let flexDirection = 'column';
          let justifyContent = 'start';
          let alignContent = 'center';
          let textAlign = "left";

          if (durationInMinutes <= 5) {
            fontSize = "0.7rem";
            flexDirection = 'row';
            justifyContent = 'space-between';
            alignContent = 'center';
            textAlign = "right";
          } else if (durationInMinutes >= 6 && durationInMinutes <= 10) {
            fontSize = "0.7rem";
            flexDirection = 'row';
            alignContent = 'center';
            textAlign = "right";
          }
          else if (durationInMinutes >= 11 && durationInMinutes <= 15) {
            fontSize = "0.8rem";
            flexDirection = 'row';
            alignContent = 'center';
            textAlign = "right";
          }
          else if (durationInMinutes >= 16 && durationInMinutes <= 20) {
            fontSize = "0.8rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 21 && durationInMinutes <= 40) {
            fontSize = "1rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 41 && durationInMinutes <= 60) {
            fontSize = "1.2rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 60 && durationInMinutes <= 120) {
            fontSize = "1.3rem";
            flexDirection = 'column';
          } else if (durationInMinutes > 120) {
            fontSize = "1.3rem";
            flexDirection = 'column';
          }



          return {
            style: {
              backgroundColor,
              borderRadius: "15px",
              color: "blue",
              fontSize,
              display: 'flex',
              flex: 1,
              flexDirection,
              justifyContent,
              alignContent,
              textAlign
            },
          };
        }}

        components={{

          timeGutterHeader: CustomTimeHeader,

          toolbar: (props) => {
            // console.log("props" , props);
            updateTimeIndicator(view)

            return (
              <CustomToolbar
                {...props}
                openForm={openForm}
                view={view}
                setView={setView}
                employeeId={employeeId}
                setEmployeeId={handleEmployeeSelection}
                showAllEmployeesCalendar={showAllEmployeesCalendar}
                isToday={isToday}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                CustomTimeHeader={CustomTimeHeader}
                setCurrentWeek={setCurrentWeek}
                 dayLayoutAlgorithm="no-overlap"
              />
            )
          },
          timeSlotWrapper: ({ children }) => (
            <div className="time-slot-wrapper">
              {children}
            </div>
          )
        }}
        resources={
          showAllEmployeesCalendar
            ? employees.map((employee) => ({
              id: employee.id,
              title: employee.name || "Unnamed Employee",
            }))
            : null
        }
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        messages={{
          next: "Neste",
          previous: "Forrige",
          today: "I dag",
          month: "Måned",
          week: "Uke",
          day: "Dag",
          agenda: "Agenda",
          date: "Dato",
          time: "Tid",
          event: "Hendelse",
          allDay: "Hele dagen",
        }}
      />

      <BookingDetailModal
        booking={selectedBooking}
        show={!!selectedBooking}
        onClose={() => setSelectedBooking(null)}
        refreshBookings={() => refreshBookings()} // Pass refreshBookings as a prop
      />

      <Modal show={showForm} onHide={closeForm} centered>
        <Modal.Header className="d-flex justify-content-center">
          <Button
            variant="link"
            onClick={closeForm}
            className="position-absolute start-0 ms-2"
          >
            <img src={closeImg} alt="Close" />
          </Button>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BookingForm closeForm={closeForm} refreshBookings={() => refreshBookings()} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const CustomToolbar = ({
  label,
  onNavigate,
  openForm,
  view,
  setView,
  employeeId,
  setEmployeeId,
  showAllEmployeesCalendar,
  isToday,
  selectedDate,
  setSelectedDate,
  CustomTimeHeader,
  setCurrentWeek
}) => {
  const [selectedLocation, setSelectedLocation] = useState("Select Location");
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState(dayjs(selectedDate));
  const role = localStorage.getItem("employee_role");
  const currentYear = dayjs(selectedDate) && dayjs(selectedDate).year ? dayjs(selectedDate).year() : moment().year();


  useEffect(() => {
    const storedEmployees = JSON.parse(localStorage.getItem("employees"));
    const storedLocations = JSON.parse(localStorage.getItem("locations"));
    if (storedEmployees) setEmployees(storedEmployees);
    if (storedLocations) setLocations(storedLocations);
  }, []);

  const handleToday = () => onNavigate("DATE", dayjs());

  useEffect(() => {
    const extractAndTranslateDate = () => {
      moment.locale("da");
      let parsedDate = null;
      if (view === "week") {
        const words = label.split(/\s+/);
        const [startDateStr, endDateStr] = label.split(" – ");
        const startDate = moment(startDateStr, "MMMM DD", true).year(currentYear);
        let endDate = moment(endDateStr, "MMMM DD", true).year(currentYear);
        const daysInStartMonth = startDate.daysInMonth();
        const startMonth = startDate.month();
        const endMonth = endDate.month();

        if (endDate.isBefore(startDate, 'day')) {
          endDate = endDate.year(currentYear);
        }
        if ((startMonth === 11 && endMonth === 0) || (startMonth === 0 && endMonth === 11)) {
          const decemberContribution = daysInStartMonth - startDate.date() + 1;
          const januaryContribution = endDate.date();

          if (januaryContribution > decemberContribution) {
            parsedDate = moment(`${endDateStr} ${currentYear}`, "MMMM DD YYYY", true);
          } else {
            parsedDate = moment(`${startDateStr} ${currentYear}`, "MMMM DD YYYY", true);
          }
        } else {
          parsedDate = moment(`${startDateStr} ${currentYear}`, "MMMM DD YYYY", true);
        }
      } else if (view === "day") {
        const match = label.match(/([A-Za-zæøåÆØÅ]+ \d{2})/);
        if (match) {
          const [_, dateStr] = match;
          const tempDate = moment(`${dateStr} ${currentYear}`, "MMM DD YYYY", true).locale("da");

          if (tempDate.isValid()) {
            const fullDateStr = tempDate.format("MMMM DD"); // e.g., "december 13"
            parsedDate = moment(`${fullDateStr} ${currentYear}`, "MMMM DD YYYY", true); // Parse the updated string
          }
        }
      }

      if (parsedDate && parsedDate.isValid()) {
        CustomTimeHeader({ date: parsedDate });
      } else {
        console.error("Invalid label format or parsing failed:", label);
      }
    };

    extractAndTranslateDate();
  }, [label]);

  const handleNavigate = (direction) => {
    onNavigate(direction);
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const handleDateChange = (date) => {
    if (!dayjs.isDayjs(date)) {
      date = dayjs(date);
    }
    setCalendarValue(date);
    setSelectedDate(date);
    onNavigate("DATE", date.toDate());
    setView('day');
  };


  useEffect(() => {
    const storedEmployeeId = localStorage.getItem("selectedEmployeeId");
    if (!storedEmployeeId) {
      const storedEmployeeIdFromLocalStorage = localStorage.getItem("employee_id");
      setEmployeeId(storedEmployeeIdFromLocalStorage || "0");
    } else {
      setEmployeeId(storedEmployeeId);
    }
  }, []);


  const handleEmployeeSelect = (e) => {
    const currentEmployeeId = e.target.value;
    if (currentEmployeeId === "0") {
      setEmployeeId("0");
      localStorage.setItem("selectedEmployeeId", "0");
    } else {
      setEmployeeId(currentEmployeeId);
      localStorage.setItem("selectedEmployeeId", currentEmployeeId);
    }
  };

  const formatDateRange = (label) => {
    const [startDateStr, endDateStr] = label.split(" – ");
    let endDate;

    if (view === "week") {
      const startMonthMatch = startDateStr.match(/([A-Za-zæøåÆØÅ]+)/);
      const endMonthMatch = endDateStr ? endDateStr.match(/([A-Za-zæøåÆØÅ]+)/) : startMonthMatch;
      const dayMatch = endDateStr ? endDateStr.match(/(\d{2})/) : startDateStr.match(/(\d{2})/);

      if (endMonthMatch) {
        endDate = endDateStr;
      } else if (startMonthMatch && dayMatch) {
        endDate = `${startMonthMatch[0]} ${dayMatch[0]}`;
      } else {
        console.error("Unable to parse date range");
        return "";
      }
      const formattedStartDate = moment(startDateStr, "MMMM DD").locale("da").format("DD MMMM");
      const formattedEndDate = moment(endDate, "MMMM DD").locale("da").format("D MMMM");

      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    else {
      const formattedSelectedDate = moment(selectedDate).locale("da").format("ddd. d. DD MMM YYYY");
      return formattedSelectedDate;
    }
  };

  return (
    <div className="toolbar-container">
      <div className="right-section">
        <div className="lot">
          {role === "ADMIN" && (
            <div className="dropdown-container">
              <select
                className="dropdown"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <option value="Select Location">Select Location</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.name}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="lot">
          <div className="toolbar">
            <button
              onClick={() => handleNavigate("PREV")}
              className="rotated-button"
            >
              <img src={lessImg} alt="Less" />
            </button>
            <button
              onClick={handleToday}
              className="today-button"
              style={{
                backgroundColor: !isToday ? "#ffffff" : "#BBB0A4",
                color: !isToday ? "#BBB0A4" : "#ffffff",
              }}
            >
              I dag
            </button>

            <span className="toolbar-label" onClick={toggleCalendar}>
              {formatDateRange(label)}
            </span>

            <button
              onClick={() => handleNavigate("NEXT")}
              className="rotated-button"
            >
              <img src={greaterImg} alt="Greater" />
            </button>
          </div>

          {calendarOpen && (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <Box className="index-calendar" sx={{ left: role === "ADMIN" ? '63%' : '73%', }}>
                <DateCalendar
                  defaultValue={dayjs()}
                  value={calendarValue}
                  onChange={(newValue) => handleDateChange(newValue)}

                  sx={{
                    width: '100%',
                    '& .MuiPickersDay-root': {
                      color: 'black',
                      fontSize: '18px',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#BBB0A4 !important',
                      color: 'white !important',
                      fontSize: '18px',
                    },
                    '& .MuiPickersCalendarHeader-label': {
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#000',
                      marginLeft: '20px',
                    },
                    //     '& .css-1nxbkmn-MuiPickersCalendarHeader-root' : {
                    // border:'2px solid red',
                    // display:'flex',
                    // justifyContent:'center'
                    //     },
                    '& .MuiTypography-root': {
                      color: '#5b6980',
                      fontSize: '18px',
                    },
                    '& .MuiIconButton-root': {
                      color: 'black',
                      fontSize: '18px',

                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
          )}

          {role === "ADMIN" && (
            <div className="dropdown-container">
              <select
                className="dropdown"
                value={employeeId}
                onChange={(e) => handleEmployeeSelect(e)}
              >
                <option value="0" >All Employees</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <button onClick={openForm} className="new-booking-button">
            New Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;


